import { STORAGE_KEY } from '@common/values';

export const TT_PIXEL_ID = process.env.NEXT_PUBLIC_TIKTOK_PIXEL_ID;

declare global {
  interface Window {
    ttq: any;
  }
}

const setUserProperties = () => {
  const properties = localStorage.getItem(STORAGE_KEY.USER_PROPERTIES);
  const userProperties = properties && JSON.parse(properties);

  if (!userProperties?.hashedEmail) return;

  window.ttq.identify({
    sha256_email: userProperties.hashedEmail,
  });
};

export const subscribe = ({
  storyName,
  storyId,
}: {
  storyName: string;
  storyId?: number;
}) => {
  setUserProperties();
  window.ttq.track('Subscribe', {
    content_name: storyName,
    content_type: 'product',
    content_id: storyId,
  });
};

export const completeRegistration = () => {
  setUserProperties();
  window.ttq.track('CompleteRegistration', {
    content_type: 'product',
  });
};

export const viewContent = (storyName: string, storyId: string) => {
  setUserProperties();
  window.ttq.track('ViewContent', {
    content_name: storyName,
    content_id: storyId,
    content_type: 'product',
  });
};

export const download = () => {
  setUserProperties();
  window.ttq.track('Download', {
    content_type: 'product',
  });
};

export const startChapter = ({
  storyName,
  storyId,
}: {
  storyName: string;
  storyId?: number;
}) => {
  setUserProperties();
  window.ttq.track('ViewContent', {
    content_name: storyName,
    content_id: storyId,
    content_type: 'product',
  });
};

export const startChapterFirst = ({
  storyName,
  storyId,
}: {
  storyName: string;
  storyId?: number;
}) => {
  setUserProperties();
  window.ttq.track('SubmitForm', {
    content_name: storyName,
    content_type: 'product',
    content_id: storyId,
  });
};

export const endChapter = ({
  storyName,
  storyId,
}: {
  storyName: string;
  storyId: number;
}) => {
  setUserProperties();
  window.ttq.track('Subscribe', {
    content_name: storyName,
    content_type: 'product',
    content_id: storyId,
  });
};

export const endChapterFirst = ({
  storyName,
  storyId,
}: {
  storyName: string;
  storyId: number;
}) => {
  setUserProperties();
  window.ttq.track('AddToWishlist', {
    content_name: storyName,
    content_type: 'product',
    content_id: storyId,
  });
};

export const purchaseTicket = ({
  price,
  id,
  name,
}: {
  price: number;
  id?: string;
  name?: string;
}) => {
  setUserProperties();
  window.ttq.track('CompletePayment', {
    content_id: id,
    content_name: name,
    content_type: 'product',
    price,
    value: price,
    currency: 'KRW',
  });
};
