import React from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';

interface IProgressBar {
  color?: string;
  backgroundColor?: string;
  size: number;
  value: number;
  height?: string;
  hasDefaultView?: boolean; // value가 0일 때 기본 뷰를 보여줄지 여부
}

const ProgressBar = ({
  color = theme.colors.primary600,
  backgroundColor = theme.colors.gray300,
  size,
  value,
  height = '0.25rem',
  hasDefaultView = false,
}: IProgressBar) => {
  const bar = css`
    width: ${value > 0
      ? `calc(${Math.max(0, value / size) * 100}% + ${height})`
      : height};
    ${hasDefaultView && value === 0 && `width: ${height}`};
    height: ${height};
    background: ${color};
    border-radius: inherit;
  `;

  return (
    <div css={parentBar({ background: backgroundColor, height })}>
      <div css={bar} />
    </div>
  );
};

const parentBar = ({
  background,
  height,
}: {
  background: string;
  height: string;
}) => css`
  width: 100%;
  height: ${height};
  background: ${background};
  border-radius: 0.25em;
`;
export default ProgressBar;
