import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TermsDtoFragment = { __typename?: 'NewTermsToAcceptV2', TOS: { __typename?: 'Terms', termsId: number, link: string }, CollectionAndUseOfPrivacy?: { __typename?: 'Terms', termsId: number, link: string } | null, PP?: { __typename?: 'Terms', termsId: number, link: string } | null };

export type GetUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', userId: number, name?: string | null, isAnonymous: boolean, email: string, verifiedAt?: any | null, currentCoin: number, updateToAuthorAt?: any | null, authorName?: string | null, priv: number, isExpiredCertification?: boolean | null, certificatedAt?: any | null, birthDateToken?: string | null, countryCode: string, registeredAt?: any | null, isTester: boolean, ssoLogins: Array<{ __typename?: 'SSO', sId: number, userId: number, ssoProvider: Types.Sso_Provider, ssoId: string, profile: string }>, myStory?: { __typename?: 'MyStory', stat: { __typename?: 'MyStoryStat', numStory: number, numViews: number, numChapter: number, numLikes: number } } | null, geo?: { __typename?: 'Geo', country: string, timezone: string, region: string } | null, systemLanguage?: { __typename?: 'Language', code: string, display: string } | null, newTermsToAcceptV2?: { __typename?: 'NewTermsToAcceptV2', TOS: { __typename?: 'Terms', termsId: number, link: string }, CollectionAndUseOfPrivacy?: { __typename?: 'Terms', termsId: number, link: string } | null, PP?: { __typename?: 'Terms', termsId: number, link: string } | null } | null } | null };

export const TermsDtoFragmentDoc = gql`
    fragment TermsDto on NewTermsToAcceptV2 {
  TOS {
    termsId
    link
  }
  CollectionAndUseOfPrivacy {
    termsId
    link
  }
  PP {
    termsId
    link
  }
}
    `;
export const GetUserDocument = gql`
    query getUser {
  getUser {
    userId
    name
    isAnonymous
    email
    verifiedAt
    currentCoin
    updateToAuthorAt
    authorName
    priv
    ssoLogins {
      sId
      userId
      ssoProvider
      ssoId
      profile
    }
    isExpiredCertification
    certificatedAt
    birthDateToken
    countryCode
    myStory {
      stat {
        numStory
        numViews
        numChapter
        numViews
        numLikes
      }
    }
    registeredAt
    isTester
    geo {
      country
      timezone
      region
    }
    systemLanguage {
      code
      display
    }
    newTermsToAcceptV2 {
      ...TermsDto
    }
  }
}
    ${TermsDtoFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;