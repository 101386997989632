import React from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import SPImage from '../image/SPImage';

interface IDirectAdsBanner {
  src: string;
  alt?: string;
  onClickBanner: () => void;
}

const DirectAdsBanner = ({
  src,
  alt = 'Ads',
  onClickBanner,
}: IDirectAdsBanner) => {
  return (
    <div css={wrapper} onClick={onClickBanner}>
      <SPImage
        src={src}
        alt={alt}
        layout="responsive"
        objectFit="cover"
        width={344}
        height={54}
      />
    </div>
  );
};

const wrapper = css`
  display: block;
  width: 21.5rem;
  height: 3.375rem;
  margin: 0 auto;
  border-radius: 0.25rem;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: ${theme.devices.mobile}) {
    width: calc(100vw - 2.5rem);
    height: calc((100vw - 2.5rem) / 6.3);
  }
`;

export default DirectAdsBanner;
