import React from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { WORLD_CUP_LINK } from '@common/values';

import SPErrorInner from './SPErrorInner';
import { useFetchGlobalConfig } from '@/hooks/useFetchGlobalConfig';
import { isMobileDevice } from '@/common/utils';
import { goBackForApp, goHomeForApp } from '@/lib/webview';

interface ISPError {
  message?: string;
  backBtnName?: string;
  onBack?: () => void;
}

const SPError = ({ message, backBtnName, onBack }: ISPError) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { appStatus } = useFetchGlobalConfig();

  const goToPage = () => {
    if (isMobileDevice()) {
      onBack ? goBackForApp() : goHomeForApp();
    } else {
      onBack ? onBack() : router.push('/');
    }
  };

  if (appStatus?.showMessage) {
    return (
      <SPErrorInner
        title={appStatus?.title || `[서버 점검]`}
        message={appStatus?.message || '안정적인 서비스를 위한 점검중입니다.'}
        onBack={() => router.push(appStatus?.redirectUrl || WORLD_CUP_LINK)}
        buttonName={appStatus?.redirectButton || '이상형 월드컵'}
      />
    );
  }

  return (
    <SPErrorInner
      message={
        message || t('common_screen_description_unexpected_error_occured')
      }
      buttonName={backBtnName || t('common_popup_button_go_to_home')}
      onBack={goToPage}
    />
  );
};

export default SPError;
