import * as fbq from './facebookPixel';
import * as ga from './googleAnalytics';
import * as branch from './branchMetrics';
import * as ttq from './tiktokPixel';
import * as ab from './airbridge';
import { onTrimAll } from '@common/utils';
import { ChallengeStorySorting, StoryGenre } from '@customTypes/story';
import { PushCategory } from '@customTypes/notification';
import { PayMethod } from '@customTypes/store';
import {
  StorySortingText,
  StoryStatus,
  Section,
  From,
  SingInMethod,
  ContentType,
  AdLocationType,
  StartType,
  ChapterFromType,
} from '@customTypes/event';
import { Challenge_Story_Genre } from '@/baseType';
import { STORAGE_KEY } from '@/common/values';
const UNKNOWN = '정보없음';

export const pageView = (): void => {
  ga.pageView();
};

export const startChapter = ({
  storyName,
  storyId,
  storyBundleId,
  chapterIndex,
  chapterId,
  start,
  waitFree,
  adImpression,
  price,
  pay,
  contentType,
  storyType,
  withCustomCharacter,
}: {
  storyName: string;
  storyId: number;
  storyBundleId?: number;
  chapterIndex: number;
  chapterId: number;
  start: 'start' | 'continue' | 'time_leap' | '정보없음';
  waitFree?: boolean | '정보없음';
  adImpression?: boolean | '정보없음';
  price?: number;
  pay: boolean;
  contentType: ContentType;
  storyType: 'home' | 'challenge' | '정보없음';
  withCustomCharacter: boolean;
}) => {
  fbq.startChapter(
    storyName ? onTrimAll(storyName) : UNKNOWN,
    chapterIndex || -1,
  );
  contentType === 'interactive'
    ? fbq.startChapterInteractive(
        storyName ? onTrimAll(storyName) : UNKNOWN,
        chapterIndex || -1,
      )
    : fbq.startChapterNovel(
        storyName ? onTrimAll(storyName) : UNKNOWN,
        chapterIndex || -1,
      );
  branch.startChapter(
    storyName ? onTrimAll(storyName) : UNKNOWN,
    chapterIndex || -1,
  );
  ga.startChapter({
    story: storyName ? onTrimAll(storyName) : UNKNOWN,
    storyId: String(storyId),
    storyBundleId: String(storyBundleId),
    chapter: chapterIndex || -1,
    chapterId: String(chapterId),
    start,
    waitFree,
    adImpression,
    price: price ?? null,
    pay: pay ? 'T' : 'F',
    contentType,
    storyType,
    withCustomCharacter,
  });
  ab.startChapter({
    story: storyName ? onTrimAll(storyName) : UNKNOWN,
    chapter: chapterIndex || -1,
    start,
    storyId,
    storyType,
    adImpression: adImpression || '정보없음',
  });
  contentType === 'interactive'
    ? ab.startChapterInteractive({
        story: storyName ? onTrimAll(storyName) : UNKNOWN,
        chapter: chapterIndex || -1,
        start,
        storyId,
        storyType,
        adImpression: adImpression || '정보없음',
      })
    : ab.startChapterNovel({
        story: storyName ? onTrimAll(storyName) : UNKNOWN,
        chapter: chapterIndex || -1,
        start,
        storyId,
        storyType,
        adImpression: adImpression || '정보없음',
      });
  ttq.startChapter({
    storyName: storyName ? onTrimAll(storyName) : UNKNOWN,
    storyId,
  });
};

export const startChapterFirst = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  contentType,
  storyType = UNKNOWN,
}: {
  storyName: string;
  storyId: number;
  chapterIndex: number;
  chapterId: number;
  contentType: ContentType;
  storyType?: 'home' | 'challenge' | '정보없음';
}): void => {
  fbq.startTrial();
  fbq.startChapterFirst(storyName ? onTrimAll(storyName) : UNKNOWN);
  contentType === 'interactive'
    ? fbq.startChapterInteractiveFirst(
        storyName ? onTrimAll(storyName) : UNKNOWN,
      )
    : fbq.startChapterNovelFirst(storyName ? onTrimAll(storyName) : UNKNOWN);
  ga.startChapterFirst({
    story: storyName,
    storyId: String(storyId),
    chapterId: String(chapterId),
    chapter: chapterIndex,
    contentType,
    storyType,
  });
  ab.startChapterFirst(storyName ? onTrimAll(storyName) : UNKNOWN);
  contentType === 'interactive'
    ? ab.startChapterInteractiveFirst(
        storyName ? onTrimAll(storyName) : UNKNOWN,
      )
    : ab.startChapterNovelFirst(storyName ? onTrimAll(storyName) : UNKNOWN);
  ttq.startChapterFirst({
    storyName: storyName ? onTrimAll(storyName) : UNKNOWN,
    storyId,
  });
};

export const endChapter = ({
  storyName = UNKNOWN,
  chapterIndex = -1,
  endingId = UNKNOWN,
  endingTitle = UNKNOWN,
  storyId = -1,
  storyBundleId,
  storyType = UNKNOWN,
  chapterId,
  contentType,
  withCustomCharacter,
}: {
  storyName?: string;
  chapterIndex?: number;
  endingId?: number | '정보없음';
  endingTitle?: string | '정보없음';
  storyId?: number;
  storyBundleId?: number;
  chapterId?: number;
  contentType: ContentType;
  storyType?: 'home' | 'challenge' | '정보없음';
  withCustomCharacter: boolean;
}): void => {
  ga.endChapter({
    storyName: onTrimAll(storyName),
    chapterIndex,
    endingId: String(endingId),
    endingTitle: endingTitle && onTrimAll(endingTitle),
    storyId,
    storyBundleId,
    storyType,
    chapterId: String(chapterId),
    contentType,
    withCustomCharacter,
  });
  fbq.endChapter(onTrimAll(storyName), chapterIndex);
  contentType === 'interactive'
    ? fbq.endChapterInteractive(onTrimAll(storyName), chapterIndex)
    : fbq.endChapterNovel(onTrimAll(storyName), chapterIndex);
  branch.endChapter(onTrimAll(storyName), chapterIndex);
  ab.endChapter({
    storyName: onTrimAll(storyName),
    chapterIndex,
    endingId,
    endingTitle: endingTitle && onTrimAll(endingTitle),
    storyId,
    storyType,
  });
  contentType === 'interactive'
    ? ab.endChapterInteractive({
        storyName: onTrimAll(storyName),
        chapterIndex,
        endingId,
        endingTitle: endingTitle && onTrimAll(endingTitle),
        storyId,
        storyType,
      })
    : ab.endChapterNovel({
        storyName: onTrimAll(storyName),
        chapterIndex,
        endingId,
        endingTitle: endingTitle && onTrimAll(endingTitle),
        storyId,
        storyType,
      });
  ttq.endChapter({ storyName: onTrimAll(storyName), storyId });
};

export const endChapterFirst = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  contentType,
  storyType = UNKNOWN,
}: {
  storyName: string;
  storyId: number;
  chapterIndex: number;
  chapterId: number;
  contentType: ContentType;
  storyType?: 'home' | 'challenge' | '정보없음';
}): void => {
  ttq.subscribe({
    storyName: storyName ? onTrimAll(storyName) : UNKNOWN,
    storyId,
  });
  fbq.subscribe();
  fbq.endChapterFirst(storyName ? onTrimAll(storyName) : UNKNOWN);
  contentType === 'interactive'
    ? fbq.endChapterInteractiveFirst(storyName ? onTrimAll(storyName) : UNKNOWN)
    : fbq.endChapterNovelFirst(storyName ? onTrimAll(storyName) : UNKNOWN);
  ga.endChapterFirst({
    story: storyName,
    storyId: String(storyId),
    chapterId: String(chapterId),
    chapter: chapterIndex,
    contentType,
    storyType,
  });
  ab.endChapterFirst(storyName ? onTrimAll(storyName) : UNKNOWN);
  contentType === 'interactive'
    ? ab.endChapterInteracitveFirst(storyName ? onTrimAll(storyName) : UNKNOWN)
    : ab.endChapterNovelFirst(storyName ? onTrimAll(storyName) : UNKNOWN);
  ttq.endChapterFirst({
    storyName: storyName ? onTrimAll(storyName) : UNKNOWN,
    storyId,
  });
};

export const viewHomeTab = (): void => {
  ga.viewHomeTab();
};

export const swipeHomeSection = (sectionName: string): void => {
  ga.swipeHomeSection(onTrimAll(sectionName));
};

export const touchOpenAppButton = () => {
  ga.touchOpenAppButton();
};

export const viewStoryDetail = ({
  previousSection = null,
  storyId,
  storyBundleId,
  storyName,
  storyLanguage,
  storyGenre,
  from = null,
  label = null,
  order = null,
  filter = null,
  status = null,
  contentType,
}: {
  previousSection?: Section | null;
  storyId: number;
  storyBundleId: number;
  storyName: string;
  storyLanguage: string;
  storyGenre: string;
  from?: From | null;
  label?: '오리지널' | '신작' | '완결' | 'UP' | '휴재' | string | null;
  order?: StorySortingText | null;
  filter?: string | null;
  status?: StoryStatus | null;
  contentType: ContentType;
}) => {
  ttq.viewContent(storyName ?? '', String(storyId));
  fbq.viewContent();
  ga.viewStoryDetail({
    previousSection,
    storyId: String(storyId),
    storyBundleId: String(storyBundleId),
    storyName: onTrimAll(storyName),
    storyLanguage,
    storyGenre,
    label,
    contentType,
    from,
    order,
    filter,
    status,
  });
};

export const startChapterFromDetail = ({
  storyId,
  storyBundleId = null,
  chapterId,
  chapterIndex,
  storyName,
  storyLanguage = null,
  storyGenre = null,
  playType,
  startType,
  chapterIsAdult,
}: {
  storyId: number;
  storyBundleId?: number | null;
  chapterId: number;
  chapterIndex: number;
  storyName: string;
  storyLanguage?: string | null;
  storyGenre?: string | null;
  playType: ContentType;
  startType: StartType;
  chapterIsAdult: boolean;
}) => {
  ga.startChapterFromDetail({
    storyId: String(storyId),
    storyBundleId: String(storyBundleId),
    chapterId: String(chapterId),
    chapterIndex,
    storyName: onTrimAll(storyName),
    storyLanguage,
    storyGenre,
    playType,
    startType,
    chapterIsAdult,
  });
};

export const selectContent = (itemAndId: string) => {
  ga.selectContent(onTrimAll(itemAndId));
};

export const viewDeleteAccount = () => {
  ga.viewDeleteAccount();
};

export const touchLeaveButton = () => {
  ga.touchLeaveButton();
};

export const completeDeleteAccount = () => {
  ga.completeDeleteAccount();
};

export const startStoryAll = (previousScreen?: string) => {
  ga.startStoryAll(previousScreen || UNKNOWN);
  ab.startStoryAll(previousScreen || UNKNOWN);
};

export const startStoryName = ({ name = UNKNOWN }: { name?: string }) => {
  ga.startStoryName(onTrimAll(name));
};

export const makeChoice = ({
  storyName = UNKNOWN,
  chapterIndex = -1,
  choiceName,
}: {
  storyName?: string;
  chapterIndex?: number;
  choiceName: string;
}) => {
  ga.makeChoice({
    storyName: storyName ? onTrimAll(storyName) : UNKNOWN,
    chapterIndex,
    choiceName,
  });
};

export const makeChoiceStats = ({
  storyName = UNKNOWN,
  chapterIndex = -1,
  questionName = UNKNOWN,
  choiceName = UNKNOWN,
  isReplay,
}: {
  storyName?: string;
  chapterIndex?: number;
  questionName?: string;
  choiceName?: string;
  isReplay: boolean;
}) => {
  ga.makeChoiceStats({
    storyName: onTrimAll(storyName),
    chapterIndex,
    questionName,
    choiceName,
    isReplay,
  });
};

export const touchTabInChoiceStats = ({
  storyName = UNKNOWN,
  chapterIndex = -1,
  questionName = UNKNOWN,
  choiceName = UNKNOWN,
  tab,
  isReplay,
}: {
  storyName?: string;
  chapterIndex?: number;
  questionName?: string;
  choiceName?: string;
  tab: 'all' | 'class';
  isReplay: boolean;
}) => {
  ga.touchTabInChoiceStats({
    storyName: onTrimAll(storyName),
    chapterIndex,
    questionName,
    choiceName,
    tab,
    isReplay,
  });
};

export const endStoryAll = () => {
  ga.endStoryAll();
  ab.endStoryAll();
};

export const endStory = ({
  storyName = UNKNOWN,
  endingSummary,
  endingTitle,
  storyId = -1,
  endingId,
  rareEnding = -1,
  choiceCount = -1,
  collectionRate = -1,
  screenName,
}: {
  storyName?: string;
  endingSummary: string;
  endingTitle: string;
  storyId?: number;
  endingId: number;
  rareEnding?: number;
  choiceCount?: number;
  collectionRate?: number;
  screenName: string;
}) => {
  ga.endStory({
    storyName: onTrimAll(storyName),
    endingSummary,
    endingTitle,
    storyId,
    endingId,
    rareEnding,
    choiceCount,
    collectionRate,
    screenName,
  });
};

export const startAutoPlayReading = ({
  storyName = UNKNOWN,
  chapterIndex = -1,
}: {
  storyName?: string;
  chapterIndex?: number;
}) => {
  ga.startAutoPlayReading({
    storyName: onTrimAll(storyName),
    chapterIndex,
  });
};

export const touchOpenAppNextChapter = ({
  storyName = UNKNOWN,
  storyId = -1,
  chapterId = -1,
}: {
  storyName?: string;
  storyId?: number;
  chapterId?: number;
}) => {
  ga.touchOpenAppNextChapter({
    storyName: onTrimAll(storyName),
    storyId: String(storyId),
    chapterId: String(chapterId),
  });
};

export const viewAppConversionModal = ({
  where,
  story = UNKNOWN,
  storyId = '-1',
}: {
  where: 'detail_like' | 'detail_comment' | 'detail_number' | 'chat_comment';
  story?: string;
  storyId?: string;
}) => {
  ga.viewAppConversionModal({
    where,
    storyId: storyId,
    story: onTrimAll(story),
  });
};

export const openAppAppConversionModal = ({
  where,
  story = UNKNOWN,
  storyId = '-1',
}: {
  where: 'detail_like' | 'detail_comment' | 'detail_number' | 'chat_comment';
  story?: string;
  storyId?: string;
}) => {
  ga.openAppConversionModal({
    where,
    storyId: storyId,
    story: onTrimAll(story),
  });
};

export const shareStoryDetail = ({
  story,
  storyId,
}: {
  story: string;
  storyId: string;
}) => {
  ga.shareStoryDetail({
    story: onTrimAll(story),
    storyId,
  });
};

export const viewAppConversionBottomsheet = () => {
  ga.viewAppConversionBottomsheet();
};

export const touchOpenAppConversionBottomsheet = () => {
  ga.touchOpenAppConversionBottomsheet();
};

export const touchCloseAppConversionBottomsheet = () => {
  ga.touchCloseAppConversionBottomsheet();
};

export const viewAppConversionBanner = () => {
  ga.viewAppConversionBanner();
};

export const touchCloseAppConversionBanner = () => {
  ga.touchCloseAppConversionBanner();
};

export const touchOpenAppConversionBanner = () => {
  ga.touchCloseAppConversionBanner();
};

export const viewLoginModal = ({
  story = UNKNOWN,
  storyId = '-1',
}: {
  story?: string;
  storyId?: string;
}) => {
  ga.viewLoginModal({ story: onTrimAll(story), storyId: String(storyId) });
};

export const touchAcceptLoginModal = ({
  story = UNKNOWN,
  storyId = '-1',
}: {
  story?: string;
  storyId?: string;
}) => {
  ga.touchAcceptLoginModal({
    story: onTrimAll(story),
    storyId: String(storyId),
  });
};

export const touchCloseLoginModal = ({
  story = UNKNOWN,
  storyId = '-1',
}: {
  story?: string;
  storyId?: string;
}) => {
  ga.touchCloseLoginModal({
    story: onTrimAll(story),
    storyId: String(storyId),
  });
};

export const shareEndingButton = ({
  story,
  endingTitle,
  button,
  storyId,
}: {
  story: string;
  endingTitle: string;
  button: string;
  storyId: number | undefined;
}) => {
  ga.shareEndingButton({
    story: onTrimAll(story),
    endingTitle: onTrimAll(endingTitle),
    button,
    storyId,
  });
};

export const viewTeaserVideo = ({
  storyId,
  storyName,
  isReplay,
}: {
  storyId: number;
  storyName: string;
  isReplay: boolean;
}) => {
  ga.viewTeaserVideo({
    storyId: String(storyId),
    storyName: onTrimAll(storyName),
    isReplay,
  });
};

export const teaserPlayTime = ({
  storyId,
  storyName,
  isReplay,
  playTime,
}: {
  storyId: number;
  storyName: string;
  isReplay: boolean;
  playTime: number;
}) => {
  ga.teaserPlayTime({
    storyId: String(storyId),
    storyName: onTrimAll(storyName),
    isReplay,
    playTime: String(playTime),
  });
};

export const viewTeaserVideoEnd = ({
  storyId,
  storyName,
  isReplay,
}: {
  storyId: number;
  storyName: string;
  isReplay: boolean;
}) => {
  ga.viewTeaserVideoEnd({
    storyId: String(storyId),
    storyName: onTrimAll(storyName),
    isReplay,
  });
};

export const viewSignInOrSignUp = () => {
  ga.viewSignInOrSignUp();
};

export const startSignIn = ({ method }: { method: SingInMethod }) => {
  ga.startSignIn({ method });
};

export const startSignUp = ({ method }: { method: SingInMethod }) => {
  ga.startSignUp({ method });
};

export const startSignUpInputEmail = () => {
  ga.startSignUpInputEmail();
};

export const startSignUpInputPassword = () => {
  ga.startSignUpInputPassword();
};

export const startSignUpInputNickname = () => {
  ga.startSignUpInputNickname();
};

export const firstLogin = ({ method }: { method: SingInMethod }) => {
  ttq.completeRegistration();
  fbq.completeRegistration();
  ga.firstLogin({ method });
};

export const viewMainTabMore = () => {
  ga.viewMainTabMore();
};

export const viewEditPassword = () => {
  ga.viewEditPassword();
};

export const editPassword = () => {
  ga.editPassword();
};

export const touchADBannerInChat = ({
  storyId = '-1',
  storyName = UNKNOWN,
  chapterIndex = '-1',
  targetUrl = UNKNOWN,
}: {
  storyId?: string;
  storyName?: string;
  chapterIndex?: string;
  targetUrl?: string;
}) => {
  ga.touchADBannerInChat({
    storyId,
    storyName: onTrimAll(storyName),
    chapterIndex,
    targetUrl,
  });
};

export const viewChallengeTab = () => {
  ga.viewChallengeTab();
};

export const touchChallengeGenre = ({ genre }: { genre: StoryGenre }) => {
  ga.touchChallengeGenre({ genre });
};

export const touchChallengeSort = ({
  sort,
}: {
  sort: ChallengeStorySorting;
}) => {
  ga.touchChallengeSort({ sort });
};

export const touchChallengeWriteButton = () => {
  ga.touchChallengeWriteButton();
};

export const touchContestBanner = ({ title = UNKNOWN }: { title?: string }) => {
  ga.touchContestBanner({ title });
};

export const viewStoryThumbnail = ({
  section,
  story = UNKNOWN,
  index = UNKNOWN,
  storyId = UNKNOWN,
  order = UNKNOWN,
  contentType = UNKNOWN,
}: {
  section: Section;
  story?: string;
  index?: string;
  storyId?: string;
  order?: '인기순' | '최신순' | '정보없음' | string;
  contentType?: ContentType | '정보없음';
}) => {
  ga.viewStoryThumbnail({
    section,
    story,
    index,
    storyId,
    order,
    contentType,
  });
};

export const touchChallengeSearch = () => {
  ga.touchChallengeSearch();
};

export const touchWriteStory = ({ isAuthor }: { isAuthor: boolean }) => {
  ga.touchWriteStory({ isAuthor: String(isAuthor) });
};

export const touchStudioGuide = () => {
  ga.touchStudioGuide();
};

export const touchAuthorDashboard = () => {
  ga.touchAuthorDashboard();
};

export const viewNotification = ({
  previousSection,
  notification = 'false',
}: {
  previousSection: string;
  notification?: string;
}) => {
  ga.viewNotification({ previousSection, notification });
};

export const touchNotificationItem = ({
  notificationType,
  notificationStoryId = UNKNOWN,
}: {
  notificationType: PushCategory;
  notificationStoryId?: string;
}) => {
  ga.touchNotificationItem({
    notificationType,
    notificationStoryId,
  });
};

export const touchNotificationSetting = () => {
  ga.touchNotificationSetting();
};

export const clickAppButton = () => {
  ttq.download();
};

export const shareReplayChoiceStats = ({
  storyName = UNKNOWN,
  chapterIndex = -1,
  questionName = UNKNOWN,
  choiceName = UNKNOWN,
  tab,
  isReplay,
  storyId,
}: {
  storyName?: string;
  chapterIndex?: number;
  questionName?: string;
  choiceName?: string;
  tab: 'all' | 'class';
  isReplay: boolean;
  storyId: number | undefined;
}) => {
  ga.shareReplayChoiceStats({
    storyName: onTrimAll(storyName),
    chapterIndex,
    questionName,
    choiceName,
    tab,
    isReplay,
    storyId,
  });
};

export const viewAchievementPopup = ({
  storyName = UNKNOWN,
  chapterIndex,
  achievement,
}: {
  storyName?: string;
  chapterIndex?: number;
  achievement?: string;
}) => {
  ga.viewAchievementPopup({
    storyName: onTrimAll(storyName),
    chapterIndex: String(chapterIndex),
    achievement,
  });
};

export const viewItemPopup = ({
  storyName,
  chapterIndex,
  item,
}: {
  storyName?: string;
  chapterIndex?: number;
  item?: string;
}) => {
  ga.viewItemPopup({
    storyName,
    chapterIndex: String(chapterIndex),
    item,
  });
};

export const adStart = ({
  storyId,
  adUnitId = null,
  adLoc,
  adUrl = null,
  storyBundleId = null,
  chapterId,
  chapterIndex,
  storyName,
  storyLanguage = null,
  storyGenre = null,
  playType,
  rewardType,
}: {
  storyId: number;
  adUnitId?: string | null;
  adLoc: AdLocationType;
  adUrl?: string | null;
  storyBundleId?: number | null;
  chapterId: number;
  chapterIndex: number;
  storyName: string;
  storyLanguage?: string | null;
  storyGenre?: Challenge_Story_Genre | null;
  playType: ContentType;
  rewardType: 'FREE_CHAPTER' | 'FREE_CHARACTER';
}) => {
  ga.adStart({
    storyId: String(storyId),
    adUnitId,
    adLoc,
    adUrl,
    storyBundleId: String(storyBundleId),
    chapterId: String(chapterId),
    chapterIndex: chapterIndex,
    storyName: onTrimAll(storyName),
    storyLanguage,
    storyGenre,
    playType,
    rewardType,
  });
  ab.adStart({
    storyName: onTrimAll(storyName),
    chapterId: String(chapterId),
    chapterIndex: String(chapterIndex),
    storyId: String(storyId),
  });
};

export const adFinish = ({
  storyId,
  adUnitId = null,
  adLoc,
  adUrl = null,
  storyBundleId = null,
  chapterId,
  chapterIndex,
  storyName,
  storyLanguage = null,
  storyGenre = null,
  playType,
  rewardType,
}: {
  storyId: number;
  adUnitId?: string | null;
  adLoc: AdLocationType;
  adUrl?: string | null;
  storyBundleId?: number | null;
  chapterId: number;
  chapterIndex: number;
  storyName: string;
  storyLanguage?: string | null;
  storyGenre?: Challenge_Story_Genre | null;
  playType: ContentType;
  rewardType: 'FREE_CHAPTER' | 'FREE_CHARACTER';
}) => {
  ga.adFinish({
    storyId: String(storyId),
    adUnitId,
    adLoc,
    adUrl,
    storyBundleId: String(storyBundleId),
    chapterId: String(chapterId),
    chapterIndex: chapterIndex,
    storyName: onTrimAll(storyName),
    storyLanguage,
    storyGenre,
    playType,
    rewardType,
  });
  ab.adFinish({
    storyName: onTrimAll(storyName),
    chapterId: String(chapterId),
    chapterIndex: String(chapterIndex),
    storyId: String(storyId),
  });
};

export const viewPurchaseStory = ({
  storyName = UNKNOWN,
  storyId,
  fullPrice,
  price,
  lastPreviewChapterIndex,
  from = UNKNOWN,
}: {
  storyName?: string;
  storyId?: number;
  fullPrice?: number; // 정가
  price?: number; // 할인가
  lastPreviewChapterIndex?: number;
  from?: 'chat' | 'detail' | 'novel' | '정보없음';
}) => {
  ga.viewPurchaseStory({
    storyName: onTrimAll(storyName),
    storyId: String(storyId),
    fullPrice: String(fullPrice),
    price: String(price),
    lastPreviewChapterIndex: String(lastPreviewChapterIndex),
    from,
  });
  ab.viewPurchaseStory({
    storyName: onTrimAll(storyName),
    storyId: String(storyId),
    fullPrice: String(fullPrice),
    price: String(price),
    lastPreviewChapterIndex: String(lastPreviewChapterIndex),
    from,
  });
};

export const touchPurchaseStory = ({
  storyName = UNKNOWN,
  storyId,
  fullPrice,
  price,
  lastPreviewChapterIndex,
  from = UNKNOWN,
}: {
  storyName?: string;
  storyId?: number;
  fullPrice?: number; // 정가
  price?: number; // 할인가
  lastPreviewChapterIndex?: number;
  from?: 'chat' | 'detail' | 'novel' | '정보없음';
}) => {
  ga.touchPurchaseStory({
    storyName: onTrimAll(storyName),
    storyId: String(storyId),
    fullPrice: String(fullPrice),
    price: String(price),
    lastPreviewChapterIndex: String(lastPreviewChapterIndex),
    from,
  });
};

export const viewPurchaseStoryPopup = ({
  storyName = UNKNOWN,
  storyId,
  fullPrice,
  price,
  userCoin,
}: {
  storyName?: string;
  storyId?: number;
  fullPrice?: number; // 정가
  price?: number; // 할인가
  userCoin?: number;
}) => {
  ga.viewPurchaseStoryPopup({
    storyName: onTrimAll(storyName),
    storyId: String(storyId),
    fullPrice: String(fullPrice),
    price: String(price),
    userCoin: String(userCoin),
  });
};

export const touchPurchaseStoryPopup = ({
  storyName = UNKNOWN,
  storyId,
  fullPrice,
  price,
  userCoin,
}: {
  storyName?: string;
  storyId?: number;
  fullPrice?: number; // 정가
  price?: number; // 할인가
  userCoin?: number;
}) => {
  ga.touchPurchaseStoryPopup({
    storyName: onTrimAll(storyName),
    storyId: String(storyId),
    fullPrice: String(fullPrice),
    price: String(price),
    userCoin: String(userCoin),
  });
};

export const viewInAppPurchaseTicket = ({
  neededCoin = '0',
  userCoin,
}: {
  neededCoin?: string;
  userCoin?: number;
}) => {
  ga.viewInAppPurchaseTicket({
    neededCoin: String(neededCoin),
    userCoin: String(userCoin),
  });
  ab.viewInAppPurchaseTicket({
    neededCoin: String(neededCoin),
    userCoin: String(userCoin),
  });
};

export const touchInAppPurchaseTicket = ({
  neededCoin = '0',
  userCoin,
  price,
}: {
  neededCoin?: string;
  userCoin?: number;
  price?: number;
}) => {
  ga.touchInAppPurchaseTicket({
    neededCoin: String(neededCoin),
    userCoin: String(userCoin),
    price: String(price),
  });
};

export const touchPurchaseProceed = ({
  userCoin,
  coin,
  price,
  method,
}: {
  userCoin?: number;
  coin?: number;
  price?: number;
  method?: PayMethod;
}) => {
  ga.touchPurchaseProceed({
    userCoin: String(userCoin),
    coin: String(coin),
    price: String(price),
    method:
      method === 'card'
        ? '신용카드'
        : method === 'kakaopay'
        ? '카카오페이'
        : method === 'phone'
        ? '휴대폰결제'
        : method === 'tosspay'
        ? '토스'
        : '정보없음',
  });
};

export const purchaseTicketFailure = ({
  userCoin,
  coin,
  price,
  method,
  message = UNKNOWN,
}: {
  userCoin?: number;
  coin?: number;
  price?: number;
  method?: PayMethod;
  message?: string;
}) => {
  ga.purchaseTicketFailure({
    userCoin: String(userCoin),
    coin: String(coin),
    price: String(price),
    method:
      method === 'card'
        ? '신용카드'
        : method === 'kakaopay'
        ? '카카오페이'
        : method === 'phone'
        ? '휴대폰결제'
        : method === 'tosspay'
        ? '토스'
        : method ?? '정보없음',
    message: onTrimAll(message),
  });
};

export const purchaseTicket = ({
  userCoin,
  coin,
  price,
  method,
  name,
  id,
  currency = 'KRW',
  orderId,
}: {
  userCoin?: number;
  coin: number;
  price: number;
  method?: PayMethod;
  name: string;
  id?: string;
  currency?: string;
  orderId: string;
}) => {
  fbq.purchase({ price: price || 0.0 });
  ga.purchaseTicket({
    userCoin: String(userCoin),
    coin,
    price: String(price),
    method:
      method === 'card'
        ? '신용카드'
        : method === 'kakaopay'
        ? '카카오페이'
        : method === 'phone'
        ? '휴대폰결제'
        : method === 'tosspay'
        ? '토스'
        : method ?? '정보없음',
    id,
    name,
    orderId,
    currency,
  });
  ab.purchaseTicket({
    userCoin: String(userCoin),
    coin: String(coin),
    price: String(price),
    method:
      method === 'card'
        ? '신용카드'
        : method === 'kakaopay'
        ? '카카오페이'
        : method === 'phone'
        ? '휴대폰결제'
        : method === 'tosspay'
        ? '토스'
        : '정보없음',
    name,
    id,
  });
  ttq.purchaseTicket({ price: price || 0.0, name, id });
};

export const payTicketAll = ({
  ticketCount = 0,
  storyNameAndChapterIndex = UNKNOWN,
  storyId = 0,
}: {
  ticketCount?: number;
  storyNameAndChapterIndex?: string;
  storyId?: number;
}) => {
  ab.payTicketAll({
    ticketCount: String(ticketCount),
    storyNameAndChapterIndex: onTrimAll(storyNameAndChapterIndex),
    storyId: String(storyId),
  });
};

export const payTicketForStory = ({
  amount,
  storyId,
  storyBundleId = null,
  storyName,
  storyLanguage = null,
  storyGenre = null,
  playType = null,
}: {
  amount: number;
  storyId: number;
  storyBundleId?: number | null;
  storyName: string;
  storyLanguage?: string | null;
  storyGenre?: string | null;
  playType?: ContentType | null;
}) => {
  ga.payTicketForStory({
    amount,
    storyId: String(storyId),
    storyBundleId: storyBundleId ? String(storyBundleId) : null,
    storyName,
    storyLanguage,
    storyGenre,
    playType,
  });
};

export const touchCommentButton = ({
  storyName,
  chapterIndex,
  from,
  contentType,
}: {
  storyName: string;
  chapterIndex: number | 'all';
  from: string;
  contentType: ContentType;
}) => {
  ga.touchCommentButton({
    storyName,
    chapterIndex,
    from,
    contentType,
  });
};

export const touchCommentReplyButton = ({ parentId }: { parentId: number }) => {
  ga.touchCommentReplyButton({
    parentId,
  });
};

export const writeComment = ({
  storyId,
  storyName,
  chapterIndex,
  isSpoiler,
  commentId,
  choiceId,
  category = 'text',
  contentType,
}: {
  storyId: number;
  storyName: string;
  chapterIndex: number;
  isSpoiler: boolean;
  commentId: number;
  choiceId?: number;
  category?: 'text' | 'image' | 'stats';
  contentType: ContentType;
}) => {
  ga.writeComment({
    storyId,
    storyName,
    chapterIndex,
    isSpoiler,
    commentId,
    choiceId,
    category,
    contentType,
  });
};

export const writeCommentReply = ({
  storyId,
  storyName,
  chapterIndex,
  isSpoiler,
  commentId,
  category = 'text',
  contentType,
}: {
  storyId: number;
  storyName: string;
  chapterIndex: number;
  isSpoiler: boolean;
  commentId: number;
  category?: 'text' | 'image' | 'stats';
  contentType: ContentType;
}) => {
  ga.writeCommentReply({
    storyId,
    storyName,
    chapterIndex,
    isSpoiler,
    commentId,
    category,
    contentType,
  });
};

export const deleteComment = ({
  storyName,
  chapterIndex,
  isSpoiler,
  replyCount,
  likeCount,
  category = 'text',
}: {
  storyName: string;
  chapterIndex: number;
  isSpoiler: boolean;
  replyCount: number;
  likeCount: number;
  category?: 'text' | 'image' | 'stats';
}) => {
  ga.deleteComment({
    storyName,
    chapterIndex,
    isSpoiler,
    replyCount,
    likeCount,
    category,
  });
};

export const deleteCommentReply = ({
  storyName,
  chapterIndex,
  isSpoiler,
  likeCount,
  category = 'text',
}: {
  storyName: string;
  chapterIndex: number;
  isSpoiler: boolean;
  likeCount: number;
  category?: 'text' | 'image' | 'stats';
}) => {
  ga.deleteCommentReply({
    storyName,
    chapterIndex,
    isSpoiler,
    likeCount,
    category,
  });
};

export const touchEditCommentButton = () => {
  ga.touchEditCommentButton();
};

export const completeEditComment = () => {
  ga.completeEditComment();
};

export const touchReportCommentButton = ({
  storyName,
  chapterIndex,
  type,
  category = 'text',
}: {
  storyName: string;
  chapterIndex: number;
  type: 'comment' | 'reply';
  category?: 'text' | 'image' | 'stats';
}) => {
  ga.touchReportCommentButton({
    storyName,
    chapterIndex,
    type,
    category,
  });
};

export const completeReportComment = ({
  reportId,
  storyName,
  chapterIndex,
  commentId,
  type,
  category = 'text',
}: {
  reportId: number;
  storyName: string;
  chapterIndex: number;
  commentId: number;
  type: 'comment' | 'reply';
  category?: 'text' | 'image' | 'stats';
}) => {
  ga.completeReportComment({
    reportId,
    storyName,
    chapterIndex,
    commentId,
    type,
    category,
  });
};

export const likeComment = ({
  storyName,
  chapterIndex,
  commentId,
  likeCount,
  category = 'text',
  contentType,
}: {
  storyName: string;
  chapterIndex: number;
  commentId: number;
  likeCount: number;
  category?: 'text' | 'image' | 'stats';
  contentType: ContentType;
}) => {
  ga.likeComment({
    storyName,
    chapterIndex,
    commentId,
    likeCount,
    category,
    contentType,
  });
};

export const likeCommentReply = ({
  storyName,
  chapterIndex,
  commentId,
  likeCount,
  category = 'text',
  contentType,
}: {
  storyName: string;
  chapterIndex: number;
  commentId: number;
  likeCount: number;
  category?: 'text' | 'image' | 'stats';
  contentType: ContentType;
}) => {
  ga.likeCommentReply({
    storyName,
    chapterIndex,
    commentId,
    likeCount,
    category,
    contentType,
  });
};

export const cancelLikeComment = ({
  storyName,
  chapterIndex,
  commentId,
  likeCount,
  category = 'text',
}: {
  storyName: string;
  chapterIndex: number;
  commentId: number;
  likeCount: number;
  category?: 'text' | 'image' | 'stats';
}) => {
  ga.cancelLikeComment({
    storyName,
    chapterIndex,
    commentId,
    likeCount,
    category,
  });
};

export const cancelLikeCommentReply = ({
  storyName,
  chapterIndex,
  commentId,
  likeCount,
  category = 'text',
}: {
  storyName: string;
  chapterIndex: number;
  commentId: number;
  likeCount: number;
  category?: 'text' | 'image' | 'stats';
}) => {
  ga.cancelLikeCommentReply({
    storyName,
    chapterIndex,
    commentId,
    likeCount,
    category,
  });
};

export const touchHomeGenreTab = ({ section }: { section: string }) => {
  ga.touchHomeGenreTab({
    section,
  });
};

export const touchWeekdayTab = ({ section }: { section: Section }) => {
  ga.touchWeekdayTab({
    section,
  });
};

export const ageVerificationStart = ({ storyId }: { storyId?: number }) => {
  const appInfo = sessionStorage.getItem(STORAGE_KEY.APP_INFO_FOR_WEBVIEW);
  const parsedappInfo = appInfo && JSON.parse(appInfo);

  ga.ageVerificationStart({
    storyId: storyId ? String(storyId) : null,
    platform: parsedappInfo?.platform ?? 'Web',
  });

  ab.ageVerificationStart({ platform: parsedappInfo?.platform ?? 'Web' });
};

export const ageVerificationComplete = ({ storyId }: { storyId?: number }) => {
  const appInfo = sessionStorage.getItem(STORAGE_KEY.APP_INFO_FOR_WEBVIEW);
  const parsedappInfo = appInfo && JSON.parse(appInfo);

  ga.ageVerificationComplete({
    storyId: storyId ? String(storyId) : null,
    platform: parsedappInfo?.platform ?? 'Web',
  });
  ab.ageVerificationComplete({ platform: parsedappInfo?.platform ?? 'Web' });
};

export const viewAgeVerification = () => {
  const appInfo = sessionStorage.getItem(STORAGE_KEY.APP_INFO_FOR_WEBVIEW);
  const parsedappInfo = appInfo && JSON.parse(appInfo);

  ga.viewAgeVerification({ platform: parsedappInfo?.platform ?? 'Web' });
  ab.viewAgeVerification({ platform: parsedappInfo?.platform ?? 'Web' });
};

export const adSalesImpressionDirect = ({
  directAdsId,
  adTitle = null,
  adLoc,
  adUrl = null,
  adImageUrl = null,
}: {
  directAdsId: number;
  adTitle?: string | null;
  adLoc: AdLocationType;
  adUrl?: string | null;
  adImageUrl?: string | null;
}) => {
  ga.adSalesImpressionDirect({
    directAdsId,
    adTitle,
    adLoc,
    adUrl,
    adImageUrl,
  });
};

export const adSalesClickDirect = ({
  directAdsId,
  adTitle = null,
  adLoc,
  adUrl = null,
  adImageUrl = null,
}: {
  directAdsId: number;
  adTitle?: string | null;
  adLoc: AdLocationType;
  adUrl?: string | null;
  adImageUrl?: string | null;
}) => {
  ga.adSalesClickDirect({
    directAdsId,
    adTitle,
    adLoc,
    adUrl,
    adImageUrl,
  });
};

export const touchLikeStory = ({
  storyName,
  storyId,
  like,
  contentType,
}: {
  storyName: string;
  storyId: number;
  like: boolean;
  contentType: ContentType;
}) => {
  ga.touchLikeStory({
    story: storyName,
    story_id: String(storyId),
    like,
    content_type: contentType,
  });
};

export const touchChangeScrollType = ({
  type,
}: {
  type: 'scroll' | 'page';
}) => {
  ga.touchChangeScrollType({
    type,
  });
};

export const touchSprintWatch = () => {
  ga.touchSprintWatch();
};

export const viewSprintWatchPopup = () => {
  ga.viewSprintWatchPopup();
};

export const touchSprintWatchConfirm = () => {
  ga.touchSprintWatchConfirm();
};

export const touchMoveChapter = () => {
  ga.touchMoveChapter();
};

export const touchAllChapterPurchase = ({
  storyName,
  storyId,
  fullCoin,
  coin,
  lastPreviewChapterIndex,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  fullCoin?: number;
  coin?: number;
  lastPreviewChapterIndex?: number | null;
  contentType: 'interactive' | 'novel';
}) => {
  ga.touchAllChapterPurchase({
    storyName,
    storyId,
    fullCoin,
    coin,
    lastPreviewChapterIndex,
    contentType,
  });
};

export const viewAllChapterPurchasePopup = ({
  storyName,
  storyId,
  fullCoin,
  coin,
  lastPreviewChapterIndex,
  purchasedChapter,
  chapterCoin,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  fullCoin?: number;
  coin?: number;
  lastPreviewChapterIndex?: number | null;
  purchasedChapter?: number;
  chapterCoin?: number;
  contentType: 'interactive' | 'novel';
}) => {
  ga.viewAllChapterPurchasePopup({
    storyName,
    storyId,
    fullCoin,
    coin,
    lastPreviewChapterIndex,
    purchasedChapter,
    chapterCoin,
    contentType,
  });
};

export const viewEachChapterPurchasePopup = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  fullCoin,
  coin,
  from,
  chapterCoin,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: string;
  chapterIndex?: number;
  fullCoin?: number;
  coin?: number;
  from?: ChapterFromType;
  chapterCoin?: number;
  contentType: 'interactive' | 'novel';
}) => {
  ga.viewEachChapterPurchasePopup({
    storyName,
    storyId,
    chapterId,
    chapterIndex,
    fullCoin,
    coin,
    from,
    chapterCoin,
    contentType,
  });
};

export const payTicketForEachChapter = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  fullCoin,
  coin,
  from,
  chapterCoin,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: string;
  chapterIndex?: number;
  fullCoin?: number;
  coin?: number;
  from?: ChapterFromType;
  chapterCoin?: number;
  contentType: ContentType;
}) => {
  ga.payTicketForEachChapter({
    storyName,
    storyId,
    chapterId,
    chapterIndex,
    fullCoin,
    coin,
    from,
    chapterCoin,
    contentType,
  });
};

export const touchTicketForNextChapter = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  coin,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: string;
  chapterIndex?: number;
  coin?: number;
  contentType: 'interactive' | 'novel';
}) => {
  ga.touchTicketForNextChapter({
    storyName,
    storyId,
    chapterId,
    chapterIndex,
    coin,
    contentType,
  });
};

export const touchChallengeContentTypeFilter = () => {
  ga.touchChallengeContentTypeFilter();
};

export const touchChallengeContentType = ({
  contentType,
}: {
  contentType: 'all' | 'interactive' | 'novel';
}) => {
  ga.touchChallengeContentType({
    contentType,
  });
};

export const viewMoreTab = () => {
  ga.viewMoreTab();
  ga.viewProfileTab();
};

export const viewNotice = () => {
  ga.viewNotice();
};

export const viewNoticeDetail = ({ noticeId }: { noticeId: string }) => {
  ga.viewNoticeDetail({ noticeId });
};

export const touchInteractiveSameStory = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: number;
  chapterIndex?: number;
}) => {
  ga.touchInteractiveSameStory({ storyName, storyId, chapterId, chapterIndex });
};

export const touchNovelSameStory = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: number;
  chapterIndex?: number;
}) => {
  ga.touchNovelSameStory({ storyName, storyId, chapterId, chapterIndex });
};

export const touchViewerEditor = () => {
  ga.touchViewerEditor();
};

export const touchViewerEditorSetting = (title: string) => {
  ga.touchViewerEditorSetting(title);
};

export const touchStartChapter = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  fullCoin,
  coin,
  lastPreviewChapterIndex,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: number;
  chapterIndex?: number;
  fullCoin?: number;
  coin?: number;
  lastPreviewChapterIndex?: number | null;
  contentType: 'interactive' | 'novel';
}) => {
  ga.touchStartChapter({
    storyName,
    storyId,
    chapterId,
    chapterIndex,
    fullCoin,
    coin,
    lastPreviewChapterIndex,
    contentType,
  });
};

export const viewChapterEnding = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  endingId,
  endingTitle,
  storyType,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: string;
  chapterIndex?: number;
  endingId?: number | null;
  endingTitle?: string | null;
  storyType?: 'home' | 'challenge';
  contentType: 'interactive' | 'novel';
}) => {
  ga.viewChapterEnding({
    storyName,
    storyId,
    chapterId,
    chapterIndex,
    endingId,
    endingTitle,
    storyType,
    contentType,
  });
};

export const viewChapterEndingFirst = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  storyType,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: number;
  chapterIndex?: number;
  storyType?: 'home' | 'challenge';
  contentType: 'interactive' | 'novel';
}) => {
  ga.viewChapterEndingFirst({
    storyName,
    storyId,
    chapterId,
    chapterIndex,
    storyType,
    contentType,
  });
};

export const viewSignUpSuccess = () => {
  ga.viewSignUpSuccess();
};

export const viewEmailVerificationRequest = () => {
  ga.viewEmailVerificationRequest();
};

export const successEmailVerification = () => {
  ga.successEmailVerification();
};

export const viewHomeContinueBanner = () => {
  ga.viewHomeContinueBanner();
};

export const touchHomeContinueBanner = () => {
  ga.touchHomeContinueBanner();
};

export const touchHomeContinueBannerOff = () => {
  ga.touchHomeContinueBannerOff();
};

export const viewHomeTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  ga.viewHomeTopBanner({ bannerId, bannerType });
};

export const clickHomeTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  ga.clickHomeTopBanner({ bannerId, bannerType });
};

export const viewInteractiveTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  ga.viewInteractiveTopBanner({ bannerId, bannerType });
};

export const clickInteractiveTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  ga.clickInteractiveTopBanner({ bannerId, bannerType });
};

export const viewNovelTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  ga.viewNovelTopBanner({ bannerId, bannerType });
};

export const clickNovelTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  ga.clickNovelTopBanner({ bannerId, bannerType });
};

export const touchInteractiveMoreChallenge = () => {
  ga.touchInteractiveMoreChallenge();
};

export const touchNovelMoreChallenge = () => {
  ga.touchNovelMoreChallenge();
};

export const touchCtaEndingReport = ({
  storyId = -1,
  storyName = UNKNOWN,
}: {
  storyId?: number;
  storyName?: string;
}) => {
  ga.touchCtaEndingReport({
    storyId: String(storyId),
    storyName,
  });
};

export const viewTermsAgreement = () => {
  ga.viewTermsAgreement();
};

export const viewWebToAppPopup = () => {
  ga.viewWebToAppPopup();
};

export const clickWebToAppPopup = () => {
  ga.clickWebToAppPopup();
};

export const viewStorygameThumbnail = ({
  sectionName,
  sectionIndex,
  storyName,
  storyId,
  storyBundleId,
}: {
  sectionName: string;
  sectionIndex: number;
  storyName: string;
  storyId: string;
  storyBundleId: string;
}) => {
  ga.viewStorygameThumbnail({
    sectionName,
    sectionIndex,
    storyName,
    storyId,
    storyBundleId,
  });
};

export const touchStorygameThumbnail = ({
  sectionName,
  sectionIndex,
  storyName,
  storyId,
  storyBundleId,
}: {
  sectionName: string;
  sectionIndex: number;
  storyName: string;
  storyId: string;
  storyBundleId: string;
}) => {
  ga.touchStorygameThumbnail({
    sectionName,
    sectionIndex,
    storyName,
    storyId,
    storyBundleId,
  });
};

export const viewStorygameTab = () => {
  ga.viewStorygameTab();
};

export const viewSubTab = ({
  prevTab,
  tab,
  index,
}: {
  prevTab: string;
  tab: string;
  index: number;
}) => {
  ga.viewSubTab({
    prevTab,
    tab,
    index,
  });
};

export const createCustomCharacterComplete = ({
  storyId,
  storyBundleId,
  storyName,
  storyLanguage,
  storyGenre,
  playType,
  payType,
}: {
  storyId: string;
  storyBundleId: string;
  storyName: string;
  storyLanguage: string;
  storyGenre: string;
  playType: ContentType;
  payType: 'free' | 'ad' | 'coin';
}) => {
  ga.createCustomCharacterComplete({
    storyId,
    storyBundleId,
    storyName,
    storyLanguage,
    storyGenre,
    playType,
    payType,
  });
};
