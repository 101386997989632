import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import { StoryVerticalCoverDtoFragmentDoc } from '../../../fragments/__generated__/home.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListStoryForEpubContestWinnerQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']>;
  version?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ListStoryForEpubContestWinnerQuery = { __typename?: 'Query', listStoryForEpubContestWinner: { __typename?: 'ListableStory', totalCount: number, page: number, pageSize: number, version?: number | null, list: Array<{ __typename?: 'Story', type: Types.Story_Type, storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isNew: boolean, isUp: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, representedAt?: any | null, sectionType: Types.Story_Section_Type, isAdult: boolean, mainImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null, language?: { __typename?: 'Language', display: string, code: string } | null }> } };


export const ListStoryForEpubContestWinnerDocument = gql`
    query listStoryForEpubContestWinner($page: Int, $version: Int) {
  listStoryForEpubContestWinner(
    data: {page: $page, pageSize: 15, version: $version}
  ) {
    totalCount
    page
    pageSize
    version
    list {
      ...StoryVerticalCoverDto
    }
  }
}
    ${StoryVerticalCoverDtoFragmentDoc}`;

/**
 * __useListStoryForEpubContestWinnerQuery__
 *
 * To run a query within a React component, call `useListStoryForEpubContestWinnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListStoryForEpubContestWinnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListStoryForEpubContestWinnerQuery({
 *   variables: {
 *      page: // value for 'page'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useListStoryForEpubContestWinnerQuery(baseOptions?: Apollo.QueryHookOptions<ListStoryForEpubContestWinnerQuery, ListStoryForEpubContestWinnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListStoryForEpubContestWinnerQuery, ListStoryForEpubContestWinnerQueryVariables>(ListStoryForEpubContestWinnerDocument, options);
      }
export function useListStoryForEpubContestWinnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListStoryForEpubContestWinnerQuery, ListStoryForEpubContestWinnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListStoryForEpubContestWinnerQuery, ListStoryForEpubContestWinnerQueryVariables>(ListStoryForEpubContestWinnerDocument, options);
        }
export type ListStoryForEpubContestWinnerQueryHookResult = ReturnType<typeof useListStoryForEpubContestWinnerQuery>;
export type ListStoryForEpubContestWinnerLazyQueryHookResult = ReturnType<typeof useListStoryForEpubContestWinnerLazyQuery>;
export type ListStoryForEpubContestWinnerQueryResult = Apollo.QueryResult<ListStoryForEpubContestWinnerQuery, ListStoryForEpubContestWinnerQueryVariables>;