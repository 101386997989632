import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import theme from '@styles/theme';

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  customCSS?: SerializedStyles;
  isDisable?: boolean;
  isFill?: boolean;
  onClick?: () => void;
}

const Button = ({
  title,
  customCSS,
  isDisable = false,
  isFill = true,
  onClick,
  ...props
}: IButton) => {
  const style = customCSS ? customCSS : container;

  return (
    <button
      css={[style, isDisable ? disable : isFill && fill]}
      onClick={() => {
        !isDisable && onClick && onClick();
      }}
      {...props}
    >
      <div>{title}</div>
    </button>
  );
};

const container = css`
  width: 10em;
  height: 3em;
  ${theme.fontV3.body.body03Bold}
  cursor: pointer;
`;

const fill = css`
  background-color: ${theme.colors.primary500};
  border-radius: 0.5rem;
  color: ${theme.colors.white};
`;

const disable = css`
  background-color: #0000000d;
  border-radius: 0.5rem;
  color: var(--blackA05, rgba(0, 0, 0, 0.05));
`;

export default Button;
