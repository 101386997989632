import { useRouter } from 'next/router';
import { useAppSelector } from '@/store/hooks';

// 이전 페이지에 대한 히스토리가 있으면 뒤로가기, 없으면 홈으로 이동
const useNavigationBackOrHome = () => {
  const router = useRouter();
  const { previousPath } = useAppSelector((state) => state.event);

  const goBackOrHome = () => {
    if (previousPath) {
      if (
        previousPath.includes('novel') ||
        previousPath.includes('chat') ||
        previousPath.includes('auth') ||
        previousPath.includes('comment') ||
        previousPath.includes('ending') ||
        previousPath.includes('store')
      ) {
        return router.push('/');
      }

      return router.back();
    }

    return router.push('/');
  };

  return goBackOrHome;
};

export default useNavigationBackOrHome;
