import React, { useState, useEffect, useRef, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { FloatingNextBtn, FloatingPrevBtn } from 'public/assets';

import { Section } from '@customTypes/event';
import { useVisibleStoryItemEvent } from '@/hooks/useVisibleStoryItemEvent';

interface ICarouselStory {
  section: Section;
  children: JSX.Element[] | JSX.Element;
  backgroundColor?: string;
  height?: number;
}

const CarouselStory = ({
  section,
  children,
  backgroundColor,
  height,
}: ICarouselStory) => {
  const storyListRef = useRef<HTMLDivElement | null>(null);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [isShowLastItem, setIsShowLastItem] = useState<boolean>(false);
  useVisibleStoryItemEvent({ storyListRef, section });

  const handleNextButtonClick: MouseEventHandler<HTMLDivElement> = () => {
    if (storyListRef.current) {
      storyListRef.current.scrollLeft += storyListRef.current.clientWidth;
    }
  };

  const handlePrevButtonClick: MouseEventHandler<HTMLDivElement> = () => {
    if (storyListRef.current) {
      storyListRef.current.scrollLeft -= storyListRef.current.clientWidth;
    }
  };

  useEffect(() => {
    if (!storyListRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            storyListRef.current &&
            entry.target instanceof HTMLElement
          ) {
            // 리스트가 한 아이템 이상 스크롤되면 prev arrow 아이콘을 보여줍니다
            storyListRef.current.scrollLeft > entry.target.clientWidth
              ? setIsScroll(true)
              : setIsScroll(false);

            // 리스트의 마지막 아이템이 화면에 보여지면 next arrow 아이콘을 숨깁니다
            storyListRef.current.lastChild === entry.target
              ? setIsShowLastItem(true)
              : setIsShowLastItem(false);
          }
        });
      },
      {
        threshold: 1,
      },
    );

    // 스토리 아이템 각각에 옵저버 부착
    storyListRef.current.childNodes.forEach((node) =>
      observer.observe(node as Element),
    );

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div css={container({ backgroundColor })}>
      {isScroll && (
        <FloatingPrevBtn
          css={prevBtn({ height })}
          onClick={handlePrevButtonClick}
          width={56}
          height={56}
        />
      )}
      {!isShowLastItem && (
        <FloatingNextBtn
          css={nextBtn({ height })}
          onClick={handleNextButtonClick}
          width={56}
          height={56}
        />
      )}
      <div className="story-list" ref={storyListRef}>
        {children}
      </div>
    </div>
  );
};

interface IContainer {
  backgroundColor?: string;
}

const container = ({ backgroundColor }: IContainer) => css`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${backgroundColor && `background: ${backgroundColor}`};

  .story-list {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    margin: 0 -0.95em 0;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const nextBtn = ({ height }: { height?: number }) => css`
  position: absolute;
  right: -3rem;
  top: ${height ? `50%` : '30%'};
  opacity: 0.95;
  border-radius: 0.25em;
  cursor: pointer;
  z-index: 2;
  ${height && `transform: translateY(-50%);`};

  @media screen and (max-width: ${theme.devices.mobile}) {
    display: none;
  }
`;

const prevBtn = ({ height }: { height?: number }) => css`
  position: absolute;
  left: -3rem;
  top: ${height ? `50%` : '30%'};
  opacity: 0.95;
  border-radius: 0.25em;
  cursor: pointer;
  z-index: 2;
  ${height && `transform: translateY(-50%);`};

  @media screen and (max-width: ${theme.devices.mobile}) {
    display: none;
  }
`;

export default CarouselStory;
