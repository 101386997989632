// Todo: 해당 코드는 중복, environments.ts 머지되면 지우고 import
const CAN_USE_DOM: boolean =
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'

// px을 rem 으로 변환하는 함수
export function pxToRem(px: number): string {
  const baseFontSize = getBaseFontSize()
  return px / baseFontSize + 'rem'
}

// HTML의 기본 폰트 사이즈를 가져오는 함수
function getBaseFontSize(): number {
  const rootFontSize: string = CAN_USE_DOM
    ? window.getComputedStyle(document.documentElement).fontSize
    : '16px'

  return parseFloat(rootFontSize)
}
