/* eslint-disable no-unused-vars */
import { getBaseURL } from './utils';
import { Sorting } from '@customTypes/story';
import { HOME_TAB_TYPE } from '@customTypes/home';
import {
  ChapterCommentOrderBy,
  Chapter_Comment_Order_Field,
  Order_By_Direction,
  Story_Weekday,
} from '@/baseType';
import { STORY_PLAY_TAB_TYPES } from '@/lib/common-react/types/storyGame';

export const FEATURE_FLAG = {
  showWebNovelTab: true,
  showNewSSORegister: true,
};

export const STORAGE_KEY = {
  AUTH_TOKEN: 'auth_token',
  CURRENT_PLAY: 'current_story_',
  IS_PLAY: 'is_play',
  IS_CHOICE: 'is_choice',
  SAVE_LIST: 'save_list',
  IS_INIT_WEB: 'is_init_web',
  IS_SHOW_FLOATING: 'is_show_floating',
  OPEN_APP_FEATURE_VERSION: 'openapp_feature_version',
  REWARDED_AD_FEATURE_VERSION: 'rewarded_ad_feature_version',
  IS_PLAY_TTS: 'is_play_tts',
  USER_PROPERTIES: 'user_properties',
  PLAYED_STORY_LIST: 'played_story_list',
  ONBOARDING_STORY_ID: 'onboarding_story_id',
  WATCHED_AD_CHAPTER_ID: 'watched_ad_chapter_id',
  IS_SHOW_ADULT: 'is_show_adult',
  COMMENT: {
    SCROLL_POSITION: 'comment_page_scroll_position',
    COMMENT_TO_EDIT_INFO: 'comment_to_edit_info',
  },
  HOME: {
    SCROLL_POSITION: 'home_page_scroll_position',
  },
  IS_CHATGPT_PLAY: 'is_chatGPT_play',
  AUTH_STATUS: 'auth_status',
  REDIRECT_INFO: 'redirect_info',
  NOVEL_CRUISE_MODE: 'novel_cruise_mode',
  NOVEL_VIEWER_THEME: 'novel_viewer_theme',
  STUDIO_REDIRECT_URL: 'studio_redirect_url',
  OAUTH_REDIRECT_URL: 'oauth_redirect_url', // 스토리 릴스 때 생성된 sso 로그인을 위한 redirectUrl
  NOVEL_CFI: 'novel_cfi',
  PAYMENT_FEATURE_VERSION: 'payment_feature_version',
  APP_INFO_FOR_WEBVIEW: 'app_info_for_webview',
  CERTUFICATIOON_RESULT_FOR_APP: 'certification_result_for_app',
  IS_BACKGROUND_SOUND_ON: 'is_background_sound_on',
  IS_EFFECT_SOUND_ON: 'is_effect_sound_on',
  IS_TTS_ON: 'is_tts_on',
  IS_VIBRATE_ON: 'is_vibrate_on',
  IS_SEEN_GUIDE: 'is_seen_guide',
  OPENED_STORYGAME_IDS: 'opened_storygame_ids',
  STORY_GAME_TAB: {
    PREV_PLAYING_STORIES: 'prev_playing_stories',
  },
  SENDED_START_CHAPTER_EVENT_CHAPTER_ID:
    'sended_start_chapter_event_chapter_id',
  STORY_GAME_SESSION_ID: 'story_game_session_id', // 스토리게임 자캐생성시 ai로 생성된 캐릭터 유지를 위한 세션id
};

export const REMOTE_CONFIG_KEY = {
  ONBOARDING_STORIES_WEB: 'onboarding_stories_web',
  HOME_BANNER_STORY_LIST_WEB: 'home_banner_story_list_web',
};

export const IOS_STORE_LINK =
  'https://apps.apple.com/kr/app/%EC%8A%A4%ED%86%A0%EB%A6%AC%ED%94%8C%EB%A0%88%EC%9D%B4-%EC%9D%B8%ED%84%B0%EB%A0%89%ED%8B%B0%EB%B8%8C-%EA%B2%8C%EC%9E%84%ED%98%95-%EC%8A%A4%ED%86%A0%EB%A6%AC/id1537547936';
export const ANDROID_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.thingsflow.storyplay&hl=ko&gl=US';
// 추후에 에어브릿지 딥링크로 변경 예정
export const OLD_DEEP_LINK = 'storyplay://home';
export const OLD_STORY_DEEP_LINK = 'storyplay://stories';
export const FACEBOOK_LINK = 'https://www.facebook.com/playstoryplay/';
export const INSTAGRAM_LINK = 'https://www.instagram.com/storyplay_kr/';
export const NAVER_LINK = 'https://game.naver.com/lounge/storyplay/home';
export const TWITTER_LINK = 'https://twitter.com/storyplay_kr';
export const WORLD_CUP_LINK = 'https://worldcup.thingsflow.com';
export const STUDIO_LOGIN_URL = 'https://studio.storyplay.com/login';
export const YOUTUBE_URL =
  'https://www.youtube.com/c/%EC%8A%A4%ED%94%8C/featured';
export const TIKTOK_URL = 'https://www.tiktok.com/@storyplay_official';
export const NAVER_BLOG_LINK = 'https://blog.naver.com/storyplay_official';

export const BASE_URL = getBaseURL();
export const ENDING_URL = `${BASE_URL}/share/ending`;
export const FINAL_ENDING_URL = `${BASE_URL}/share/final-ending`;
export const STORY_URL = `${BASE_URL}/share/story`;
export const STUDIO_URL = 'https://studio.storyplay.com';
export const STUDIO_URL_DEV = 'https://studio.dev.storyplay.com';

export const AIRBRIDGE_QR_CODE_URL = 'https://abr.ge/@storyplay';

export const MBTI_STORY_ID = 54;
export const MBTI19_STORY_ID = 141;
export const MBTI19_URL = `${BASE_URL}/story/${MBTI19_STORY_ID}`;

export const SHARE_HOUSE_RED_STORY_ID = 7095;
export const SHARE_HOUSE_RED_URL = `${BASE_URL}/story/${SHARE_HOUSE_RED_STORY_ID}`;

// TODO: 성인작품로그인 통합 시 삭제
export const 흥분증후군_ID = 9405;
export const 일처다부제_ID = 9521;
export const 그것이들어오면_ID = 9535;
export const 성적취향테스트_ID = 9561;
export const 벗어야만볼수있는남자_ID = 10396;

export const ADULT_BG_COLOR: any = {
  '141': '#7b0226',
  '7095': '#7d130c',
};

export const DEEP_LINK = {
  OLD_FEATURE_OPEN_APP: 'https://abr.ge/uf1oie',
  NEW_FEATURE_OPEN_APP: 'https://abr.ge/geicfy',
  BOTTOM_SHEET: 'https://abr.ge/5scd8l',
  FLOATING_BANNER: 'https://abr.ge/8sjfsx',
  DETAIL_LIKE: 'https://abr.ge/u7vwil',
  DETAIL_COMMENT: 'https://abr.ge/gvodav',
  DETAIL_POINT: 'https://abr.ge/ahdy91',
  CHAT_COMMENT: 'https://abr.ge/xtwjsc',
  CHAPTER_ENDING_OPEN_APP: 'https://abr.ge/35ngft',
  AUTH_OPEN_APP: 'https://abr.ge/cmcysu',
};

export type RegisterInputNames = 'email' | 'password' | 'nickname' | 'birthday';

interface RegisterInputs {
  [TYPE: string]: {
    NAME: string;
    INPUT_TYPE: string;
    TITLE: string;
    VALID_REGEX: RegExp;
    VALID_WARNING_MESSAGE?: string;
    MAX_LENGTH?: number;
    MIN_AGE?: number;
    PLACEHOLDER?: string;
  };
}

// TODO-GLOBAL t() 사용하도록 수정 필요
// t('snslogin_screen_description_use_8_or_more_alphanumeric_special_charaters')
export const REGISTER_INPUTS: RegisterInputs = {
  email: {
    NAME: 'email',
    INPUT_TYPE: 'text',
    TITLE: 'common_screen_label_email',
    VALID_WARNING_MESSAGE: 'signup_screen_description_invalid_email',
    VALID_REGEX: /^[\w\W-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    PLACEHOLDER: 'common_screen_label_email',
  },
  password: {
    NAME: 'password',
    INPUT_TYPE: 'password',
    TITLE: 'signin_screen_title_password',
    VALID_WARNING_MESSAGE:
      'snslogin_screen_description_use_8_or_more_alphanumeric_special_charaters',
    VALID_REGEX: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[\w\W]{8,20}$/,
    PLACEHOLDER: 'signin_screen_title_password',
  },
  'current-password': {
    NAME: 'current-password',
    INPUT_TYPE: 'password',
    TITLE: 'passchange_screen_title_current_password',
    PLACEHOLDER: 'passchange_screen_title_current_password',
    VALID_REGEX: /.*/,
  },
  'new-password': {
    NAME: 'new-password',
    INPUT_TYPE: 'password',
    TITLE: 'passchange_screen_label_new_password',
    VALID_WARNING_MESSAGE:
      'snslogin_screen_description_use_8_or_more_alphanumeric_special_charaters',
    VALID_REGEX: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[\w\W]{8,20}$/,
    PLACEHOLDER: 'passchange_screen_label_new_password',
  },
  'new-password-again': {
    NAME: 'new-password-again',
    INPUT_TYPE: 'password',
    TITLE: 'passchange_screen_label_check_new_password',
    VALID_WARNING_MESSAGE:
      'snslogin_screen_description_use_8_or_more_alphanumeric_special_charaters',
    VALID_REGEX: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[\w\W]{8,20}$/,
    PLACEHOLDER: 'passchange_screen_label_check_new_password',
  },
  nickname: {
    NAME: 'nickname',
    INPUT_TYPE: 'text',
    TITLE: 'common_screen_title_nickname',
    MAX_LENGTH: 12,
    VALID_REGEX: /^[\w\W]{2,12}$/,
    PLACEHOLDER: 'common_screen_title_nickname',
  },
  birthday: {
    NAME: 'birthday',
    INPUT_TYPE: 'date',
    TITLE: 'birthday_screen_title_birthdate',
    MIN_AGE: 14,
    VALID_REGEX: /.*/,
    VALID_WARNING_MESSAGE: 'signbirth_screen_label_warning',
  },
};

export const REGEX = {
  E_MAIL: /^[\w\W-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  PASSWORD: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[\w\W]{8,}$/,
  LOGIN_PASSWORD: /^[\w\W]{8,}$/,
  NICKNAME: /^[\w\W]{2,12}$/,
};

export const ERROR_MESSAGE = {
  E_MAIL_VALIDATION: 'signup_screen_description_invalid_email',
  PASSWORD_VALIDATION:
    'snslogin_screen_description_use_8_or_more_alphanumeric_special_charaters',
  BIRTHDAY_VALIDATION: 'signbirth_screen_description_fourteen',
};

export const SSO_ERROR_CODE = {
  needSignUp: 'E-31007',
  needSignIn: 'E-31006',
  userAlreadyExists: 'SP-31031',
};

export const SERVER_ERROR_CODE = {
  EMAIL_INVALID: 'SP-30000',
  UNAUTHORIZED: 'SP-31001',
  UNREGISTER: 'SP-31004',
  WRONG_PASSWORD: 'SP-40000',
  CHAPTER_COMMENT_CANNOT_POST_YET: 'SP-56000',
  LIKE_UP_TO_TEN: 'SP-57000',
  CANCEL_LIKE_UP_TO_TEN: 'SP-57002',
  ACCESS_DENIED_TO_DELETE: 'SP-56002',
  COMMENT_ALREADY_REPORTED: 'SP-56100',
  STARTING_CHAPTER_REQUIRES_WILL_USE_COIN: 'SP-31027',
  ADULT_VERIFICATION_REQUIRED: 'SP-72210',
  AI_CHR_TOSS_PAYMENTS_REWARD_ALREADY_CLAIMED: 'SP-104005',
};

export const SERVER_ERROR_MESSAGE = {
  AUTH_TOKEN: 'ServerUnauthorized', // 토큰이 없거나, 유효하지 않을 경우 발생
  USER_LEFT: 'UserLeft', // 유저가 회원 탈퇴했을 경우 발생
};

// t('ugc_screen_title_sort_popular')
// t('ugc_screen_title_sort_update')
// t('ugc_screen_title_sort_view_count')
export const SORTING_CRITERIA: Sorting[] = [
  {
    type: 'RECOMMENDED',
    textKey: 'ugc_screen_title_sort_popular',
  },
  {
    type: 'LATEST_UPDATED',
    textKey: 'ugc_screen_title_sort_update',
  },
  {
    type: 'NUM_VIEWS',
    textKey: 'ugc_screen_title_sort_view_count',
  },
];

// comment 관련

export const DEFAULT_COMMENT_PAGE_COUNT = 10;

export const SORT_BY: {
  [key: string]: ChapterCommentOrderBy | ChapterCommentOrderBy[];
} = {
  createdDate: {
    field: Chapter_Comment_Order_Field['CreatedDate'],
    direction: Order_By_Direction['Desc'],
  },
  best: [
    {
      field: Chapter_Comment_Order_Field['UserLikes'],
      direction: Order_By_Direction['Desc'],
    },
    {
      field: Chapter_Comment_Order_Field['CreatedDate'],
      direction: Order_By_Direction['Asc'],
    },
  ],
};

// https://github.com/apollographql/apollo-client/blob/d96f4578f89b933c281bb775a39503f6cdb59ee8/src/core/networkStatus.ts#L4
// apollo networkStatus 종류

export enum NetworkStatus {
  loading = 1,
  setVariables = 2,
  fetchMore = 3,
  refetch = 4,
  poll = 6,
  ready = 7,
  error = 8,
}

export const SP_TOP_HEADER_TABS = [
  {
    type: STORY_PLAY_TAB_TYPES.storyGame,
    title: 'storygame_screen_title',
  },
  {
    type: STORY_PLAY_TAB_TYPES.interactive,
    title: 'common_screen_title_interactive',
  },
  {
    type: STORY_PLAY_TAB_TYPES.webNovel,
    title: 'common_screen_title_webnovel',
  },
  {
    type: STORY_PLAY_TAB_TYPES.challenge,
    title: 'common_screen_title_challenge_story',
  },
];

export const CHALLENGE_STORY_GENRE = {
  BL_GL: 'BL/GL',
  CONTEST_WINNER: '공모전 수상작',
  ETC: '기타',
  FANTASY: '판타지',
  FAN_FICTION: '팬픽/패러디',
  MODERN_FANTASY: '현대/현대 판타지',
  MYSTERY_PLAY: '추리/미스터리',
  PERIOD_DRAMA: '무협/대체역사',
  ROMANCE: '로맨스',
  ROMANCE_FANTASY: '로맨스 판타지',
  SLICE_OF_LIFE: '시뮬레이션',
  UNKNOWN: '미분류',
};

export const TABS_WITHOUT_ADULT = [
  {
    type: HOME_TAB_TYPE.Home,
    text: {
      key: 'common_genre_title_home',
      ko_KR: '홈',
    },
  },
  {
    type: HOME_TAB_TYPE.WeekDay,
    text: {
      key: 'common_genre_title_ongoing',
      ko_KR: '요일',
    },
  },
];

export const ADULT_TAB = {
  type: HOME_TAB_TYPE.Adult,
  text: {
    key: 'common_genre_title_adult',
    ko_KR: '후방주의',
  },
};

export const WEEK_DAY_TAB_LIST: {
  type: Story_Weekday | 'New' | 'Finished';
  textKey: string;
}[] = [
  {
    type: 'New',
    textKey: 'new',
  },
  {
    type: Story_Weekday.Monday,
    textKey: 'monday',
  },
  {
    type: Story_Weekday.Tuesday,
    textKey: 'tuesday',
  },
  {
    type: Story_Weekday.Wednesday,
    textKey: 'wednesday',
  },
  {
    type: Story_Weekday.Thursday,
    textKey: 'thursday',
  },
  {
    type: Story_Weekday.Friday,
    textKey: 'friday',
  },
  {
    type: Story_Weekday.Saturday,
    textKey: 'saturday',
  },
  {
    type: Story_Weekday.Sunday,
    textKey: 'sunday',
  },
  {
    type: 'Finished',
    textKey: 'finished',
  },
];

export const HOME_TAB_SCROLL_POSITION = 375;

export const DEV_JS_PLAYDATA_URL =
  'https://media.dev.storyplay.com/PlayerData/playDataCalculator.js';

export const PROD_JS_PLAYDATA_URL =
  'https://media.storyplay.com/PlayerData/playDataCalculator.js';

export const GLOBAL_CONFIG_DEV =
  'https://platform.thingsflow.com/stp/dev/globalconfig.json';
export const GLOBAL_CONFIG_PROD =
  'https://platform.thingsflow.com/stp/prod/globalconfig.json';

export const PRIVACY_REGISTER_3RD_PARTY_LINK = `${BASE_URL}/terms/privacy/register_3rdparty`;

// 스겜 / 인터랙티브 / 웹소설 회차별 광고 노출 여부.
// true면 광고 노출, false면 web to app 팝업 노출
export const IS_STORY_GAME_ADS_ON = true;
export const IS_INTERRACTIVE_ADS_ON = true;
export const IS_WEB_NOVEL_ADS_ON = true;
