import { getStoryGameCustomColor } from '@lib/common-react/utils/getStoryGameCustomColor';
import { isWithinInterval, parseISO } from 'date-fns';
import { Translate } from 'next-translate';
import { NextRouter } from 'next/router';
import formatDistance from 'date-fns/formatDistanceStrict';
import secondsToMinutes from 'date-fns/secondsToMinutes';
import secondsToHours from 'date-fns/secondsToHours';
import format from 'date-fns/format';
import koLocale from 'date-fns/locale/ko';
import enLocale from 'date-fns/locale/en-US';
import {
  onReplaceOtherCharacterName,
  onReplacePostPosition,
} from '@service/ChapterService';
import { clickAppButton } from '@lib/eventManager';
import { Section, StoryStatus, StorySortingText } from '@customTypes/event';
import { OtherCharacterInfo } from '@customTypes/chapter';
import { ChatScript, StatementType } from '@/customTypes/chatStory';
import {
  EndingRateRangeLevelType,
  TimeLeapFreePerChapterSettingParsed,
  WeekDayTab,
} from '@customTypes/story';
import { GetUser } from '@customTypes/user';
import { HOME_TAB_TYPE } from '@customTypes/home';
import {
  ANDROID_STORE_LINK,
  OLD_DEEP_LINK,
  IOS_STORE_LINK,
  STORAGE_KEY,
  CHALLENGE_STORY_GENRE,
  TABS_WITHOUT_ADULT,
  ADULT_TAB,
} from '@common/values';
import {
  Story_Weekday,
  Story_Sorting,
  Challenge_Story_Genre,
  Story_Play_Type,
} from '@/baseType';
import getDay from 'date-fns/getDay';
import { HomeStoryGenreItemFragment } from '@/operations/queries/home/__generated__/useHomeStoryGenres.generated';
import theme from '@/styles/theme';
import { setHubbleUserProperties } from '@/lib/hubble';

export const getChatBlocks = (scripts: string) => {
  if (!scripts) {
    return undefined;
  }

  const scriptList: ChatScript = JSON.parse(scripts);
  const blocks = Object.values(scriptList?.blocks);

  return blocks;
};

export const convertCharName = (
  scripts: string,
  firstName: string,
  lastName: string,
) => {
  return scripts
    ?.replaceAll('{{성}}', lastName ? lastName : '')
    ?.replaceAll('{{이름}}', firstName);
};

export const isEmptyString = (text?: string) => {
  if (text === null || text === undefined) {
    return true;
  } else {
    return text?.trim().length < 1;
  }
};

/**
 * @name getSignedNumberString
 * @description 숫자가 양수면 "+"를 붙여 반환하고, 음수면 "-"를 붙인 문자열을 반환한다.
 * @param number 변환할 숫자
 * @return 변환된 문자열
 */
export const getSignedNumberString = (number: number) => {
  if (number > 0) {
    return '+' + String(number);
  }
  return String(number);
};

export const calculateProps = (original?: string, value?: string) => {
  // + - 기호로 시작되는 스트링은 값을 더하고, 단순 숫자는 덮어쓰기 한다.
  if (original === undefined && value === undefined) {
    return undefined;
  } else if (original === undefined) {
    return Number(value);
  } else if (value === undefined) {
    return Number(original);
  }

  const isCalculate = value.startsWith('+') || value.startsWith('-');

  return isCalculate ? Number(original) + Number(value) : Number(value);
};

export const margeChoiceProps = (
  originals: Record<string, number | string>,
  newData: Record<string, number | string>,
) => {
  const keys = new Set([...Object.keys(originals), ...Object.keys(newData)]);
  const newProps: Record<string, number | string> = {};

  Array.from(keys).forEach((item) => {
    const value = (newData[item] ? newData[item] : originals[item]) || 0;
    newProps[item] = value;
  });

  return newProps;
};

export const getStringToDate = (dateString?: string | Date) => {
  if (dateString) {
    const newData = new Date(dateString);

    return `${newData.getFullYear()}.${
      newData.getMonth() + 1
    }.${newData.getDate()}`;
  } else {
    return '0000.00.00';
  }
};

export const getStringToDateByLanguage = (
  language: string,
  dateString?: string | Date,
) => {
  if (dateString) {
    const newData = new Date(dateString);

    if (language === 'ko') {
      return getStringToDate(dateString);
    } else {
      const monthAbbreviations = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];

      return `${newData.getDate()} ${
        monthAbbreviations[newData.getMonth()]
      }, ${newData.getFullYear()}`;
    }
  } else {
    return '0000.00.00';
  }
};

export const getRarityText = (
  t: Translate,
  type?: EndingRateRangeLevelType,
) => {
  switch (type) {
    case 'Normal':
      return t('common_screen_label_ending_rate_normal');
    case 'Rarity':
      return t('common_screen_label_ending_rate_rare');
    case 'VeryRarity':
      return t('common_screen_label_ending_rate_unique');
    case 'Minority':
      return t('common_screen_label_ending_rate_more_unique');
    default:
      return t('common_screen_label_ending_rate_unknown');
  }
};

export const checkDevice = (): DEVICE => {
  if (typeof window === 'undefined') return 'PC';

  const ua = navigator.userAgent.toLowerCase();

  if (ua.includes('android')) {
    return 'ANDROID';
  } else if (ua.includes('iphone') || ua.includes('ipad')) {
    return 'IOS';
  }

  return 'PC';
};

// 모바일앱 내에서 웹뷰로 실행되었나?
export const isMobileDevice = (): boolean => {
  if (typeof window === 'undefined') return false;

  const ua = navigator.userAgent.toLowerCase();

  return ua.endsWith('storyplay_android') || ua.endsWith('storyplay_ios');
};

// 스플 안드로이드앱 내에서 웹뷰로 실행되었나?
export const isAndroid = (): boolean => {
  if (typeof window === 'undefined') return false;

  const ua = navigator.userAgent.toLowerCase();

  return ua.endsWith('storyplay_android');
};

export const isStoryGamePCSize = (): boolean => {
  if (typeof window === 'undefined') return true;
  const minWidth = 1024;

  return window.innerWidth >= minWidth;
};

export const isInApp = (): boolean => {
  const ua = navigator.userAgent.toLowerCase();

  const isKakao = ua.includes('kakao');
  const isInstagram = ua.includes('instagram');
  const isFacebook = ua.includes('fb');
  const isTiktok = ua.includes('trill');
  const isNaver = ua.includes('naver');

  return isKakao || isInstagram || isFacebook || isTiktok || isNaver;
};

export const formatWaitingTime = ({
  seconds,
  isSentence = false,
  t,
}: {
  seconds: number;
  isSentence?: boolean;
  t: Translate;
}): string => {
  const FULL_MINUTES = 60;
  const FULL_SECONDS = 60;
  const FULL_HOURS = 24;
  const SECONDS_PER_DAY = 86400;

  if (seconds >= FULL_HOURS * FULL_MINUTES * FULL_SECONDS) {
    if (isSentence) {
      return t('storydetail_popup_label_description_pay_or_free_after_day', {
        value: Math.ceil(secondsToHours(seconds) / 24),
      });
    }

    return t('storydetail_screen_label_description_free_after_day', {
      value: Math.ceil(seconds / SECONDS_PER_DAY),
    });
  } else if (seconds > FULL_MINUTES * FULL_SECONDS) {
    if (isSentence) {
      return t('storydetail_popup_label_description_pay_or_free_after_hour', {
        value: secondsToHours(seconds),
      });
    }

    return t('storydetail_screen_label_description_free_after_hour', {
      value: secondsToHours(seconds),
    });
  } else {
    if (isSentence) {
      return t('storydetail_popup_label_description_pay_or_free_after_minute', {
        value: secondsToMinutes(seconds) || 1,
      });
    }

    return t('storydetail_screen_label_description_free_after_minute', {
      value: Math.ceil(secondsToMinutes(seconds)),
    });
  }
};

export const emailValidator = (email: string) => {
  const emailRegex = /^[\w\W-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return emailRegex.test(email);
};

export const passwordValidator = (password: string) => {
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^a-zA-Z0-9])[\w\W]{8,}$/g;
  return passwordRegex.test(password);
};

/**
 * @name formatDate
 * @description 날짜 데이터 `yyyy-mm-dd`형식으로 변환
 * @param date 변환할 날짜
 * @param sign 연결 기호(기본값: -)
 * @returns `yyyy-mm-dd`형식의 날짜
 */
export const formatDate = (date: Date, sign = '-'): string => {
  return format(date, `yy${sign}MM${sign}dd`);
};

/**
 * @name shortFormatDate
 * @description 날짜 데이터 `yy.mm.dd`형식으로 변환
 * @param date 변환할 날짜
 * @returns `yy.mm.dd`형식의 날짜
 */
export const shortFormatDate = (date: Date): string => {
  const year = date.getFullYear().toString().slice(-2);
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);

  return `${year}.${month}.${day}`;
};

const koranAgeCalculator = (date: string): number => {
  const today = new Date();
  const birthDate = new Date(date);

  let age = today.getFullYear() - birthDate.getFullYear();
  const month = today.getMonth() - birthDate.getMonth();

  if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
};

export const checkOlder14 = (date: string): boolean => {
  return koranAgeCalculator(date) >= 14;
};

export const clearUserToken = () => {
  if (!localStorage) return;

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    const isStoryInfo = key && key.includes('current_story');

    if (isStoryInfo) {
      localStorage.removeItem(key);
    }
  }
  localStorage.removeItem(STORAGE_KEY.NOVEL_CFI);
  localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN);
};

export const clearUserProperties = () => {
  if (!localStorage) return;

  localStorage.removeItem(STORAGE_KEY.USER_PROPERTIES);
};

export const openYoutubeDeepLink = ({ link }: { link: string }) => {
  const device: DEVICE = checkDevice();
  // device가 PC이면 새 탭에서 링크 열리고, 모바일이면 현재 탭 안에서 링크 열립니다

  if (device === 'PC') {
    window.open(link, '_blank');
    return;
  }

  location.href = link;
};

export const openOldDeepLink = ({
  t,
  router,
  deepLink = OLD_DEEP_LINK,
  iosStoreLink = IOS_STORE_LINK,
  aosStoreLink = ANDROID_STORE_LINK,
}: {
  t: Translate;
  router: NextRouter;
  deepLink?: string;
  iosStoreLink?: string;
  aosStoreLink?: string;
}) => {
  clickAppButton();

  const device: DEVICE = checkDevice();
  const isWebLink = deepLink?.includes('http');

  if (device === 'PC' && !isWebLink) {
    router.push({
      pathname: '/appdownload',
      query: {
        iosStoreLink,
        aosStoreLink,
      },
    });
    return;
  }

  if (isWebLink) {
    window.open(deepLink);
  } else {
    location.href = deepLink;
  }

  const clearTimers = () => {
    clearInterval(checkIsDocumentHidden);
    clearTimeout(redirectStoreAfterTime);
  };

  const checkIsDocumentHidden = setInterval(() => {
    if (document.hidden) {
      clearTimers();
    }
  }, 200);

  const redirectStoreAfterTime = setTimeout(() => {
    if (window.confirm(t('common_popup_button_want_go_store'))) {
      location.href = checkDevice() === 'ANDROID' ? aosStoreLink : iosStoreLink;
    }
  }, 500);
};

export const openDeepLink = (
  router: NextRouter,
  deepLink: string,
  isChat?: boolean,
) => {
  clickAppButton();

  const device: DEVICE = checkDevice();

  if (device === 'PC') {
    isChat
      ? router.replace({
          pathname: '/appdownload',
        })
      : router.push({
          pathname: '/appdownload',
        });

    return;
  }

  location.href = deepLink;
};

interface ISetUserToken {
  token: string;
}

export const setUserToken = ({ token }: ISetUserToken) => {
  clearUserToken();
  localStorage.setItem(STORAGE_KEY.AUTH_TOKEN, token);
};

export const setUserTokenWithoutClear = ({ token }: ISetUserToken) => {
  localStorage.setItem(STORAGE_KEY.AUTH_TOKEN, token);
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const operationDate = ({
  operation,
  baseDateString,
  comparisonDateString,
}: {
  operation: '=' | '>=' | '<=' | '!=';
  baseDateString?: string;
  comparisonDateString?: string | null;
}): boolean => {
  if (!comparisonDateString) return false;

  const baseDate = baseDateString ? new Date(baseDateString) : new Date();
  const comparisonDate = new Date(comparisonDateString);

  switch (operation) {
    case '=':
      return baseDate.getTime() == comparisonDate.getTime();
    case '>=':
      return baseDate.getTime() >= comparisonDate.getTime();
    case '<=':
      return baseDate.getTime() <= comparisonDate.getTime();
    case '!=':
      return baseDate.getTime() != comparisonDate.getTime();
    default:
      return false;
  }
};

export const onTrimAll = (text: string) => {
  const allTrim = /\s/g;
  return text.replace(allTrim, '');
};

export const isDevelopment = () => {
  const serverURI = process.env.NEXT_PUBLIC_SERVER_URI;
  return serverURI?.includes('dev') || serverURI?.includes('stg');
};

export const getBaseURL = () => {
  const serverURI = process.env.NEXT_PUBLIC_SERVER_URI;

  if (serverURI?.includes('platform')) {
    return 'https://platform.dev.storyplay.com';
  } else if (serverURI?.includes('content')) {
    return 'https://content.dev.storyplay.com';
  } else if (serverURI?.includes('dev')) {
    return 'https://dev.storyplay.com';
  } else {
    return 'https://storyplay.com';
  }
};

export const getChatGptURL = () => {
  const serverURI = process.env.NEXT_PUBLIC_SERVER_URI;

  return serverURI?.replace('/graphql', '');
};

export const insertCommas = (number: number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const mergeObject = ({
  originalObj,
  newObj,
}: {
  originalObj: Record<string, number | string>;
  newObj: Record<string, number | string>;
}) => {
  let mergeObj = originalObj;
  const originalKeys = Object.keys(originalObj);

  originalKeys.forEach((item) => {
    if (newObj[item]) {
      const copyObj = { ...mergeObj };
      copyObj[item] = newObj[item];
      mergeObj = copyObj;
    }
  });

  return mergeObj;
};

export const convertAllCharName = ({
  text,
  mainCharacterName,
  otherCharacterNames,
}: {
  text: string;
  mainCharacterName?: {
    firstName: string;
    lastName?: string | null;
  };
  otherCharacterNames?: {
    name?: string;
    defaultFirstName?: string | null;
    defaultLastName?: string | null;
    displayName?: string;
  }[];
}): string => {
  const otherCharacters: OtherCharacterInfo[] =
    otherCharacterNames?.map((item) => {
      return {
        originName: item.name,
        customFirstName: item.defaultFirstName,
        customLastName: item.defaultLastName,
        displayName: item.displayName,
      };
    }) || [];

  let resultText = text;
  if (mainCharacterName) {
    resultText = convertCharName(
      resultText,
      mainCharacterName.firstName,
      mainCharacterName.lastName || '',
    );
  }

  if (otherCharacters.length > 0) {
    resultText = onReplaceOtherCharacterName({
      text: resultText,
      otherCharacters,
    });
  }
  resultText = onReplacePostPosition(resultText);

  return resultText;
};

export const hasBGMStatement = (chapterScript: string) => {
  const allBGMType = /("statementType":"BGMon"|"statementType":"BGMoff")/g;
  return allBGMType.test(chapterScript);
};

export const hasTTSStatement = (chapterScript: string) => {
  const allTTSType =
    /("statementType":"TTSCashing"|"statementType":"CallRemoteScript"|"textType":"TTS")/g;
  return allTTSType.test(chapterScript);
};

export const getToastTime = (message: string) => {
  const defaultMsec = 600;
  const addMsec = message.length * 100;
  return defaultMsec + addMsec;
};

export const getAdultCheckType = ({
  isAdultType,
  userData,
}: {
  isAdultType: boolean;
  userData?: GetUser;
}): 'adult' | 'auth' | 'pass' => {
  if (isAdultType && userData?.getUser?.isAnonymous) {
    return 'auth';
  } else if (
    isAdultType &&
    (userData?.getUser?.isExpiredCertification !== false ||
      !userData?.getUser.certificatedAt)
  ) {
    return 'adult';
  } else {
    return 'pass';
  }
};

export const formatNumber = (number: number): string => {
  const digitInfoList = [
    { value: 1000000, symbol: 'M' },
    { value: 1000, symbol: 'K' },
    { value: 1, symbol: '' },
  ];
  const digitInfo = digitInfoList.find((item) => number >= item.value);

  if (!digitInfo) {
    return '0';
  } else {
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const formattedNumber = (number / digitInfo.value)
      .toFixed(1) // 소숫점 자릿수를 한 자릿수로 제한함(ex: 123.456 => 123.5)
      .replace(rx, '$1'); // 소수 맨 뒤에 붙는 0을 제거(ex: 123.0 => 123)

    return formattedNumber + digitInfo.symbol;
  }
};

/**
 * @name formatDistanceFromNow
 * @description 현재 날짜와의 거리를 `0일전`, `0분전` 등의 형식으로 반환
 * (https://date-fns.org/v2.29.2/docs/formatDistanceStrict 문서 참고)
 * @param date 변환할 날짜
 * @param locale 텍스트('ko', 'en')
 * @returns `0일전`, `0분전` 등의 형식의 문자열
 */
export const formatDistanceFromNow = ({
  locale,
  date,
}: {
  locale?: string;
  date: string;
}) => {
  const now = new Date();
  const createdAt = new Date(date);
  const dateLocale = locale === 'ko' ? koLocale : enLocale;

  return formatDistance(createdAt, now, {
    locale: dateLocale,
    addSuffix: true,
  });
};

export const setUserProperties = ({
  registeredAt,
  isTester,
  userId,
  email,
  language,
  hashedEmail,
  countryCode,
  isAdult,
  isAnonymous,
}: {
  registeredAt: Date;
  isTester: boolean;
  userId: number;
  email?: string;
  language?: string;
  hashedEmail?: string;
  countryCode?: string;
  isAdult?: boolean;
  isAnonymous: boolean;
}) => {
  if (!localStorage) return;

  const properties = {
    user_id: userId,
    sign_up_at: registeredAt,
    is_tester: isTester,
    email,
    language,
    hashedEmail,
    countryCode,
    isAdult: Boolean(isAdult),
    is_anonymous: isAnonymous,
  };
  localStorage.setItem(STORAGE_KEY.USER_PROPERTIES, JSON.stringify(properties));

  // Hubble에 사용자 정보 전달
  setHubbleUserProperties({ user_id: userId, isAnonymous: isAnonymous });
};

/**
 * @name convertMonthText
 * @description `22' AUG` 형태의 텍스트를 `22년 8월`로 변환
 * @param text 변환할 텍스트
 * @returns `22년 8월` 등의 형식의 문자열
 */

export const convertMonthText = (text: string) => {
  const splitText = text.split("' ");
  const translatedYear = splitText[0] + '년';
  const month = splitText[1];

  switch (month) {
    case 'JAN':
      return `${translatedYear} 1월`;
    case 'FEB':
      return `${translatedYear} 2월`;
    case 'MAR':
      return `${translatedYear} 3월`;
    case 'APR':
      return `${translatedYear} 4월`;
    case 'MAY':
      return `${translatedYear} 5월`;
    case 'JUN':
      return `${translatedYear} 6월`;
    case 'JUL':
      return `${translatedYear} 7월`;
    case 'AUG':
      return `${translatedYear} 8월`;
    case 'SEP':
      return `${translatedYear} 9월`;
    case 'OCT':
      return `${translatedYear} 10월`;
    case 'NOV':
      return `${translatedYear} 11월`;
    case 'DEC':
      return `${translatedYear} 12월`;
  }
};

/**
 * @name splitArrayIntoN
 * @description 배열을 n개씩 나누어 이중 배열을 만들어 반환한다
 * @param array 나눌 배열 ex) ['A', 'B', 'C', 'D', 'E']
 * @param n 배열을 나눌 개수 ex) 2
 * @returns n개씩 쪼개진 이중배열 ex)[['A', 'B'], ['C', 'D'], ['E']]
 */

export const splitArrayIntoN = (array: any[], n: number) => {
  const newArray = [...array];
  const divide = Math.ceil(newArray.length / n);
  const splittedArray = [];

  for (let i = 0; i < divide; i++) {
    // 배열 0부터 n개씩 잘라 새 배열에 넣기
    splittedArray.push(newArray.splice(0, n));
  }

  return splittedArray;
};

/**
 * @name getWeekDaySectionName
 * @description 요일탭에서 GA 이벤트 파라미터에 필요한 section 이름을 반환하는 함수.
 */
export const getWeekDaySectionName = (week: WeekDayTab): Section => {
  switch (week as WeekDayTab) {
    case 'Finished':
      return '요일_완결';
    case 'New':
      return '요일_신작';
    case Story_Weekday.Monday:
      return '요일_월';
    case Story_Weekday.Tuesday:
      return '요일_화';
    case Story_Weekday.Wednesday:
      return '요일_수';
    case Story_Weekday.Thursday:
      return '요일_목';
    case Story_Weekday.Friday:
      return '요일_금';
    case Story_Weekday.Saturday:
      return '요일_토';
    case Story_Weekday.Sunday:
      return '요일_일';

    default:
      return '';
  }
};

/**
 * @name getWeekDaySectionName
 * @description 요일타입으로부터 요일 한글 텍스트를 반환하는 함수
 * @param 서버로부터 받아온 요일 타입(ex) MON)
 * @return 한글 텍스트(ex) 월)
 */
export const getWeekDayText = (week: Story_Weekday) => {
  switch (week) {
    case Story_Weekday.Monday:
      return '월';
    case Story_Weekday.Tuesday:
      return '화';
    case Story_Weekday.Wednesday:
      return '수';
    case Story_Weekday.Thursday:
      return '목';
    case Story_Weekday.Friday:
      return '금';
    case Story_Weekday.Saturday:
      return '토';
    case Story_Weekday.Sunday:
      return '일';
  }
};

/**
 * @name getStatusText
 * @description 스토리의 상태('완결', '연재중', '휴재') 텍스트를 반환하는 함수
 * @param isFinished 완결된 작품인가
 * @param onHiatus 휴재중인가
 * @return 스토리 상태 텍스트('완결', '연재중', '휴재')
 */
export const getStatusText = ({
  isFinished,
  onHiatus,
}: {
  isFinished: boolean;
  onHiatus: boolean;
}): StoryStatus => {
  return isFinished ? '완결' : onHiatus ? '휴재' : '연재중';
};

/**
 * @name getLabelText
 * @description 스토리의 상태 텍스트를 반환하는 함수
 * @param status 스토리의 상태 텍스트('완결', '연재중', '휴재')
 * @param weekdays 스토리의 요일 리스트
 * @return 연재요일, 신작/완결/휴재 텍스트가 각각 쉼표로 구분된 하나의 string
 */
export const getLabelText = ({
  currentHomeTab,
  currentWeekTab,
  isFinished,
  isOnHiatus,
  isNew,
  isUp,
  weekdays,
}: {
  currentHomeTab: HOME_TAB_TYPE;
  currentWeekTab?: WeekDayTab;
  isFinished: boolean;
  isOnHiatus: boolean;
  isNew?: boolean;
  isUp: boolean;
  weekdays?:
    | {
        weekday: Story_Weekday;
      }[]
    | null;
}): string | undefined => {
  const labelList: string[] = [];

  // 완결된 작품이 아니고 새롭게 나온 작품인 경우 'UP'라벨을 보여준다.
  if (!isFinished && isUp) {
    labelList.push('UP');
  }

  // 현재 탭이 요일탭인 경우
  if (currentHomeTab === HOME_TAB_TYPE.WeekDay) {
    // 신작탭이면서 연재중인 경우 요일별 라벨을 보여준다.

    if (currentWeekTab === 'New' && !isFinished && !isOnHiatus) {
      weekdays?.forEach((week) => labelList.push(getWeekDayText(week.weekday)));
    }

    // 요일별탭이면서 신작인 경우 '신작'라벨을 보여준다.
    if (currentWeekTab !== 'New' && currentWeekTab !== 'Finished' && isNew) {
      labelList.push('신작');
    }

    // 신작탭이거나 요일별탭이고 완결된 작품이 아니고 휴재중인 작품인 경우 '휴재'라벨을 보여준다.
    if (currentWeekTab !== 'Finished' && !isFinished && isOnHiatus) {
      labelList.push('휴재');
    }
  }

  if (labelList.length >= 1) {
    return labelList.reduce((acc, curr) => acc + ', ' + curr);
  }
};

/**
 * @name getOrderText
 * @description 스토리 정렬 텍스트를 반환하는 함수
 */
export const getOrderText = (
  sorting: Story_Sorting,
): StorySortingText | undefined => {
  if (sorting === 'DAILY_RANK_ASC') {
    return '인기순';
  }

  if (sorting === 'PUBLISHED_DESC') {
    return '최신순';
  }
};

/**
 * @name getOrderText
 * @description 스토리 장르 필터 텍스트를 반환하는 함수
 */
export const getFilterText = (genre: Challenge_Story_Genre | 'TOTAL') => {
  return genre === 'TOTAL' ? '전체' : CHALLENGE_STORY_GENRE[genre];
};

/**
 * @name getCurrentWeekText
 * @description 현재 요일의 타입을 반환하는 함수
 * @return 연재요일, 신작/완결/휴재 텍스트가 각각 쉼표로 구분된 하나의 string
 */
export const getCurrentWeekType = () => {
  const now = new Date();
  const currentWeekNumber = getDay(now);

  switch (currentWeekNumber) {
    case 0:
      return Story_Weekday.Sunday;
    case 1:
      return Story_Weekday.Monday;
    case 2:
      return Story_Weekday.Tuesday;
    case 3:
      return Story_Weekday.Wednesday;
    case 4:
      return Story_Weekday.Thursday;
    case 5:
      return Story_Weekday.Friday;
    case 6:
      return Story_Weekday.Saturday;

    default:
      return Story_Weekday.Sunday;
  }
};

/**
 * @name formatMoreLink
 * @description 더보기 페이지 링크를 포매팅하는 함수
 * @param sectionName 더보기 페이지 섹션 텍스트(morestory/0000)
 * @param playType 홈 | 인터랙티브 | 웹소설
 * @param genreType 장르 타입
 * @return 더보기 페이지 url
 */

export const formatMoreLink = ({
  sectionName,
  playType,
  genreType,
}: {
  sectionName:
    | 'monthly'
    | 'adult'
    | 'finished'
    | 'genre'
    | 'new'
    | 'original'
    | 'ranking'
    | 'favorite'
    | 'history'
    | 'ugc';
  genreType?: string;
  playType?: Story_Play_Type;
}) => {
  if (playType) {
    if (genreType) {
      return `/morestory/${sectionName}?playType=${playType}&genreType=${genreType}`;
    }

    return `/morestory/${sectionName}?playType=${playType}`;
  } else {
    if (genreType) {
      return `/morestory/${sectionName}?genreType=${genreType}`;
    }

    return `/morestory/${sectionName}`;
  }
};

/**
 * @name getHomeSectionName
 * @description playType별로 previousSection 문자열을 만들어서 반환하는 함수
 * @param playType "Interactive" | "EPUB" | undefined
 * @param string 섹션명 ex) "월간스토리" | "신작스토리"
 * @return (홈_ | 인터랙티브_ | 웹소설_)섹션명 (_더보기)
 */

export const getHomeSectionName = ({
  playType,
  section,
  isMore,
}: {
  playType?: Story_Play_Type;
  section: string;
  isMore?: boolean;
}): Section => {
  const trimmedSection = section.replace(/ /g, '');

  if (playType === 'Interactive') {
    if (isMore) {
      return `인터랙티브_${trimmedSection}_더보기`;
    }
    return `인터랙티브_${trimmedSection}`;
  } else if (playType === 'EPUB') {
    if (isMore) {
      return `웹소설_${trimmedSection}_더보기`;
    }
    return `웹소설_${trimmedSection}`;
  }
  if (isMore) {
    return `홈_${trimmedSection}_더보기`;
  }
  return `홈_${trimmedSection}`;
};

export const getHomeWeekdayTabText = ({
  t,
  type,
}: {
  type: string;
  t: Translate;
}) => {
  switch (type) {
    case 'finished':
      return t('home_screen_title_week_finished');
    case 'friday':
      return t('home_screen_title_week_friday');
    case 'monday':
      return t('home_screen_title_week_mon');
    case 'new':
      return t('home_screen_title_week_new');
    case 'saturday':
      return t('home_screen_title_week_sat');
    case 'sunday':
      return t('home_screen_title_week_sun');
    case 'thursday':
      return t('home_screen_title_week_thu');
    case 'tuesday':
      return t('home_screen_title_week_tue');
    case 'wednesday':
      return t('home_screen_title_week_wed');
    default:
      return '';
  }
};

// 본인인증 여부에 따라서 상단 카테고리 순서 변경
export const getTabList = ({
  isAdultCertificationDone,
  genreTabs,
}: {
  isAdultCertificationDone: boolean;
  genreTabs: HomeStoryGenreItemFragment[];
}) => {
  if (isAdultCertificationDone) {
    return [...TABS_WITHOUT_ADULT, ADULT_TAB, ...genreTabs];
  }

  return [...TABS_WITHOUT_ADULT, ...genreTabs, ADULT_TAB];
};

export const calculatePercentage = (value: number, maxValue: number) => {
  if (value < 0 || maxValue < 0) return 0;

  return (value / maxValue) * 100;
};

// 두 색상의 밝기 차이를 계산하여 새로운 색상을 반환하는 함수
// 기본 색상
// 폰트: white
// 배경: blackA75

// 배경색만 지정된 경우
// 폰트
// 지정된 배경 색상이 luminance 50% 초과이면(밝으면) black
// 지정된 배경 색상이 luminance 50% 이하이면(어두우면) white
// 배경: 지정된 색상

// 폰트색만 지정된 경우
// 폰트: 지정된 색상
// 배경
// 지정된 폰트 색상이 luminance 50% 초과이면(밝으면) blackA75
// 지정된 폰트 색상이 luminance 50% 이하이면(어두우면) whiteA75

// 둘 다 지정된 경우
// 폰트: 지정된 색상
// 배경: 지정된 색상
export const getCustomColor = ({
  customFontColors,
  customBackgroundColor,
  statementType,
}: {
  customFontColors?: (string | undefined)[];
  customBackgroundColor?: string;
  statementType?: StatementType;
}) =>
  getStoryGameCustomColor({
    customFontColors,
    customBackgroundColor,
    statementType,
    theme,
  });

/**
 * HTTPS URL로 제공된 이미지를 top-center로 크롭하는 함수
 * @param imageUrl 크롭할 이미지의 HTTPS URL
 * @param width 원하는 크롭 너비
 * @param height 원하는 크롭 높이
 * @returns 크롭된 이미지의 데이터 URL을 담은 Promise
 */
export function cropImageTopCenterFromUrl(imageUrl: string): Promise<File> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Canvas context not found'));
        return;
      }

      // 크롭 영역 계산
      const cropTop = 0;
      const cropLeft = 30;
      const cropWidth = img.width - 30;
      const cropHeight = cropWidth; // 전체 높이의 1/4

      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx.drawImage(
        img,
        cropLeft,
        cropTop,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight,
      );

      canvas.toBlob((blob) => {
        if (blob) {
          // Blob을 File 객체로 변환
          const file = new File([blob], 'cropped_image.png', {
            type: 'image/png',
          });
          resolve(file);
        } else {
          reject(new Error('Failed to create blob'));
        }
      }, 'image/png');
    };

    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = imageUrl;
  });
}
export const safeJSONParse = <T>(
  jsonString: string | null | undefined,
): T | null => {
  if (!jsonString) {
    return null;
  }

  try {
    return JSON.parse(jsonString) as T;
  } catch (error) {
    console.error('safeJSONParse.error: ', error);
    return null;
  }
};

/**
 * 현재 시간이 주어진 시작 시간과 종료 시간 사이에 있는지 확인합니다.
 *
 * @param {string} startAt - ISO 8601 형식의 시작 시간 문자열
 * @param {string} endAt - ISO 8601 형식의 종료 시간 문자열
 * @returns {boolean} 현재 시간이 주어진 범위 내에 있으면 true, 그렇지 않으면 false
 *
 */
export const isCurrentIsoTimeWithinRange = (
  startAt: string,
  endAt: string,
): boolean => {
  try {
    const now = new Date();

    return isWithinInterval(now, {
      start: parseISO(startAt),
      end: parseISO(endAt),
    });
  } catch (error) {
    console.error('isCurrentIsoTimeWithinRange.error: ', error);
    return false;
  }
};

/**
 * JSON 문자열로 된 스토리 타임리프 이벤트 데이터의 활성 상태를 확인합니다.
 * 현재 시간이 이벤트의 시작 시간과 종료 시간 사이에 있는지 검사합니다.
 *
 * @param {string} jsonString - 스토리 타임리프 이벤트 데이터가 담긴 JSON 문자열
 * @returns {boolean} 이벤트가 현재 활성 상태이면 true, 그렇지 않으면 false
 *
 */
export const checkStoryTimeLeapEventActive = (
  jsonString: string | null | undefined,
): boolean => {
  if (!jsonString) {
    return false;
  }

  const parsed = safeJSONParse<TimeLeapFreePerChapterSettingParsed>(jsonString);

  if (!parsed || !parsed.startAt || !parsed.endAt) {
    return false;
  }

  return isCurrentIsoTimeWithinRange(parsed.startAt, parsed.endAt);
};

/**
 * 주어진 접두사로 시작하는 모든 로컬스토리지 항목의 원본 값을 배열로 반환합니다.
 * 이 함수는 값을 파싱하지 않고 저장된 그대로의 문자열을 반환합니다.
 * @param {string} prefix - 로컬스토리지 키를 필터링할 접두사
 * @returns {string[]} 일치하는 모든 로컬스토리지 항목의 원본 값(파싱되지 않은)을 포함하는 배열
 */
export const getRawLocalStorageValueArrayByPrefix = (
  prefix: string,
): string[] => {
  const rawValues: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith(prefix)) {
      const value = localStorage.getItem(key);
      if (value !== null) {
        rawValues.push(value);
      }
    }
  }

  return rawValues;
};

export const safeJSONStringify = <T extends object>(
  jsonString: T,
): string | null => {
  try {
    return JSON.stringify(jsonString);
  } catch (error) {
    console.error('safeJSONStringify.error: ', error);
    return null;
  }
};

const colorNameToHex = (colorName: string) => {
  const colors: { [index: string]: string } = {
    aliceblue: '#f0f8ff',
    antiquewhite: '#faebd7',
    aqua: '#00ffff',
    aquamarine: '#7fffd4',
    azure: '#f0ffff',
    beige: '#f5f5dc',
    bisque: '#ffe4c4',
    black: '#000000',
    blanchedalmond: '#ffebcd',
    blue: '#0000ff',
    blueviolet: '#8a2be2',
    brown: '#a52a2a',
    burlywood: '#deb887',
    cadetblue: '#5f9ea0',
    chartreuse: '#7fff00',
    chocolate: '#d2691e',
    coral: '#ff7f50',
    cornflowerblue: '#6495ed',
    cornsilk: '#fff8dc',
    crimson: '#dc143c',
    cyan: '#00ffff',
    darkblue: '#00008b',
    darkcyan: '#008b8b',
    darkgoldenrod: '#b8860b',
    darkgray: '#a9a9a9',
    darkgreen: '#006400',
    darkkhaki: '#bdb76b',
    darkmagenta: '#8b008b',
    darkolivegreen: '#556b2f',
    darkorange: '#ff8c00',
    darkorchid: '#9932cc',
    darkred: '#8b0000',
    darksalmon: '#e9967a',
    darkseagreen: '#8fbc8f',
    darkslateblue: '#483d8b',
    darkslategray: '#2f4f4f',
    darkturquoise: '#00ced1',
    darkviolet: '#9400d3',
    deeppink: '#ff1493',
    deepskyblue: '#00bfff',
    dimgray: '#696969',
    dodgerblue: '#1e90ff',
    firebrick: '#b22222',
    floralwhite: '#fffaf0',
    forestgreen: '#228b22',
    fuchsia: '#ff00ff',
    gainsboro: '#dcdcdc',
    ghostwhite: '#f8f8ff',
    gold: '#ffd700',
    goldenrod: '#daa520',
    gray: '#808080',
    green: '#008000',
    greenyellow: '#adff2f',
    honeydew: '#f0fff0',
    hotpink: '#ff69b4',
    indianred: '#cd5c5c',
    indigo: '#4b0082',
    ivory: '#fffff0',
    khaki: '#f0e68c',
    lavender: '#e6e6fa',
    lavenderblush: '#fff0f5',
    lawngreen: '#7cfc00',
    lemonchiffon: '#fffacd',
    lightblue: '#add8e6',
    lightcoral: '#f08080',
    lightcyan: '#e0ffff',
    lightgoldenrodyellow: '#fafad2',
    lightgrey: '#d3d3d3',
    lightgreen: '#90ee90',
    lightpink: '#ffb6c1',
    lightsalmon: '#ffa07a',
    lightseagreen: '#20b2aa',
    lightskyblue: '#87cefa',
    lightslategray: '#778899',
    lightsteelblue: '#b0c4de',
    lightyellow: '#ffffe0',
    lime: '#00ff00',
    limegreen: '#32cd32',
    linen: '#faf0e6',
    magenta: '#ff00ff',
    maroon: '#800000',
    mediumaquamarine: '#66cdaa',
    mediumblue: '#0000cd',
    mediumorchid: '#ba55d3',
    mediumpurple: '#9370d8',
    mediumseagreen: '#3cb371',
    mediumslateblue: '#7b68ee',
    mediumspringgreen: '#00fa9a',
    mediumturquoise: '#48d1cc',
    mediumvioletred: '#c71585',
    midnightblue: '#191970',
    mintcream: '#f5fffa',
    mistyrose: '#ffe4e1',
    moccasin: '#ffe4b5',
    navajowhite: '#ffdead',
    navy: '#000080',
    oldlace: '#fdf5e6',
    olive: '#808000',
    olivedrab: '#6b8e23',
    orange: '#ffa500',
    orangered: '#ff4500',
    orchid: '#da70d6',
    palegoldenrod: '#eee8aa',
    palegreen: '#98fb98',
    paleturquoise: '#afeeee',
    palevioletred: '#d87093',
    papayawhip: '#ffefd5',
    peachpuff: '#ffdab9',
    peru: '#cd853f',
    pink: '#ffc0cb',
    plum: '#dda0dd',
    powderblue: '#b0e0e6',
    purple: '#800080',
    rebeccapurple: '#663399',
    red: '#ff0000',
    rosybrown: '#bc8f8f',
    royalblue: '#4169e1',
    saddlebrown: '#8b4513',
    salmon: '#fa8072',
    sandybrown: '#f4a460',
    seagreen: '#2e8b57',
    seashell: '#fff5ee',
    sienna: '#a0522d',
    silver: '#c0c0c0',
    skyblue: '#87ceeb',
    slateblue: '#6a5acd',
    slategray: '#708090',
    snow: '#fffafa',
    springgreen: '#00ff7f',
    steelblue: '#4682b4',
    tan: '#d2b48c',
    teal: '#008080',
    thistle: '#d8bfd8',
    tomato: '#ff6347',
    turquoise: '#40e0d0',
    violet: '#ee82ee',
    wheat: '#f5deb3',
    white: '#ffffff',
    whitesmoke: '#f5f5f5',
    yellow: '#ffff00',
    yellowgreen: '#9acd32',
  };
  if (typeof colors[colorName.toLowerCase()] !== 'undefined') {
    return colors[colorName.toLowerCase()];
  }
};

export const convertHexToRGB = (color: string, alpha?: number) => {
  const hexReg = new RegExp(/^\#(([0-9a-f]){3}|([0-9a-f]){6}|([0-9a-f]){8})$/i);
  const rgbaReg = new RegExp(/^rgb/);

  if (rgbaReg.test(color)) return color;
  if (!hexReg.test(color)) return colorNameToHex(color);

  const colorCode = color.replace('#', '');
  const hexCode =
    colorCode.length === 3
      ? `${colorCode[0]}${colorCode[0]}${colorCode[1]}${colorCode[1]}${colorCode[2]}${colorCode[2]}`
      : colorCode;

  const r = parseInt(hexCode.substring(0, 2), 16);
  const g = parseInt(hexCode.substring(2, 4), 16);
  const b = parseInt(hexCode.substring(4, 6), 16);
  const a = (parseInt(hexCode.substring(6, 8), 16) / 255).toFixed(2);

  if (alpha || !isNaN(Number(a))) {
    return `rgba(${r},${g},${b},${alpha ?? a})`;
  } else {
    return `rgb(${r},${g},${b})`;
  }
};
