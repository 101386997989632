import React, { useEffect, useRef } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import theme from '@styles/theme';

interface IToast {
  message: string | JSX.Element;
  onClear: () => void;
  customCSS?: SerializedStyles;
}

const Toast = ({ message, onClear, customCSS }: IToast) => {
  const timerRef = useRef<NodeJS.Timeout>();
  const toastRef = useRef<HTMLDivElement>(null);
  const toastFadeOutKeyframesRef = useRef<KeyframeEffect>();
  const toastFadeOutAnimationRef = useRef<Animation>();

  useEffect(() => {
    toastFadeOutKeyframesRef.current = new KeyframeEffect(
      toastRef.current,
      [{ opacity: 0.8 }, { opacity: 0 }],
      { delay: 2700, duration: 400 },
    );

    toastFadeOutAnimationRef.current = new Animation(
      toastFadeOutKeyframesRef.current,
      document.timeline,
    );
  }, []);

  useEffect(() => {
    if (message) {
      // 토스트가 보여지기 시작하면 애니메이션 부착
      toastFadeOutAnimationRef.current?.play();
      showToast();
    }
    return () => {
      // toastRef.current에 부착된 애니메이션이 존재하면, 기존 애니메이션 삭제
      toastRef.current?.getAnimations()[0]?.cancel();
      clearTimeOut();
    };
  }, [message]);

  const showToast = () => {
    timerRef.current = setTimeout(() => {
      onClear();
    }, 3000);
  };

  const clearTimeOut = () => {
    timerRef.current && clearTimeout(timerRef.current);
  };

  return (
    <div css={[wrapper, customCSS]} ref={toastRef}>
      <span className="toast-text">{message || ''}</span>
    </div>
  );
};

const wrapper = css`
  position: fixed;
  max-width: 335px;
  border: 1px solid ${theme.colors.gray300};
  display: flex;
  justify-content: center;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.4);
  border-radius: 1.5em;
  border: none;
  padding: 1em 0.6em;
  font: ${theme.font.body2Normal};
  opacity: 0.8;
  color: ${theme.colors.gray600};
  background-color: ${theme.colors.white};
  bottom: 6em;
  z-index: 20;

  .toast-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;

    text-align: center;
    font-size: 1em;
    font-weight: 400;
  }
`;

export default Toast;
