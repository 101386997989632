import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import theme from '@styles/theme';
import { css } from '@emotion/react';

import FullScreenPopupContainer from './container/FullScreenPopupContainer';
import { LogoTypeIcon } from 'public/assets';
import Button from '../button/Button';
import Link from 'next/link';
import { useAcceptTermsMutation } from '@/operations/mutations/user/__generated__/useAcceptTerms.generated';
import { TermsDtoFragment } from '@/operations/queries/user/__generated__/getUser.generated';
import SPLoading from '../loading/SPLoading';

interface ITermsPopup {
  isShow: boolean;
  onClose: () => void;
  data: TermsDtoFragment | null;
}

const TwoLinkText = ({
  message,
  firstLinkWord,
  firstUrl = '',
  secondLinkWord,
  secondUrl = '',
}: {
  message: string;
  firstLinkWord: string;
  firstUrl?: string;
  secondLinkWord: string;
  secondUrl?: string;
}) => {
  return (
    <p css={linkTextWrapper}>
      {message
        .split(new RegExp(`(${firstLinkWord})`, 'gi'))
        ?.reduce((acc: string[], current) => {
          return [
            ...acc,
            ...current.split(new RegExp(`(${secondLinkWord})`, 'gi')),
          ];
        }, [])
        .map((word) =>
          word.toLowerCase() === firstLinkWord.toLowerCase() ? (
            <Link href={firstUrl} key={firstUrl}>
              <a
                onClick={(e) => e.stopPropagation()}
                target="_blank"
                className="link-text"
              >
                {word}
              </a>
            </Link>
          ) : word.toLowerCase() === secondLinkWord.toLowerCase() ? (
            <Link href={secondUrl} key={secondUrl}>
              <a
                onClick={(e) => e.stopPropagation()}
                target="_blank"
                className="link-text"
              >
                {word}
              </a>
            </Link>
          ) : (
            word
          ),
        )}
    </p>
  );
};

const TermsPopup = ({ isShow, onClose, data }: ITermsPopup) => {
  const { t } = useTranslation();
  const [acceptTerms, { loading }] = useAcceptTermsMutation({
    onCompleted: onClose,
  });

  const handleStartButtonClick = () => {
    data &&
      acceptTerms({
        variables: {
          collectionAndUseOfPrivacyId: data.CollectionAndUseOfPrivacy?.termsId,
          tosId: data.TOS.termsId,
          ...(data?.CollectionAndUseOfPrivacy && {
            collectionAndUseOfPrivacyId:
              data?.CollectionAndUseOfPrivacy.termsId,
          }),
          ...(data?.PP && {
            ppId: data?.PP.termsId,
          }),
        },
      });
  };

  if (!isShow) {
    return null;
  }

  return (
    <FullScreenPopupContainer
      onClose={onClose}
      hasBackDrop={false}
      hasHeader={false}
      customCSS={css`
        background: white;
      `}
    >
      <div css={container}>
        <LogoTypeIcon className="logo-icon" />
        <h1 className="header-title">
          {t('new_terms_agreement_popup_label_title')}
        </h1>
        <p className="header-description">
          {t('new_terms_agreement_popup_label_description')}
        </p>
        <div className="terms-agree">
          <h3 className="title">
            {t('new_terms_agreement_popup_label_description_required')}
          </h3>
          <div className="description">
            <TwoLinkText
              message={
                data?.CollectionAndUseOfPrivacy
                  ? t('new_terms_agreement_popup_label_description_link')
                  : t('new_terms_agreement_popup_etc_label_description_link')
              }
              firstLinkWord={t('new_terms_agreement_popup_tos')}
              firstUrl={data?.TOS.link}
              secondLinkWord={
                data?.CollectionAndUseOfPrivacy
                  ? t(
                      'new_terms_agreement_popup_collection_and_use_of_personal_info',
                    )
                  : t('new_terms_agreement_popup_pp')
              }
              secondUrl={
                data?.CollectionAndUseOfPrivacy
                  ? data.CollectionAndUseOfPrivacy.link
                  : data?.PP?.link
              }
            />
          </div>
        </div>
        <Button
          title={t('common_screen_button_start')}
          customCSS={buttonStyle}
          isFill
          onClick={handleStartButtonClick}
        />
        {loading && <SPLoading />}
      </div>
    </FullScreenPopupContainer>
  );
};

const buttonStyle = css`
  position: fixed;
  bottom: 1.5rem;
  height: 3.25rem;
  width: calc(100% - 2rem);
  max-width: calc(${theme.maxWidth} - 2rem);
  border-radius: 0.5rem;
  font: ${theme.font.body1Bold};
  font-weight: 700;
  cursor: pointer;
`;

const container = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 5.25rem 1.25rem;

  .logo-icon {
    flex-shrink: 0;
  }

  .header-title {
    margin-top: 0.5rem;
    line-height: 2rem;
    color: ${theme.colors.gray900};
    ${theme.font.header.headline2}
  }

  .header-description {
    margin-top: 1.25rem;
    line-height: 1.25rem;
    color: ${theme.colors.gray700};
    ${theme.font.body.body2}
  }

  .terms-agree {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    height: 100%;

    .title {
      color: ${theme.colors.gray800};
      ${theme.font.header.subhead3}
    }

    .description {
      margin-top: 0.125rem;
      color: ${theme.colors.gray700};
      ${theme.font.body.body2}
    }
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    padding: 7.75rem 1.25rem 10.25rem;

    .terms-agree {
      margin-top: 0;
      justify-content: flex-end;
    }
  }
`;

const linkTextWrapper = css`
  .link-text {
    color: ${theme.colors.primary500};
    text-decoration-line: underline;
  }
`;

export default TermsPopup;
