export const BRANCH_KEY = process.env.NEXT_PUBLIC_BRANCH_KEY;

declare global {
  interface Window {
    branch: any;
  }
}

export const startChapter = (name: string, index: number) => {
  window.branch.logEvent('start_chapter', { story: name, chapter: index });
};

export const endChapter = (name: string, index: number) => {
  window.branch.logEvent('end_chapter', { story: name, chapter: index });
};
