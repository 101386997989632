import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import { FloatingCloseIcon, BottomAppIcon } from 'public/assets';
import { DEEP_LINK } from '@common/values';
import { STORAGE_KEY } from '@common/values';
import theme from '@styles/theme';
import {
  viewAppConversionBanner,
  touchCloseAppConversionBanner,
} from '@lib/eventManager';
import { openDeepLink } from '@common/utils';

const FloatingBanner = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [isShow, setIsShow] = useState<boolean>(false);

  useEffect(() => {
    viewAppConversionBanner();

    const isShow = localStorage.getItem(STORAGE_KEY.IS_SHOW_FLOATING) || 'true';
    setIsShow(JSON.parse(isShow));
  }, []);

  const onClose = () => {
    touchCloseAppConversionBanner();
    localStorage.setItem(STORAGE_KEY.IS_SHOW_FLOATING, 'false');
    setIsShow(false);
  };

  return (
    <>
      {isShow && (
        <div css={container}>
          <div className="logo-container">
            <button className="close" onClick={onClose}>
              <FloatingCloseIcon />
            </button>
            <BottomAppIcon className="logo" />
            <div className="content">
              <div>{t('common_popup_description_if_you_play_on_app')}</div>
              <div className="bold-text">
                {t('common_popup_description_yout_progress_save')}
              </div>
            </div>
          </div>
          <button
            className="app-button"
            onClick={() => openDeepLink(router, DEEP_LINK.FLOATING_BANNER)}
          >
            {t('common_screen_button_app_download')}
          </button>
        </div>
      )}
    </>
  );
};

const container = css`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  width: 100%;
  max-width: ${theme.maxWidth};
  height: 5.5em;
  background-color: ${theme.colors.gray100};
  padding: 1em 1.25em;
  z-index: 20;

  .logo-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .logo {
    border-radius: 0.85em;
  }

  .app-button {
    width: 94px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    padding: 1em 0;
    border: 1px solid ${theme.colors.primary500};
    color: ${theme.colors.primary500};
    font-size: 0.8em;
  }

  .content {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    font-style: normal;
    font-size: 0.875em;

    .bold-text {
      font-weight: bold;
    }
  }

  .close {
    cursor: pointer;
  }
`;

export default FloatingBanner;
