
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import React from 'react';
import * as Sentry from '@sentry/nextjs';
import { SessionProvider } from 'next-auth/react';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from '@lib/apollo';
import { createApolloClient } from '@lib/apolloForWebview';
import { Provider } from 'react-redux';
import { Global, ThemeProvider } from '@emotion/react';
import { GlobalStyles, GlobalStylesForStoryGame } from '@styles/globals';
import theme from '@styles/theme';
import store from '@store/store';
import Layout from '@components/layout/Layout';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { Story_Play_Type } from '@/baseType';

const MyApp = ({ Component, pageProps }: AppProps) => {
  const client = useApollo(pageProps);
  const clientForWebview = createApolloClient();

  const router = useRouter();

  return (
    <Sentry.ErrorBoundary>
      <Provider store={store}>
        <SessionProvider session={pageProps.session}>
          <ThemeProvider theme={theme}>
            <Global
              styles={
                pageProps.playType === Story_Play_Type.Storygame
                  ? GlobalStylesForStoryGame
                  : GlobalStyles
              }
            />
            {router.pathname.includes('webview') ? (
              <ApolloProvider client={clientForWebview}>
                <Layout playType={pageProps.playType}>
                  <Component {...pageProps} />
                </Layout>
              </ApolloProvider>
            ) : (
              <ApolloProvider client={client}>
                <Layout playType={pageProps.playType}>
                  <Component {...pageProps} />
                </Layout>
              </ApolloProvider>
            )}
          </ThemeProvider>
        </SessionProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  