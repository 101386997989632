import { STORAGE_KEY } from '@/common/values';
import {
  ThemeColor,
  FontSize,
  LineHeight,
  LetterSpacing,
  SetViewerStyle,
  FlowType,
} from '@/customTypes/novelStory';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EpubChapterFragment,
  EpubStoryFragment,
  GetWebNovelChapterQuery,
} from '@/operations/queries/chapter/__generated__/useWebNovelChapter.generated';
import { WebNovelChapterDtoFragment } from '@/operations/queries/novelChapter/__generated__/useWebNovelChapterList.generated';

interface INovelState {
  progress: number;
  flowType: FlowType;
  themeColor: ThemeColor;
  fontSize: FontSize;
  lineHeight: LineHeight;
  letterSpacing: LetterSpacing;
  isCruiseMode: boolean;
  toastMessage: string | null;
  novelChapterInfo: EpubChapterFragment | null;
  novelStoryInfo: EpubStoryFragment | null;
  isShowFullScreen: boolean;
  bgColor: string;
  willUseCoin: boolean;
  isShowEnding: boolean;
  notReadChapter: WebNovelChapterDtoFragment[];
}

let customTheme: {
  flowType: FlowType;
  themeColor: ThemeColor;
  fontSize: FontSize;
  lineHeight: LineHeight;
  letterSpacing: LetterSpacing;
} | null = null;

if (typeof window !== 'undefined') {
  const rawCustomTheme = localStorage.getItem(STORAGE_KEY.NOVEL_VIEWER_THEME);
  customTheme = rawCustomTheme && JSON.parse(rawCustomTheme);
}

const initialState: INovelState = {
  progress: 0,
  flowType: customTheme?.flowType || 'paginated',
  themeColor: customTheme?.themeColor || 'white/black',
  fontSize: customTheme?.fontSize || '16',
  lineHeight: customTheme?.lineHeight || '24',
  letterSpacing: customTheme?.letterSpacing || '-0.3',
  isCruiseMode: false,
  toastMessage: null,
  novelChapterInfo: null,
  novelStoryInfo: null,
  isShowFullScreen: false,
  bgColor: 'white',
  willUseCoin: false,
  isShowEnding: false,
  notReadChapter: [],
};

const novelChapterSlice = createSlice({
  name: 'novel',
  initialState,
  reducers: {
    setProgress(state, action: PayloadAction<number>) {
      state.progress = action.payload;
    },
    setViewerStyle(state, action: PayloadAction<SetViewerStyle>) {
      const { viewerMode, themeColor, fontSize, lineHeight, letterSpacing } =
        action.payload;
      state.flowType = (viewerMode as FlowType) ?? 'paginated';
      state.themeColor = themeColor;
      state.fontSize = fontSize;
      state.lineHeight = lineHeight;
      state.letterSpacing = letterSpacing;
      state.bgColor = themeColor.split('/')[0];
      localStorage.setItem(
        STORAGE_KEY.NOVEL_VIEWER_THEME,
        JSON.stringify({
          themeColor,
          fontSize,
          lineHeight,
          letterSpacing,
          flowType: (viewerMode as FlowType) ?? 'paginated',
        }),
      );
    },
    setCruiseMode(state, action: PayloadAction<boolean>) {
      state.isCruiseMode = action.payload;
    },
    setToastMessage(state, action: PayloadAction<string | null>) {
      state.toastMessage = action.payload;
    },
    setNovelInfo(state, action: PayloadAction<GetWebNovelChapterQuery | null>) {
      if (action.payload) {
        state.novelChapterInfo = action.payload.chapter;
        state.novelStoryInfo = action.payload.story;
        return;
      }
      state.novelChapterInfo = null;
      state.novelStoryInfo = null;
    },
    updateEpubLink(state, action: PayloadAction<string | undefined>) {
      if (state.novelChapterInfo?.epubFile && action.payload) {
        state.novelChapterInfo.epubFile.link = action.payload;
      }
    },
    setIsShowFullScreen(state, action: PayloadAction<boolean>) {
      state.isShowFullScreen = action.payload;
    },
    setWillUseCoin(state, action: PayloadAction<boolean>) {
      state.willUseCoin = action.payload;
    },
    setIsShowEnding(state, action: PayloadAction<boolean>) {
      state.isShowEnding = action.payload;
    },
    setNotReadChapter(state, action: PayloadAction<any>) {
      state.notReadChapter = action.payload;
    },
  },
});

export const {
  setProgress,
  setViewerStyle,
  setCruiseMode,
  setToastMessage,
  setNovelInfo,
  setIsShowFullScreen,
  setWillUseCoin,
  setIsShowEnding,
  updateEpubLink,
  setNotReadChapter,
} = novelChapterSlice.actions;

export default novelChapterSlice;
