import React from 'react';
import useNavigationBackOrHome from '@/hooks/useNavigationBackOrHome';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { NavBackBtn } from 'public/assets';
import { isMobileDevice } from '@/common/utils';
import { goBackForApp } from '@/lib/webview';

interface IBackHeader {
  onBack?: () => void;
  title: string;
  hasBorderBottom?: boolean;
  isTitleCenter?: boolean;
  height?: 'small' | 'large';
}

const BackHeader = ({
  onBack,
  title,
  hasBorderBottom = false,
  isTitleCenter = false,
  height = 'large',
}: IBackHeader) => {
  const goBackOrHome = useNavigationBackOrHome();

  return (
    <header css={container({ hasBorderBottom, isTitleCenter, height })}>
      <NavBackBtn
        width={22}
        height={22}
        stroke={theme.colors.gray800}
        className="icon"
        onClick={
          isMobileDevice() ? goBackForApp : onBack ? onBack : goBackOrHome
        }
      />
      <div className="title">{title}</div>
    </header>
  );
};

const container = ({
  hasBorderBottom,
  isTitleCenter,
  height,
}: {
  hasBorderBottom: boolean;
  isTitleCenter: boolean;
  height: 'small' | 'large';
}) => css`
  position: fixed;
  top: env(safe-area-inset-top);
  padding: 1.375rem 1.25rem;
  width: 100%;
  max-width: ${theme.maxWidth};
  height: ${`${height === 'large' ? `4rem` : `2.75rem`}`};
  display: flex;
  align-items: center;
  background: ${theme.colors.white};
  z-index: 10;

  ${hasBorderBottom &&
  `border-bottom: 0.0625rem solid ${theme.colors.gray200};`}

  .icon {
    cursor: pointer;
  }

  .title {
    color: ${theme.colors.gray900};
    ${theme.font.header.navigation};

    ${isTitleCenter
      ? ` 
      position: absolute;
      left: 50%;
      transform: translateX(-50%);`
      : `   
      font: ${theme.font.title2Bold};`};
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    width: 100%;
  }
`;

export default BackHeader;
