import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AlertState {
  message?: string;
  toastMessage?: string | null;
  type?: 'warning' | 'info';
}

const initialState: AlertState = { message: undefined, type: 'warning' };

const alertSlices = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    alertMessage(state, action: PayloadAction<AlertState>) {
      state.message = action.payload.message;
      state.type = action.payload.type || 'warning';
    },
    setToastMessage(state, action: PayloadAction<string | null>) {
      state.toastMessage = action.payload;
    },
    onClose(state) {
      state.message = undefined;
    },
  },
});

export const { alertMessage, setToastMessage, onClose } = alertSlices.actions;
export default alertSlices;
