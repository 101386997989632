import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AcceptTermsMutationVariables = Types.Exact<{
  tosId: Types.Scalars['Int'];
  collectionAndUseOfPrivacyId?: Types.InputMaybe<Types.Scalars['Int']>;
  ppId?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type AcceptTermsMutation = { __typename?: 'Mutation', acceptTermsV2: { __typename?: 'OkResponse', ok: number } };


export const AcceptTermsDocument = gql`
    mutation AcceptTerms($tosId: Int!, $collectionAndUseOfPrivacyId: Int, $ppId: Int) {
  acceptTermsV2(
    data: {collectionAndUseOfPrivacyId: $collectionAndUseOfPrivacyId, tosId: $tosId, ppId: $ppId}
  ) {
    ok
  }
}
    `;
export type AcceptTermsMutationFn = Apollo.MutationFunction<AcceptTermsMutation, AcceptTermsMutationVariables>;

/**
 * __useAcceptTermsMutation__
 *
 * To run a mutation, you first call `useAcceptTermsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsMutation, { data, loading, error }] = useAcceptTermsMutation({
 *   variables: {
 *      tosId: // value for 'tosId'
 *      collectionAndUseOfPrivacyId: // value for 'collectionAndUseOfPrivacyId'
 *      ppId: // value for 'ppId'
 *   },
 * });
 */
export function useAcceptTermsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTermsMutation, AcceptTermsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTermsMutation, AcceptTermsMutationVariables>(AcceptTermsDocument, options);
      }
export type AcceptTermsMutationHookResult = ReturnType<typeof useAcceptTermsMutation>;
export type AcceptTermsMutationResult = Apollo.MutationResult<AcceptTermsMutation>;
export type AcceptTermsMutationOptions = Apollo.BaseMutationOptions<AcceptTermsMutation, AcceptTermsMutationVariables>;