import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import {
  UseFormRegister,
  FieldValues,
  RegisterOptions,
  UseFormSetFocus,
} from 'react-hook-form';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { DeleteBtn, VisibilityOffBtn, VisibilityBtn } from 'public/assets';

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  maxLength?: number;
  showVisibleIcon?: boolean;
  showDeleteIcon?: boolean;
  error: any;
  register?: UseFormRegister<FieldValues>;
  rules?: RegisterOptions;
  currentLength?: number;
  onDelete?: () => void;
  setFocus?: UseFormSetFocus<FieldValues>;
}

const TextInput = ({
  register,
  label,
  maxLength,
  showVisibleIcon,
  showDeleteIcon,
  currentLength,
  error,
  type,
  name = '',
  rules,
  id,
  onDelete,
  setFocus,
  ...props
}: TextInputProps) => {
  const [inputType, setInputType] = useState<string>(type || 'text');

  useEffect(() => {
    setFocus && id && setFocus(id);
  }, []);

  return (
    <div
      css={container({
        error,
        showVisibleIcon,
        showDeleteIcon,
      })}
    >
      <label htmlFor={id}>{label}</label>
      <div className="input-wrapper">
        {register ? (
          <input
            {...register(id || '', rules || {})}
            type={inputType}
            {...props}
          />
        ) : (
          <input type={inputType} {...props} />
        )}
        <div className="icon-container">
          {showDeleteIcon && (
            <DeleteBtn
              width={24}
              height={24}
              className="delete-button icon"
              onClick={onDelete}
            />
          )}
          {type === 'password' && (
            <>
              {inputType === 'password' ? (
                <VisibilityOffBtn
                  width={24}
                  height={24}
                  className="visibility-button icon"
                  onClick={() => setInputType('text')}
                />
              ) : (
                <VisibilityBtn
                  width={24}
                  height={24}
                  className="visibility-button icon"
                  onClick={() => setInputType('password')}
                />
              )}
            </>
          )}
        </div>
        <div className="validation-text">{error || ''}</div>
        {maxLength && (
          <div className="text-length-checker">
            <span>{currentLength || 0} </span>/<span> {maxLength}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const container = ({
  error,
  showVisibleIcon,
  showDeleteIcon,
}: {
  error?: string;
  showVisibleIcon?: boolean;
  showDeleteIcon?: boolean;
}) => css`
  margin-top: 2.5em;
  height: 7rem;

  label {
    display: block;
    font: ${theme.font.body2Normal};
    color: ${theme.colors.gray700};
    margin-bottom: 1.75em;
  }

  .input-wrapper {
    width: 100%;
    height: 2.75em;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 0.0625em solid ${theme.colors.gray300};

    input {
      width: 100%;
      border: none;
      padding: 0.25em 0;
      color: ${theme.colors.gray900};
      font: ${theme.font.title2Normal};
      font-weight: regular;
      display: flex;
      line-height: 28px;
      letter-spacing: -0.3px;
      font-family: 'Apple SD Gothic Neo';
      font-style: normal;
      text-decoration: none;
    }

    input::placeholder {
      color: ${theme.colors.gray400};
      font: ${theme.font.body3Normal};
    }

    .icon-container {
      display: flex;

      icon {
        cursor: pointer;
      }

      .delete-button {
        margin-right: 0.8rem;
        cursor: pointer;
      }

      .visibility-button {
        margin-right: 0.8rem;
      }
    }

    .validation-text {
      position: absolute;
      visibility: hidden;
      top: 3.3rem;
      color: ${theme.colors.danger600};
      font: ${theme.font.body2Normal};
    }

    .text-length-checker {
      position: absolute;
      top: 3em;
      right: 0;
      color: ${theme.colors.gray600};
      font: ${theme.font.body1Normal};
    }
  }

  .input-wrapper:focus-within {
    border-bottom: 0.0625em solid ${theme.colors.primary500};
  }

  ${'password' === 'password' &&
  `
    .input-wrapper .delete-button {
      right: 2.2em;
    }
  `}

  ${error &&
  `
    .input-wrapper:focus-within {
      border-bottom: 0.0625em solid ${theme.colors.danger500};

      .validation-text {
        visibility: visible;
      }
    }
  `}

  ${'birthday' === 'birthday' &&
  error &&
  `
      .input-wrapper {
        border-bottom: 0.0625em solid ${theme.colors.danger500};
  
        .validation-text {
          visibility: visible;
        }
      }
    `}
`;
export default TextInput;
