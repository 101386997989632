import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PlayChapterInfo,
  PlayChapter,
  LastPlay,
  ListAchievements,
} from '@customTypes/chapter';
import { getChapterInfo, updateLastPlay } from '../service/ChapterService';

interface IAdInfoStorage {
  [storyId: number]: boolean;
}

interface IChapter {
  playChapterInfo?: PlayChapterInfo;
  isLoading: boolean;
  isRestarting: boolean;
  willUseCoin: boolean;
  isContinue: boolean;
  adInfoStorage: IAdInfoStorage;
  showWholePurchasePopup: boolean;
  isUpdateLastPlayDataDone: boolean; // 마지막 플레이 정보 업데이트 완료 여부
  /*
   * 플레이 중 최종화 엔딩을 본 후 '처음부터 다시 시작' 을 눌렀을 때, isRestarting 을 true 로 설정해주었으나
   * 플레이 화면 이탈 시(1화로 이동될 때) StartStoryLayout.tsx 에서 clearPlayChapter() 로 인해 isRestarting 이 false 로 설정되어
   * 타임리프로 인식을 할 수 없어 해당 값을 추가해 주었습니다.
   */
  isRestartingForRemainingAfterClear: boolean; // isRestarting 값을 보존해주기 위한 값
}

const defaultSate: IChapter = {
  playChapterInfo: undefined,
  isLoading: false,
  isRestarting: false,
  willUseCoin: false,
  isContinue: false,
  adInfoStorage: {},
  showWholePurchasePopup: false,
  isUpdateLastPlayDataDone: false,
  isRestartingForRemainingAfterClear: false,
};

const chatChapterSlice = createSlice({
  name: 'chapter',
  initialState: defaultSate,
  reducers: {
    setPlayChapter(
      state: IChapter,
      action: PayloadAction<{
        playChapterInfo: PlayChapter;
        achievements?: ListAchievements;
      }>,
    ) {
      const chapterInfo: PlayChapter = action.payload.playChapterInfo;
      const achievements: ListAchievements | undefined =
        action.payload.achievements;
      const playChapterInfo: PlayChapterInfo | undefined = chapterInfo
        ? getChapterInfo(chapterInfo, state.isRestarting, achievements)
        : undefined;

      state.playChapterInfo = playChapterInfo || state.playChapterInfo;
    },
    setIsLoading(state: IChapter, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setIsRestarting(state: IChapter, action: PayloadAction<boolean>) {
      state.isRestarting = action.payload;
    },
    setIsContinue(state: IChapter, action: PayloadAction<boolean>) {
      state.isContinue = action.payload;
    },
    clearPlayChapter(state: IChapter) {
      state.playChapterInfo = undefined;
      state.isLoading = false;
      state.isRestarting = state.isRestartingForRemainingAfterClear;
      state.isUpdateLastPlayDataDone = false;
    },
    setUpdateLastPlay(state: IChapter, action: PayloadAction<LastPlay>) {
      const chapterInfo = state.playChapterInfo;
      if (chapterInfo) {
        const playChapterInfo = updateLastPlay(chapterInfo, action.payload);
        state.playChapterInfo = playChapterInfo;
        state.isUpdateLastPlayDataDone = true;
      }
    },
    setAdInfo(
      state: IChapter,
      action: PayloadAction<{ storyId: number; isAdEnabled: boolean }>,
    ) {
      state.adInfoStorage[action.payload.storyId] = action.payload.isAdEnabled;
    },
    setWillUseCoin(state: IChapter, action: PayloadAction<boolean>) {
      state.willUseCoin = action.payload;
    },

    setShowWholePurchasePopup(state: IChapter, action: PayloadAction<boolean>) {
      state.showWholePurchasePopup = action.payload;
    },
    setIsRestartingForRemainingAfterClear(
      state: IChapter,
      action: PayloadAction<boolean>,
    ) {
      state.isRestartingForRemainingAfterClear = action.payload;
    },
  },
});

export const {
  setPlayChapter,
  setIsLoading,
  setIsRestarting,
  setWillUseCoin,
  setIsContinue,
  clearPlayChapter,
  setUpdateLastPlay,
  setAdInfo,
  setShowWholePurchasePopup,
  setIsRestartingForRemainingAfterClear,
} = chatChapterSlice.actions;
export default chatChapterSlice;
