import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { isDevelopment } from '@common/utils';
import alertSlice from '@slices/alertSlice';
import chatChapterSlice from '@/slices/chatChapterSlice';
import userSlice from '@slices/userSlice';
import adSlice from '@slices/adSlice';
import commentSlice from '@slices/commentSlice';
import reCommentSlices from '@slices/reCommentSlice';
import eventSlice from '@slices/eventSlice';
import novelChapterSlice from '@/slices/novelChapterSlice';

const isDev = isDevelopment();

const rootReducer = combineReducers({
  alert: alertSlice.reducer,
  chapter: chatChapterSlice.reducer,
  user: userSlice.reducer,
  ad: adSlice.reducer,
  comment: commentSlice.reducer,
  reComment: reCommentSlices.reducer,
  event: eventSlice.reducer,
  novel: novelChapterSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  preloadedState: {},
  devTools: isDev,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
