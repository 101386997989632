import React from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';

interface ILoading {
  hasBackDrop?: boolean;
  isFixed?: boolean;
  size?: string;
}

const SPLoading = ({ hasBackDrop, isFixed = false, size }: ILoading) => {
  return (
    <div css={wrapper({ isFixed, size })}>
      {hasBackDrop && <div className="back-drop" />}
      <div className="loading"></div>
    </div>
  );
};

const wrapper = ({
  isFixed,
  size,
}: {
  isFixed?: boolean;
  size?: string;
}) => css`
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
  ${isFixed &&
  `
    position: static;
    padding: 1em;
  `}
  .back-drop {
    opacity: 0.3;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
  }
  .loading {
    width: ${size ? size : '8em'};
    height: ${size ? size : '8em'};
    border-radius: 50%;
    border: 5px solid ${theme.colors.primary500};
    border-top: 3px solid transparent;
    animation: spin 1s linear infinite;
    @media screen and (max-width: ${theme.devices.mobile}) {
      width: ${size ? size : '4em'};
      height: ${size ? size : '4em'};
      border-width: 3px;
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default SPLoading;
