import { Section, ContentType } from '@/customTypes/event';
import { viewStoryThumbnail } from '@/lib/eventManager';
import { useEffect, RefObject } from 'react';

// storyItem이 화면에 보여지면 viewStoryThumbnail 이벤트를 발생시키는 hook

interface IUseVisibleStoryItemEvent {
  storyListRef: RefObject<HTMLElement>;
  section?: Section;
}
export const useVisibleStoryItemEvent = ({
  storyListRef,
  section,
}: IUseVisibleStoryItemEvent) => {
  useEffect(() => {
    if (!storyListRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            storyListRef.current &&
            entry.target instanceof HTMLElement
          ) {
            viewStoryThumbnail({
              section: section || '',
              index: entry.target.dataset.index,
              story: entry.target.dataset.name,
              storyId: entry.target.dataset.id,
              contentType: entry.target.dataset.contentType as ContentType,
            });
          }
        });
      },
      {
        threshold: 0.8,
      },
    );

    // 스토리 아이템 각각에 옵저버 부착
    storyListRef.current.childNodes.forEach((node) =>
      observer.observe(node as Element),
    );

    return () => {
      observer.disconnect();
    };
  }, []);
};
