import React from 'react';
import { FloatingPrevBtn } from 'public/assets';
import { css } from '@emotion/react';
import theme from '@styles/theme';

interface IPrevArrowButton {
  onButtonClick: React.MouseEventHandler<HTMLDivElement>;
  isShow: boolean;
}

const PrevArrowButton = ({ onButtonClick, isShow }: IPrevArrowButton) => {
  if (!isShow) {
    return null;
  }

  return (
    <FloatingPrevBtn
      css={wrapper}
      onClick={onButtonClick}
      width={32}
      height={32}
    />
  );
};

const wrapper = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.95;
  z-index: 2;
  border-radius: 0.25em;
  cursor: pointer;
  left: -1.25rem;

  @media screen and (max-width: ${theme.devices.mobile}) {
    display: none;
  }
`;

export default PrevArrowButton;
