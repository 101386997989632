import { useEffect, useState } from 'react';
// import { GLOBAL_CONFIG_DEV, GLOBAL_CONFIG_PROD } from '@common/values';
// import { isDevelopment } from '@/common/utils';

export const useFetchGlobalConfig = () => {
  const [appStatus] = useState<AppStatus | undefined>(undefined);
  // const [appStatus, setAppStatus] = useState<AppStatus | undefined>(undefined);

  // const fetchGlobalConfig = () => {
  //   const globalConfigURL = isDevelopment()
  //     ? GLOBAL_CONFIG_DEV
  //     : GLOBAL_CONFIG_PROD;
  //   fetch(globalConfigURL, { cache: 'no-store' })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setAppStatus({
  //         ...res.serviceCheck,
  //       });
  //     });
  // };

  useEffect(() => {
    // TODO: SPDEV-4938 서버 점검 시 서버 점검 메시지 띄우기
    // fetchGlobalConfig();
  }, []);

  return {
    appStatus,
  };
};
