import React, {
  forwardRef,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
} from 'react';
import useTranslation from 'next-translate/useTranslation';
import debounce from 'lodash/debounce';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { DeleteBtn, VisibilityOffBtn, VisibilityBtn } from 'public/assets';
import { REGISTER_INPUTS } from '@common/values';
import { checkOlder14 } from '@common/utils';

interface IAuthInput {
  type:
    | 'email'
    | 'password'
    | 'birthday'
    | 'nickname'
    | 'current-password'
    | 'new-password'
    | 'new-password-again';
  initialValue?: string;
  onInputValue?: Dispatch<SetStateAction<string>>;
  isLogin?: boolean;
}

const AuthInput = forwardRef(
  (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    { type, initialValue, onInputValue = () => {}, isLogin }: IAuthInput,
    ref?: React.LegacyRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState<string>(initialValue || '');
    const [inputType, setInputType] = useState<string>(
      REGISTER_INPUTS[type].INPUT_TYPE,
    );
    const [isValid, setIsValid] = useState<boolean>(true);

    const onInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      debouncedInputValidator(e.target.value);
    };

    const inputValidator = (input: string) => {
      if (isLogin) {
        onInputValue(input);
        return;
      }

      const regex = REGISTER_INPUTS[type].VALID_REGEX;
      const isValid =
        REGISTER_INPUTS[type].NAME === 'birthday'
          ? checkOlder14(input)
          : regex.test(input);

      setIsValid(isValid);
      isValid ? onInputValue(input) : onInputValue('');
    };

    const debouncedInputValidator = useMemo(
      () => debounce(inputValidator, 300),
      [],
    );

    return (
      <div css={container({ isValid, type })}>
        <div className="input-wrapper">
          <input
            type={inputType}
            id={type}
            value={inputValue}
            onChange={onInputValueChange}
            ref={ref}
            placeholder={t(REGISTER_INPUTS[type].PLACEHOLDER || '')}
          />
          <div className="icon-container">
            {type !== 'birthday' && (
              <>
                {inputValue.length > 0 && (
                  <DeleteBtn
                    width={24}
                    height={24}
                    className="delete-button"
                    onClick={() => setInputValue('')}
                  />
                )}
              </>
            )}
            {type.includes('password') && (
              <>
                {inputType === 'password' ? (
                  <VisibilityOffBtn
                    width={24}
                    height={24}
                    className="visibility-button"
                    onClick={() => setInputType('text')}
                  />
                ) : (
                  <VisibilityBtn
                    width={24}
                    height={24}
                    className="visibility-button"
                    onClick={() => setInputType('password')}
                  />
                )}
              </>
            )}
          </div>
          {type === 'nickname' && (
            <div className="text-length-checker">
              <span>{inputValue.length} </span>/
              <span> {REGISTER_INPUTS[type].MAX_LENGTH}</span>
            </div>
          )}
        </div>
      </div>
    );
  },
);

const container = ({
  isValid,
  type,
}: {
  isValid: boolean;
  type: string;
}) => css`
  margin-top: 2.5em;
  height: 7rem;

  label {
    display: block;
    font: ${theme.font.body2Normal};
    color: ${theme.colors.gray700};
    margin-bottom: 1.75em;
  }

  .input-wrapper {
    width: 100%;
    height: 2.75em;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 0.0625em solid ${theme.colors.gray300};

    input {
      width: 100%;
      border: none;
      padding: 0.25em 0;
      color: ${theme.colors.gray900};
      font: ${theme.font.title2Normal};
      font-weight: regular;
      display: flex;
      line-height: 28px;
      letter-spacing: -0.3px;
      font-family: 'Apple SD Gothic Neo';
      font-style: normal;
    }

    input::placeholder {
      color: ${theme.colors.gray400};
      font: ${theme.font.title2Normal};
      font-size: 18px;
    }

    .icon-container {
      display: flex;

      .delete-button {
        margin-right: 0.8rem;
        cursor: pointer;
      }

      .visibility-button {
        margin-right: 0.8rem;
      }
    }

    .validation-text {
      position: absolute;
      visibility: hidden;
      top: 3.3rem;
      color: ${theme.colors.danger600};
      font: ${theme.font.body2Normal};
    }

    .text-length-checker {
      position: absolute;
      top: 3em;
      right: 0;
      color: ${theme.colors.gray600};
      font: ${theme.font.body1Normal};
    }
  }

  .input-wrapper:focus-within {
    border-bottom: 0.0625em solid ${theme.colors.primary500};
  }

  ${type === 'password' &&
  `
    .input-wrapper .delete-button {
      right: 2.2em;
    }
  `}

  ${type !== 'nickname' &&
  !isValid &&
  `
    .input-wrapper:focus-within {
      border-bottom: 0.0625em solid ${theme.colors.danger500};

      .validation-text {
        visibility: visible;
      }
    }
  `}

  ${type === 'birthday' &&
  !isValid &&
  `
      .input-wrapper {
        border-bottom: 0.0625em solid ${theme.colors.danger500};
  
        .validation-text {
          visibility: visible;
        }
      }
    `}
`;

AuthInput.displayName = 'AuthInput';
export default AuthInput;
