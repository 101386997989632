import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserInfoDtoFragment = { __typename?: 'User', email: string, userId: number, authorUpdatePushEnabledAt?: any | null, currentCoin: number, birthDateToken?: string | null, priv: number, countryCode: string, isTester: boolean, certificatedAt?: any | null, isExpiredCertification?: boolean | null, registeredAt?: any | null, isAnonymous: boolean, ssoLogins: Array<{ __typename?: 'SSO', ssoProvider: Types.Sso_Provider }>, activatedSkipAds?: { __typename?: 'UserPurchasedSkipAds', willExpireAt: any, expired: boolean } | null, myStory?: { __typename?: 'MyStory', stat: { __typename?: 'MyStoryStat', numStory: number, numChapter: number, numViews: number, numLikes: number, numComment: number } } | null, systemLanguage?: { __typename?: 'Language', code: string, display: string } | null };

export type InitUserSystemLanguageMutationVariables = Types.Exact<{
  languageCode: Types.Scalars['String'];
}>;


export type InitUserSystemLanguageMutation = { __typename?: 'Mutation', initUserSystemLanguage: { __typename?: 'User', email: string, userId: number, authorUpdatePushEnabledAt?: any | null, currentCoin: number, birthDateToken?: string | null, priv: number, countryCode: string, isTester: boolean, certificatedAt?: any | null, isExpiredCertification?: boolean | null, registeredAt?: any | null, isAnonymous: boolean, ssoLogins: Array<{ __typename?: 'SSO', ssoProvider: Types.Sso_Provider }>, activatedSkipAds?: { __typename?: 'UserPurchasedSkipAds', willExpireAt: any, expired: boolean } | null, myStory?: { __typename?: 'MyStory', stat: { __typename?: 'MyStoryStat', numStory: number, numChapter: number, numViews: number, numLikes: number, numComment: number } } | null, systemLanguage?: { __typename?: 'Language', code: string, display: string } | null } };

export const UserInfoDtoFragmentDoc = gql`
    fragment UserInfoDto on User {
  email
  userId
  authorUpdatePushEnabledAt
  currentCoin
  birthDateToken
  priv
  countryCode
  isTester
  certificatedAt
  isExpiredCertification
  ssoLogins {
    ssoProvider
  }
  registeredAt
  activatedSkipAds {
    willExpireAt
    expired
  }
  myStory {
    stat {
      numStory
      numChapter
      numViews
      numLikes
      numComment
    }
  }
  systemLanguage {
    code
    display
  }
  isAnonymous
}
    `;
export const InitUserSystemLanguageDocument = gql`
    mutation InitUserSystemLanguage($languageCode: String!) {
  initUserSystemLanguage(data: {languageCode: $languageCode}) {
    ...UserInfoDto
  }
}
    ${UserInfoDtoFragmentDoc}`;
export type InitUserSystemLanguageMutationFn = Apollo.MutationFunction<InitUserSystemLanguageMutation, InitUserSystemLanguageMutationVariables>;

/**
 * __useInitUserSystemLanguageMutation__
 *
 * To run a mutation, you first call `useInitUserSystemLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitUserSystemLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initUserSystemLanguageMutation, { data, loading, error }] = useInitUserSystemLanguageMutation({
 *   variables: {
 *      languageCode: // value for 'languageCode'
 *   },
 * });
 */
export function useInitUserSystemLanguageMutation(baseOptions?: Apollo.MutationHookOptions<InitUserSystemLanguageMutation, InitUserSystemLanguageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitUserSystemLanguageMutation, InitUserSystemLanguageMutationVariables>(InitUserSystemLanguageDocument, options);
      }
export type InitUserSystemLanguageMutationHookResult = ReturnType<typeof useInitUserSystemLanguageMutation>;
export type InitUserSystemLanguageMutationResult = Apollo.MutationResult<InitUserSystemLanguageMutation>;
export type InitUserSystemLanguageMutationOptions = Apollo.BaseMutationOptions<InitUserSystemLanguageMutation, InitUserSystemLanguageMutationVariables>;