export const getLuminance = (r: number, g: number, b: number) => {
  return ((0.2126 * r + 0.7152 * g + 0.0722 * b) / 255) * 100
}

export function colorCodeToRgb(colorCode: string) {
  const result =
    /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorCode) ||
    /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/.exec(colorCode)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

// 두 색상의 밝기 차이를 계산하여 새로운 색상을 반환하는 함수
// 기본 색상
// 폰트: white
// 배경: blackA75

// 배경색만 지정된 경우
// 폰트
// 지정된 배경 색상이 luminance 50% 초과이면(밝으면) black
// 지정된 배경 색상이 luminance 50% 이하이면(어두우면) white
// 배경: 지정된 색상

// 폰트색만 지정된 경우
// 폰트: 지정된 색상
// 배경
// 지정된 폰트 색상이 luminance 50% 초과이면(밝으면) blackA75
// 지정된 폰트 색상이 luminance 50% 이하이면(어두우면) whiteA75

// 둘 다 지정된 경우
// 폰트: 지정된 색상
// 배경: 지정된 색상
export const getStoryGameCustomColor = <STATEMENT_TYPE extends string>({
  customFontColors,
  customBackgroundColor,
  statementType,
  theme,
}: {
  customFontColors?: (string | undefined)[]
  customBackgroundColor?: string
  statementType?: STATEMENT_TYPE
  theme: {
    colors: {
      white: string
      gray900: string
      gray700: string
      gray100: string
      black: string
    }
  }
}) => {
  const coloredFont = customFontColors?.filter(c => c) || []
  const defaultColorFonts = customFontColors?.filter(c => !c) || []

  const firstCustomFontColor = coloredFont[0]

  // 기본 색상(아무것도 지정 안됨)
  if (!firstCustomFontColor && !customBackgroundColor) return

  // 폰트 컬러가 두 개 이상 지정된 경우
  // 지문일 때 -> 회색 배경 (기본 인터랙티브 배경색) 적용 + 폰트색 지정되지 않은 글씨만 인터랙티브 모드 지문 폰트색
  if (customFontColors && coloredFont.length > 1) {
    // 내 발화일 때 -> 스토리게임 발화박스 기본 배경 그대로 (별다른 짓 X)
    if (statementType === 'MainCharacterTalk') {
      return {
        fontColors: customFontColors,
        backgroundColor: customBackgroundColor,
      }
    }

    // 상대방 발화일 때 -> 흰 배경 + 폰트색 지정되지 않은 글씨만 인터랙티브 모드 상대방 발화 폰트색
    if (statementType === 'CharacterTalk') {
      return {
        fontColors: customFontColors.map(color =>
          color ? color : theme.colors.gray900
        ),
        backgroundColor: customBackgroundColor
          ? customBackgroundColor
          : theme.colors.white,
      }
    }

    // 속마음일 때 -> 회색 배경 (기본 인터랙티브 배경색) + 폰트색 지정되지 않은 글씨만 인터랙티브 모드 속마음 폰트 색
    if (statementType?.endsWith('Think')) {
      return {
        fontColors: customFontColors.map(color =>
          color ? color : theme.colors.gray700
        ),
        backgroundColor: customBackgroundColor
          ? customBackgroundColor
          : theme.colors.gray100,
      }
    }
  }

  if (firstCustomFontColor && customBackgroundColor) {
    // 폰트색 지정한 것과 안한 것이 섞여있는 경우
    if (defaultColorFonts.length > 0) {
      const color = colorCodeToRgb(customBackgroundColor)
      const customColorLuminance = getLuminance(
        color?.r || 0,
        color?.g || 0,
        color?.b || 0
      )
      return {
        fontColors:
          customColorLuminance > 50
            ? customFontColors?.map(
                color => (color = color ?? theme.colors.black)
              )
            : customFontColors?.map(
                color => (color = color ?? theme.colors.white)
              ),
        backgroundColor: customBackgroundColor,
      }
    }
    // 둘 다 지정된 경우
    return {
      fontColors: customFontColors,
      backgroundColor: customBackgroundColor,
    }
  }

  // 폰트색만 지정된 경우
  if (firstCustomFontColor) {
    const color = colorCodeToRgb(firstCustomFontColor)
    const customColorLuminance = getLuminance(
      color?.r || 0,
      color?.g || 0,
      color?.b || 0
    )

    if (customColorLuminance > 50) {
      return {
        fontColors: customFontColors?.map(color => color ?? theme.colors.white),
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
      }
    } else {
      return {
        fontColors: customFontColors?.map(color => color ?? theme.colors.black),
        backgroundColor: 'rgba(255, 255, 255, 0.75)',
      }
    }
  }

  // 배경색만 지정된 경우
  if (customBackgroundColor) {
    const color = colorCodeToRgb(customBackgroundColor)
    const customColorLuminance = getLuminance(
      color?.r || 0,
      color?.g || 0,
      color?.b || 0
    )

    if (customColorLuminance > 50) {
      return {
        fontColors: new Array(customFontColors?.length).fill(
          theme.colors.black
        ),
        backgroundColor: customBackgroundColor,
      }
    } else {
      return {
        fontColors: new Array(customFontColors?.length).fill(
          theme.colors.white
        ),
        backgroundColor: customBackgroundColor,
      }
    }
  }
}
