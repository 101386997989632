import React from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import {
  LogoTwitterIcon,
  LogoInstagramIcon,
  LogoYoutubeIcon,
  LogoNaverBlogIcon,
  LogoTiktokIcon,
  LogoNaverIcon,
  LogoFacebookIcon,
} from 'public/assets';
import theme from '@styles/theme';
import {
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  NAVER_LINK,
  TWITTER_LINK,
  YOUTUBE_URL,
  NAVER_BLOG_LINK,
  TIKTOK_URL,
} from '@common/values';
import LanguageDropDown from './LanguageDropDown';
import { useRouter } from 'next/router';
import { useGetNoticeTermsQuery } from '@/operations/queries/user/__generated__/useGetNoticeTerms.generated';

const CommonFooter = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { data } = useGetNoticeTermsQuery({
    skip: router.pathname.includes('webview'),
  });

  return (
    <footer css={container}>
      <div className="icon">
        <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
          <LogoTwitterIcon width={24} height={24} />
        </a>
        <a href={INSTAGRAM_LINK} target="_blank" rel="noreferrer">
          <LogoInstagramIcon width={24} height={24} />
        </a>
        <a href={YOUTUBE_URL} target="_blank" rel="noreferrer">
          <LogoYoutubeIcon width={24} height={24} />
        </a>
        <a href={NAVER_BLOG_LINK} target="_blank" rel="noreferrer">
          <LogoNaverBlogIcon width={24} height={24} />
        </a>
        <a href={NAVER_LINK} target="_blank" rel="noreferrer">
          <LogoNaverIcon width={24} height={24} />
        </a>
        <a href={TIKTOK_URL} target="_blank" rel="noreferrer">
          <LogoTiktokIcon width={24} height={24} />
        </a>
        <a href={FACEBOOK_LINK} target="_blank" rel="noreferrer">
          <LogoFacebookIcon
            width={24}
            height={24}
            fill={theme.colors.gray500}
          />
        </a>
      </div>
      <address>
        <div className="info">
          {router.locale === 'ko' ? (
            <>
              {`${t('common_screen_description_thingsflow_inc')} | ${t(
                'common_screen_description_ceo_suji_lee',
              )} | ${t(
                'common_screen_description_business_registeration_number',
              )}`}
            </>
          ) : (
            <>
              {`${t('common_screen_description_thingsflow_inc')} | ${t(
                'common_screen_description_ceo_suji_lee',
              )}`}
            </>
          )}
        </div>
        <div className="info">
          {t('common_screen_description_company_address')}
        </div>
        <span className="info">
          {t('common_screen_title_contact')}{' '}
          {t('common_screen_description_representative_number')}
        </span>
      </address>
      <br />
      <div>
        <Link href="/notices">
          <a>
            <span className="notice">{t('more_screen_title_notice')}</span>
          </a>
        </Link>
        <a href={data?.getNoticeTerms.PP.link} target="_blank" rel="noreferrer">
          <span className="notice">
            {t('common_screen_title_privacy_policy')}
          </span>
        </a>
        <a
          href={data?.getNoticeTerms.TOS.link}
          target="_blank"
          rel="noreferrer"
        >
          <span className="notice">
            {t('common_screen_title_terms_of_service')}
          </span>
        </a>
      </div>
      <br />
      <div className="support">
        <Link href="mailto:help@storyplay.com">
          <a className="info">
            {t('common_screen_title_support')} help@storyplay.com
          </a>
        </Link>
        <span className="divider">|</span>
        <Link href="mailto:support@storyplay.com">
          <a className="info">
            {t('common_screen_title_partnership')} support@storyplay.com
          </a>
        </Link>
      </div>
      <span className="info">{t('common_screen_description_copyright')}</span>
      <LanguageDropDown />
    </footer>
  );
};

const container = css`
  display: flex;
  width: 100%;
  max-width: ${theme.maxWidth};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 95px 0;
  color: ${theme.colors.gray600};
  ${theme.font.body.body2};

  .icon {
    display: flex;
    gap: 18px;
    margin-top: 41px;
    margin-bottom: 18px;
  }

  &::before {
    content: '';
    height: 100%;
    display: inline-block;
    vertical-align: middle;
  }

  .info {
    margin-right: 0.25rem;
  }

  .notice {
    font-weight: 700;
    margin-right: 22px;
  }

  .support {
    display: flex;
    flex-direction: row;
    .divider {
      margin: 0 0.25rem;
    }

    @media screen and (max-width: ${theme.devices.mobile}) {
      display: flex;
      flex-direction: column;
      .divider {
        display: none;
      }
    }
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    padding: 0 1.3125rem 9.375rem 1.3125rem;
  }
`;

export default CommonFooter;
