import React, { ReactNode, memo } from 'react';
import Slider from 'react-slick';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { HomeBannerStoryType } from '@customTypes/story';

interface ICarouselTopBanner {
  list?: HomeBannerStoryType[];
  children: ReactNode;
}

const CarouselTopBanner = ({ children }: ICarouselTopBanner) => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    cssEase: 'ease-in-out',
    arrows: false,
  };

  return (
    <article css={container}>
      <Slider {...settings}>{children}</Slider>
    </article>
  );
};

const container = () => css`
  position: relative;
  width: 100%;
  overflow: hidden;

  // 슬라이드 리스트
  .slick-list {
    background: ${theme.colors.gray900};
  }

  // 슬라이드 페이지네이션
  .slick-dots {
    text-align: left;
    bottom: 0.625em;
    margin-left: 0.625em;

    li {
      margin: 0;
      width: 0.9em;
      height: 0.9em;
      line-height: 10px;

      button:before {
        color: ${theme.colors.gray600};
        opacity: 1;
      }
    }

    li.slick-active {
      width: 0.9em;
      height: 0.9em;

      button:before {
        color: ${theme.colors.white};
        opacity: 1;
      }
    }

    @media screen and (max-width: ${theme.devices.mobile}) {
      margin-bottom: 1.0625em;
    }
  }
`;

export default memo(CarouselTopBanner);
