import React, { ReactNode, Suspense } from 'react';
import { useIntersection } from '@hooks/useIntersection';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { css } from '@emotion/react';

interface ILayoutforLazyLoading {
  children: ReactNode;
}

const StoryCarouselSkeleton = () => {
  return (
    <>
      {new Array(2).fill(null).map((_, index) => {
        return (
          <div css={skeletonContainer} key={index}>
            <Skeleton
              height={'28px'}
              width={'200px'}
              containerClassName="avatar-skeleton"
            />
            <div className="skeleton-list-container">
              {new Array(6).fill(0).map((_, index) => {
                return (
                  <div className="skeleton-item" key={index}>
                    <Skeleton
                      height={'207px'}
                      width={'138px'}
                      containerClassName="avatar-skeleton"
                    />
                    <div className="skeleton-title-wrapper">
                      <Skeleton
                        height={'20px'}
                        width={'138px'}
                        containerClassName="avatar-skeleton"
                      />
                      <Skeleton
                        height={'16px'}
                        width={'138px'}
                        containerClassName="avatar-skeleton"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

const LayoutForLazyLoading = ({ children }: ILayoutforLazyLoading) => {
  const [isView, setRef] = useIntersection();

  return (
    <section ref={setRef}>
      {isView && (
        <Suspense fallback={<StoryCarouselSkeleton />}>{children}</Suspense>
      )}
    </section>
  );
};

const skeletonContainer = css`
  width: 100%;
  max-width: 800px;
  padding: 2.5rem 0.9375rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;

  .skeleton-list-container {
    display: flex;
    gap: 0.625rem;

    .skeleton-title-wrapper {
      margin: 0.6875em 0.125em;
    }

    .skeleton-item {
      display: flex;
      flex-direction: column;
      width: 8.5625rem;
      text-align: left;
      cursor: pointer;
    }
  }
`;

export default LayoutForLazyLoading;
