import React, { useEffect, useState } from 'react';
import Image, { ImageProps } from 'next/image';
import emptyImage from 'public/assets/img_empty.png';

const SPImage = ({ src, ...props }: ImageProps) => {
  const [imageSrc, setImageSrc] = useState<ImageProps['src']>(emptyImage);
  const [isUnOptimized, setIsUnOptimized] = useState<boolean>(false);

  useEffect(() => {
    setImageSrc(src || emptyImage);
  }, [src]);

  return (
    <Image
      {...props}
      src={imageSrc}
      unoptimized={isUnOptimized}
      onError={() => {
        if (isUnOptimized) {
          setImageSrc(emptyImage);
        } else {
          setIsUnOptimized(true);
        }
      }}
      blurDataURL={imageSrc.toString()}
      alt={props.alt ? props.alt : 'image'}
    />
  );
};

export default SPImage;
