import React, { useRef, useEffect, useState, MouseEventHandler } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import PrevArrowButtonSmall from '../arrows/PrevArrowButtonSmall';
import NextArrowButtonSmall from '../arrows/NextArrowButtonSmall';
import { AdultLabelIcon } from 'public/assets';

export type TabType = {
  showFilter?: boolean;
  type: string;
  text: {
    ko_KR: string;
    key?: string;
  };
};

interface ITab {
  data: TabType[];
  currentTab: string;
  onClickTabItem: (selectedTab: string, showFilter?: boolean) => void;
}

const Tabs = ({ data, currentTab, onClickTabItem }: ITab) => {
  const { t } = useTranslation();
  const tabListRef = useRef<HTMLUListElement>(null);
  const tabItemRef = useRef<HTMLLIElement>(null);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [isShowLastItem, setIsShowLastItem] = useState<boolean>(false);

  const listWidth = tabListRef.current?.scrollWidth ?? 0; // 스크롤할 전체 길이
  const listWidthHalf = (tabListRef.current?.clientWidth ?? 0) / 2; // 스크롤될 부모요소 가로값의 절반 (중앙정렬 기준)
  const targetPosition =
    (tabItemRef.current?.offsetLeft ?? 0) +
    (tabItemRef.current?.offsetWidth ?? 0) / 2;

  const handleTabHorizontalScroll = () => {
    if (targetPosition < listWidthHalf) {
      tabListRef.current?.scrollTo({
        behavior: 'smooth',
        left: 0,
      });
    } else if (listWidth - targetPosition < listWidthHalf) {
      tabListRef.current?.scrollTo({
        behavior: 'smooth',
        left: listWidth,
      });
    } else {
      tabListRef.current?.scrollTo({
        behavior: 'smooth',
        left: targetPosition - listWidthHalf,
      });
    }
  };

  useEffect(() => {
    handleTabHorizontalScroll();
  }, [listWidthHalf, targetPosition]);

  const handleNextButtonClick: MouseEventHandler<HTMLDivElement> = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollLeft += tabListRef.current.clientWidth;
    }
  };

  const handlePrevButtonClick: MouseEventHandler<HTMLDivElement> = () => {
    if (tabListRef.current) {
      tabListRef.current.scrollLeft -= tabListRef.current.clientWidth;
    }
  };

  useEffect(() => {
    if (!tabListRef.current) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            tabListRef.current &&
            entry.target instanceof HTMLElement
          ) {
            // 리스트가 한 아이템 이상 스크롤되면 prev arrow 아이콘을 보여줍니다
            tabListRef.current.scrollLeft > entry.target.clientWidth
              ? setIsScroll(true)
              : setIsScroll(false);

            // 리스트의 마지막 아이템이 화면에 보여지면 next arrow 아이콘을 숨깁니다
            tabListRef.current.lastChild === entry.target
              ? setIsShowLastItem(true)
              : setIsShowLastItem(false);
          }
        });
      },
      {
        threshold: 1,
      },
    );

    // 스토리 아이템 각각에 옵저버 부착
    tabListRef.current.childNodes.forEach((node) =>
      observer.observe(node as Element),
    );

    return () => {
      observer.disconnect();
    };
  }, [data]);

  return (
    <div css={wrapper}>
      <div className="arrow">
        <PrevArrowButtonSmall
          onButtonClick={handlePrevButtonClick}
          isShow={isScroll}
        />
        <NextArrowButtonSmall
          onButtonClick={handleNextButtonClick}
          isShow={!isShowLastItem}
        />
      </div>
      <ul className="list" ref={tabListRef}>
        {data?.map((item: TabType) => {
          return (
            <li
              ref={item.type === currentTab ? tabItemRef : null}
              key={item.type}
              css={tabItem({
                isActive: item.type === currentTab,
              })}
              onClick={() => onClickTabItem(item.type, item.showFilter)}
            >
              {item.type === 'ADULT' && (
                <AdultLabelIcon width={20} height={20} />
              )}
              {t(item.text.key ?? '')}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const wrapper = css`
  .arrow {
    position: absolute;
    width: 100%;
    margin-top: 1.4375rem;
    max-width: ${theme.maxWidth};
  }

  .list {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
    height: 44px;
    padding-left: 1.125rem;
    background: ${theme.colors.white};
    color: ${theme.colors.gray900};
    font-weight: 400;
    font-size: 0.875rem;
    border-bottom: 1px solid ${theme.colors.gray200};
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    z-index: 1;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const tabItem = ({ isActive }: { isActive: boolean }) => css`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${isActive ? theme.colors.gray900 : theme.colors.gray700};
  font: ${isActive ? theme.font.body1Bold : theme.font.body1Normal};
  cursor: pointer;

  &:first-of-type {
    width: 1.875rem;
    justify-content: center;
  }

  &:last-child {
    padding-right: 1.125rem;
  }
  /* 장르 스크롤 탭에서 margin으로 지정한 오른쪽 여백이 safari에서 보이지 않는 이슈 해결을 위해 pseudo 클래스를 사용하여 해결 */
  /* https://stackoverflow.com/questions/8572952/border-length-smaller-than-div-width */
  /* TODO: 더 명시적인 스타일 지정으로 해결하기 */
  &:last-child::after {
    content: '';
    width: 80%;
    height: 0.125rem;
    background: ${isActive ? theme.colors.gray900 : 'none'};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-55%);
  }

  &::after {
    content: '';
    width: 100%;
    height: 0.125rem;
    background: ${isActive ? theme.colors.gray900 : 'none'};
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export default Tabs;
