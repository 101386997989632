import React from 'react';
import { Story_Play_Type } from '@/baseType';
import StoryGameFooter from './StoryGameFooter';
import CommonFooter from './CommonFooter';

const SPFooter = ({ playType }: { playType?: Story_Play_Type }) => {
  if (playType === Story_Play_Type.Storygame) {
    return <StoryGameFooter />;
  }

  return <CommonFooter />;
};

export default SPFooter;
