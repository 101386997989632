import { PlatformType } from '@/customTypes/event';
import {
  AIRBRIDGE_QR_CODE_URL,
  ANDROID_STORE_LINK,
  IOS_STORE_LINK,
  OLD_STORY_DEEP_LINK,
  STORAGE_KEY,
} from '@common/values';

declare global {
  interface Window {
    airbridge: {
      setUserId: any;
      setUserEmail: any;
      addUserAlias: any;
      setDeeplinks: any;
      events: {
        send: any;
      };
    };
  }
}

export const setDeeplink = ({ storyId }: { storyId: number }) => {
  window.airbridge?.setDeeplinks({
    buttonID: 'web-to-app-deeplink',
    deeplinks: {
      ios: `${OLD_STORY_DEEP_LINK}/${storyId}`,
      android: `${OLD_STORY_DEEP_LINK}/${storyId}`,
      desktop: `${AIRBRIDGE_QR_CODE_URL}`,
    },
    fallbacks: {
      ios: IOS_STORE_LINK,
      android: ANDROID_STORE_LINK,
    },
    desktopPopUp: false,
  });
};

const setUserProperties = () => {
  const properties = localStorage.getItem(STORAGE_KEY.USER_PROPERTIES);
  const userProperties = properties && JSON.parse(properties);

  if (!userProperties) return;

  window.airbridge?.setUserId(String(userProperties?.user_id ?? ''));
  window.airbridge?.setUserEmail(String(userProperties?.email ?? ''));
  window.airbridge?.addUserAlias('is_adult', userProperties?.isAdult);
};

export const startStoryAll = (previous_screen: string) => {
  setUserProperties();
  window.airbridge.events.send('start_story_all', {
    customAttributes: { previous_screen: previous_screen },
  });
};

export const startChapter = ({
  story,
  chapter,
  start,
  storyId,
  storyType,
  adImpression = '정보없음',
}: {
  story: string;
  chapter: number;
  start: 'start' | 'continue' | 'time_leap' | '정보없음';
  storyId: number;
  storyType: 'home' | 'challenge' | '정보없음';
  adImpression?: boolean | '정보없음';
}) => {
  setUserProperties();
  window.airbridge.events.send('start_chapter', {
    customAttributes: {
      story,
      chapter: chapter.toString(),
      start: start,
      story_id: storyId,
      story_type: storyType,
      ad_impression: adImpression,
    },
  });
};

export const startChapterInteractive = ({
  story,
  chapter,
  start,
  storyId,
  storyType,
  adImpression = '정보없음',
}: {
  story: string;
  chapter: number;
  start: 'start' | 'continue' | 'time_leap' | '정보없음';
  storyId: number;
  storyType: 'home' | 'challenge' | '정보없음';
  adImpression?: boolean | '정보없음';
}) => {
  setUserProperties();
  window.airbridge.events.send('start_chapter_interactive', {
    customAttributes: {
      story,
      chapter: chapter.toString(),
      start: start,
      story_id: storyId,
      story_type: storyType,
      ad_impression: adImpression,
    },
  });
};

export const startChapterNovel = ({
  story,
  chapter,
  start,
  storyId,
  storyType,
  adImpression = '정보없음',
}: {
  story: string;
  chapter: number;
  start: 'start' | 'continue' | 'time_leap' | '정보없음';
  storyId: number;
  storyType: 'home' | 'challenge' | '정보없음';
  adImpression?: boolean | '정보없음';
}) => {
  setUserProperties();
  window.airbridge.events.send('start_chapter_novel', {
    customAttributes: {
      story,
      chapter: chapter.toString(),
      start: start,
      story_id: storyId,
      story_type: storyType,
      ad_impression: adImpression,
    },
  });
};

export const startChapterFirst = (name: string) => {
  setUserProperties();
  window.airbridge.events.send('start_chapter_first', {
    customAttributes: { story: name },
  });
};

export const startChapterInteractiveFirst = (name: string) => {
  setUserProperties();
  window.airbridge.events.send('start_chapter_interactive_first', {
    customAttributes: { story: name },
  });
};

export const startChapterNovelFirst = (name: string) => {
  setUserProperties();
  window.airbridge.events.send('start_chapter_novel_first', {
    customAttributes: { story: name },
  });
};

export const endStoryAll = () => {
  setUserProperties();
  window.airbridge.events.send('end_story_all');
};

export const endChapter = ({
  storyName,
  chapterIndex,
  endingId,
  endingTitle,
  storyId,
  storyType,
}: {
  storyName: string;
  chapterIndex: number;
  endingId?: number | '정보없음';
  endingTitle?: string;
  storyId: number;
  storyType: 'home' | 'challenge' | '정보없음';
}) => {
  setUserProperties();
  window.airbridge.events.send('end_chapter', {
    customAttributes: {
      story: storyName,
      chapter: chapterIndex.toString(),
      ending_id: endingId?.toString(),
      ending_title: endingTitle,
      story_id: storyId,
      story_type: storyType,
    },
  });
};

export const endChapterInteractive = ({
  storyName,
  chapterIndex,
  endingId,
  endingTitle,
  storyId,
  storyType,
}: {
  storyName: string;
  chapterIndex: number;
  endingId?: number | '정보없음';
  endingTitle?: string;
  storyId: number;
  storyType: 'home' | 'challenge' | '정보없음';
}) => {
  setUserProperties();
  window.airbridge.events.send('end_chapter_interactive', {
    customAttributes: {
      story: storyName,
      chapter: chapterIndex.toString(),
      ending_id: endingId?.toString(),
      ending_title: endingTitle,
      story_id: storyId,
      story_type: storyType,
    },
  });
};

export const endChapterNovel = ({
  storyName,
  chapterIndex,
  endingId,
  endingTitle,
  storyId,
  storyType,
}: {
  storyName: string;
  chapterIndex: number;
  endingId?: number | '정보없음';
  endingTitle?: string;
  storyId: number;
  storyType: 'home' | 'challenge' | '정보없음';
}) => {
  setUserProperties();
  window.airbridge.events.send('end_chapter_novel', {
    customAttributes: {
      story: storyName,
      chapter: chapterIndex.toString(),
      ending_id: endingId?.toString(),
      ending_title: endingTitle,
      story_id: storyId,
      story_type: storyType,
    },
  });
};

export const endChapterFirst = (name: string) => {
  setUserProperties();
  window.airbridge.events.send('end_chapter_first', {
    customAttributes: { story: name },
  });
};

export const endChapterInteracitveFirst = (name: string) => {
  setUserProperties();
  window.airbridge.events.send('end_chapter_interactive_first', {
    customAttributes: { story: name },
  });
};

export const endChapterNovelFirst = (name: string) => {
  setUserProperties();
  window.airbridge.events.send('end_chapter_novel_first', {
    customAttributes: { story: name },
  });
};

export const purchaseTicket = ({
  userCoin,
  coin,
  price,
  method,
  name,
  id,
}: {
  userCoin: string;
  coin: string;
  price: string;
  method: '신용카드' | '카카오페이' | '토스' | '휴대폰결제' | '정보없음';
  name?: string;
  id?: string;
}) => {
  setUserProperties();
  window.airbridge.events.send('purchase_ticket', {
    customAttributes: {
      user_coin: userCoin,
      coin,
      price,
      method,
    },
  });
  window.airbridge.events.send('airbridge.ecommerce.order.completed', {
    semanticAttributes: {
      products: [
        {
          productID: id,
          name: name,
          price: price,
          currency: 'KRW',
          quantity: 1,
          position: 1,
        },
      ],
      inAppPurchased: false,
      totalValue: price,
      currency: 'KRW',
    },
  });
};

export const viewInAppPurchaseTicket = ({
  neededCoin,
  userCoin,
}: {
  neededCoin: string;
  userCoin: string;
}) => {
  setUserProperties();
  window.airbridge?.events.send('view_in_app_purchase_ticket', {
    customAttributes: {
      user_coin: userCoin,
      needed_coin: neededCoin,
    },
  });
};

export const payTicketAll = ({
  ticketCount,
  storyNameAndChapterIndex,
  storyId,
}: {
  ticketCount: string;
  storyNameAndChapterIndex: string;
  storyId: string;
}) => {
  setUserProperties();
  window.airbridge.events.send('view_in_app_purchase_ticket', {
    customAttributes: {
      ticket: ticketCount,
      story: storyNameAndChapterIndex,
      story_id: storyId,
    },
  });
};

export const adStart = ({
  storyName,
  chapterId,
  chapterIndex,
  storyId,
}: {
  storyName: string;
  chapterId: string;
  chapterIndex: string;
  storyId: string;
}) => {
  setUserProperties();
  window.airbridge.events.send('ad_start', {
    customAttributes: {
      story: storyName,
      chapter: chapterId,
      index: chapterIndex,
      story_id: storyId,
    },
  });
};

export const adFinish = ({
  storyName,
  chapterId,
  chapterIndex,
  storyId,
}: {
  storyName: string;
  chapterId: string;
  chapterIndex: string;
  storyId: string;
}) => {
  setUserProperties();
  window.airbridge.events.send('ad_finish', {
    customAttributes: {
      story: storyName,
      chapter: chapterId,
      index: chapterIndex,
      story_id: storyId,
    },
  });
};

export const viewPurchaseStory = ({
  storyName,
  storyId,
  fullPrice,
  price,
  lastPreviewChapterIndex,
  from,
}: {
  storyName: string;
  storyId: string;
  fullPrice: string; // 정가
  price: string; // 할인가
  lastPreviewChapterIndex: string;
  from: 'chat' | 'detail' | 'novel' | '정보없음';
}) => {
  setUserProperties();
  window.airbridge.events.send('view_purchase_story', {
    customAttributes: {
      story: storyName,
      story_id: storyId,
      full_price: fullPrice,
      price: price,
      lastPreviewChapterIndex,
      from: from,
      is_coin: true,
    },
  });
};

export const ageVerificationStart = ({
  platform,
}: {
  platform: PlatformType;
}) => {
  setUserProperties();

  window.airbridge.events.send('age_verification_start', {
    customAttributes: {
      platform,
    },
  });
};

export const ageVerificationComplete = ({
  platform,
}: {
  platform: PlatformType;
}) => {
  setUserProperties();

  window.airbridge.events.send('age_verification_complete', {
    customAttributes: {
      platform,
    },
  });
};

export const viewAgeVerification = ({
  platform,
}: {
  platform: PlatformType;
}) => {
  setUserProperties();

  window.airbridge.events.send('view_age_verification', {
    customAttributes: {
      platform,
    },
  });
};
