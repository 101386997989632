import React from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';

interface IInput {
  width?: string;
  value: string;
  placeholder?: string;
  isFixMainChrName?: boolean;
  onChangeText: (text: string) => void;
}

const OnboardingInput = ({
  width,
  value,
  placeholder,
  isFixMainChrName,
  onChangeText,
}: IInput) => {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChangeText(value);
  };

  return (
    <>
      {isFixMainChrName ? (
        <div css={fixName}>{value}</div>
      ) : (
        <div css={container(width || '100%')}>
          <input
            css={input}
            placeholder={placeholder || ''}
            value={value}
            onChange={onChange}
          />
        </div>
      )}
    </>
  );
};

const container = (width: string) => css`
  width: ${width};
  height: 2.75em;
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--blackA10, rgba(0, 0, 0, 0.10));
  margin: 0 0.75em;

  :focus-within {
    border-bottom: 1px solid ${theme.colors.primary500};
  }

  @media (max-width: ${theme.devices.mobile}) {
    border-bottom: 1px solid var(--blackA10, rgba(0, 0, 0, 0.10));
    :focus-within {
      border-bottom: 1px solid ${theme.colors.primary500};
    }
  }
`;

const input = css`
  width: 100%;
  border: none;
  text-align: center;
  ${theme.fontV3.body.body03Reg};
  color: ${theme.colors.gray900};

  ::placeholder {
    color: var(--blackA25, rgba(0, 0, 0, 0.25));
  }
`;

const fixName = css`
  margin-top: 0.5em;
  font-size: 1.25em;
  font-weight: 700;
  text-align: center;
  color: ${theme.colors.gray900};
`;

export default OnboardingInput;
