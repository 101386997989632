declare global {
  interface Window {
    Hubble: any;
  }
}

export const setHubbleUserProperties = (properties: {
  user_id: number;
  isAnonymous: boolean;
}) => {
  if (
    properties &&
    window.Hubble &&
    window.Hubble.ready &&
    window.Hubble.initialized
  ) {
    window.Hubble.identify(`${properties.user_id}`, {
      signUp: !properties.isAnonymous,
    });
  }
};

export const sendHubbleTrackingEvent = (eventName: string) => {
  if (window.Hubble && window.Hubble.ready && window.Hubble.initialized) {
    window.Hubble.track(eventName);
  }
};
