import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IADState {
  chapterId?: number;
}

const initialState: IADState = { chapterId: undefined };

const adSlices = createSlice({
  name: 'ad',
  initialState,
  reducers: {
    setADChapterId(state, action: PayloadAction<IADState>) {
      state.chapterId = action.payload.chapterId;
    },
  },
});

export const { setADChapterId } = adSlices.actions;
export default adSlices;
