import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STORAGE_KEY } from '@common/values';

interface UserState {
  featureVersion: number;
}

interface setUserPayload {
  featureVersion: string | null;
}

const initialFeatureVersion =
  typeof window !== 'undefined' &&
  Number(localStorage.getItem(STORAGE_KEY.OPEN_APP_FEATURE_VERSION));

const initialState: UserState = {
  featureVersion: initialFeatureVersion || 0,
};

const userSlices = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<setUserPayload>) {
      // 0이면 이전 버전, 1이면 1.6에 도입된 새로운 기능 보여줌
      state.featureVersion = Number(action.payload.featureVersion);
    },
  },
});

export const { setUser } = userSlices.actions;
export default userSlices;
