/* eslint-disable no-unused-vars */
export enum HOME_TAB_TYPE {
  Home = 'HOME',
  WeekDay = 'WEEK_DAY',
  Adult = 'ADULT',
}

// 홈 | 인터랙티브 | 웹소설 각각 섹션 타이틀이 다른 섹션들에 대한 텍스트 정의
export const HOME_SECTION_TITLES = {
  Home: {
    Monthly: {
      Title: 'home_screen_title_monthly_story',
      SubTitle: 'home_screen_description_monthly_story',
    },
    New: {
      Title: 'home_screen_title_new_story',
      SubTitle: 'home_screen_description_new_story',
    },
    Finished: {
      Title: 'home_screen_title_finished_story',
      SubTitle: 'home_screen_description_finished_story',
    },
  },
  Interactive: {
    Monthly: {
      Title: 'home_screen_title_monthly_story',
      SubTitle: 'interactive_screen_description_monthly_story',
    },
    New: {
      Title: 'interactive_screen_title_interactive_new_story',
      SubTitle: 'home_screen_description_new_story',
    },
    Finished: {
      Title: 'home_screen_title_finished_story',
      SubTitle: 'home_screen_description_finished_story',
    },
  },
  WebNovel: {
    Monthly: {
      Title: 'home_screen_title_monthly_story',
      SubTitle: 'home_screen_description_monthly_story',
    },
    New: {
      Title: 'webnovel_screen_title_new_novel',
      SubTitle: 'home_screen_description_new_story',
    },
    Finished: {
      Title: 'home_screen_title_finished_story',
      SubTitle: 'webnovel_screen_description_finished_novel',
    },
  },
};
