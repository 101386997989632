import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChapterCommentOrderBy } from '@/baseType';
import { SORT_BY } from '@common/values';
import { CommentItemFragment } from '@operations/queries/comment/__generated__/useListChapterComment.generated';

interface readyToEditPayload {
  id: number;
  content: string;
  isSpoiler: boolean;
}

interface IReCommentState {
  editingCommentInfo: {
    id: number | null;
    content: string;
    isSpoiler: boolean;
  };
  orderBy: ChapterCommentOrderBy | ChapterCommentOrderBy[];
  isCommentable: boolean;
  parentData?: CommentItemFragment;
  isUserAdmin: boolean;
}

const initialState: IReCommentState = {
  editingCommentInfo: {
    id: null,
    content: '',
    isSpoiler: false,
  },
  orderBy: SORT_BY.best,
  isCommentable: false,
  isUserAdmin: false,
};

const reCommentSlices = createSlice({
  name: 'reComment',
  initialState,
  reducers: {
    readyToEdit(state, action: PayloadAction<readyToEditPayload>) {
      const { id, content, isSpoiler } = action.payload;
      state.editingCommentInfo.id = id;
      state.editingCommentInfo.content = content;
      state.editingCommentInfo.isSpoiler = isSpoiler;
    },
    clearEditCommentInfo(state) {
      state.editingCommentInfo.id = null;
      state.editingCommentInfo.content = '';
      state.editingCommentInfo.isSpoiler = false;
    },
    setOrderBy(
      state,
      action: PayloadAction<ChapterCommentOrderBy | ChapterCommentOrderBy[]>,
    ) {
      state.orderBy = action.payload;
    },
    setIsCommentable(state, action: PayloadAction<boolean>) {
      state.isCommentable = action.payload;
    },
    setParentData(state, action: PayloadAction<CommentItemFragment>) {
      state.parentData = action.payload;
    },
    setIsUserAdmin(state, action: PayloadAction<boolean>) {
      state.isUserAdmin = action.payload;
    },
  },
});

export const {
  readyToEdit,
  clearEditCommentInfo,
  setOrderBy,
  setIsCommentable,
  setParentData,
  setIsUserAdmin,
} = reCommentSlices.actions;

export default reCommentSlices;
