import {
  ApolloClient,
  from,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  fromPromise,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { SERVER_ERROR_MESSAGE, STORAGE_KEY } from '@common/values';
import { REGISTER_ANONYMOUS_USER } from '@api/user';
import { SERVER_ERROR_CODE } from '@common/values';
import { setUserToken } from '@common/utils';
import { isDevelopment } from '@common/utils';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

const apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const httpLink: HttpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_SERVER_URI,
});

const authLink: ApolloLink = setContext((_, { headers }) => {
  if (typeof window === 'undefined') {
    return headers;
  }

  const appInfo =
    sessionStorage.getItem(STORAGE_KEY.APP_INFO_FOR_WEBVIEW) ?? '';
  const { token, platform } = JSON.parse(appInfo);

  return {
    headers: {
      ...headers,
      token: token || '',
      platform: platform ?? 'Web',
    },
  };
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (isDevelopment()) {
    console.log('webviewError: ', graphQLErrors, 'operation:', operation);
  }

  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (
        (err.message.includes(SERVER_ERROR_CODE.UNAUTHORIZED) &&
          err.message.includes(SERVER_ERROR_MESSAGE.AUTH_TOKEN)) ||
        err.message.includes(SERVER_ERROR_MESSAGE.USER_LEFT)
      ) {
        return fromPromise(registerAnonymousUser())
          .filter((value) => Boolean(value))
          .flatMap((token) => {
            setUserToken({ token: token ?? '' });
            const oldHeaders = operation.getContext().headers;
            operation.setContext({
              headers: {
                ...oldHeaders,
                token: token || '',
                platform: 'Web',
              },
            });

            return forward(operation);
          });
      }
    }
  }
});

export const registerAnonymousUser = async () => {
  if (!apolloClient) return;

  const result = await (
    apolloClient as ApolloClient<NormalizedCacheObject>
  ).mutate({
    mutation: REGISTER_ANONYMOUS_USER,
  });

  return result?.data?.registerAnonymousUser.token;
};

export const createApolloClient = () => {
  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });
};
