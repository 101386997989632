import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { touchChallengeContentTypeFilter } from '@/lib/eventManager';
import {
  touchHomeTab,
  touchNovelTab,
  touchChallengeTab,
  touchMoreTab,
} from '@/lib/googleAnalytics';
import { STORAGE_KEY } from '@/common/values';
import { UserInfo } from '@/customTypes/user';
import { useGetUserQuery } from '@/operations/queries/user/__generated__/getUser.generated';
import { setUserProperties } from '@/common/utils';
import { HomeTab } from '@/customTypes/event';

const BOTTOM_TABS: {
  [key: string]: TabInfo;
} = {
  challenge: {
    tab: 'challenge',
    url: '/challenge?genre=TOTAL&sorting=RECOMMENDED&showFilter=true',
    iconOutlinePathDark: '/assets/icon_challenge_outline_dark.svg',
    iconFillPathDark: '/assets/icon_challenge_fill_dark.svg',
    iconOutlinePath: '/assets/icon_challenge_outline.svg',
    iconFillPath: '/assets/icon_challenge_fill.svg',
    title: 'common_screen_title_challenge_story',
  },
  more: {
    tab: 'more',
    url: '/more',
    iconOutlinePathDark: '/assets/icon_profile_outline_dark.svg',
    iconFillPathDark: '/assets/icon_profile_fill_dark.svg',
    iconOutlinePath: '/assets/icon_profile_outline.svg',
    iconFillPath: '/assets/icon_profile_fill.svg',
    title: 'more_screen_title_more',
  },
  store: {
    tab: 'store',
    url: '/store',
    iconOutlinePathDark: '/assets/icon_store_outline_dark.svg',
    iconFillPathDark: '/assets/icon_store_fill_dark.svg',
    iconOutlinePath: '/assets/icon_store_outline.svg',
    iconFillPath: '/assets/icon_store_fill.svg',
    title: 'common_screen_title_store',
  },
  interactive: {
    tab: 'interactive',
    url: '/interactive',
    iconOutlinePathDark: '/assets/icon_interactive_outline_dark.svg',
    iconFillPathDark: '/assets/icon_interactive_fill_dark.svg',
    iconOutlinePath: '/assets/icon_interactive_outline.svg',
    iconFillPath: '/assets/icon_interactive_fill.svg',
    title: 'common_screen_title_interactive',
  },
  webnovel: {
    tab: 'novel',
    url: '/webnovel',
    iconOutlinePathDark: '/assets/icon_novel_outline_dark.svg',
    iconFillPathDark: '/assets/icon_novel_fill_dark.svg',
    iconOutlinePath: '/assets/icon_novel_outline.svg',
    iconFillPath: '/assets/icon_novel_fill.svg',
    title: 'common_screen_title_webnovel',
  },
  storygame: {
    tab: 'storygame',
    url: '/home/storygame',
    iconOutlinePathDark: '/assets/icon_story_game_outline_dark.svg',
    iconFillPathDark: '/assets/icon_story_game_fill_dark.svg',
    iconOutlinePath: '/assets/icon_story_game_outline.svg',
    iconFillPath: '/assets/icon_story_game_fill.svg',
    title: 'storygame_screen_title',
  },
};

interface TabInfo {
  tab: HomeTab; // GA 이벤트 전송을 위한 탭 정보
  url: string;
  iconOutlinePathDark: string;
  iconFillPathDark: string;
  iconOutlinePath: string;
  iconFillPath: string;
  title: string;
  isActive?: boolean;
  isDarkTheme?: boolean;
}

type TabType = keyof typeof BOTTOM_TABS;

interface INavBar {
  isDarkTheme?: boolean;
}

const Navbar = ({ isDarkTheme = false }: INavBar) => {
  const router = useRouter();
  const [currentTab, setCurrentTab] = useState<TabType>('');
  const [userInfo, setUserInfo] = useState<UserInfo>();
  useGetUserQuery({
    onCompleted: (data) => {
      data.getUser &&
        setUserProperties({
          registeredAt: data.getUser?.registeredAt,
          isTester: data.getUser.isTester,
          userId: data.getUser.userId,
          email: data.getUser.email,
          language: data.getUser.systemLanguage?.code ?? undefined,
          countryCode: data.getUser.countryCode,
          isAdult:
            data.getUser.certificatedAt && !data.getUser.isExpiredCertification,
          isAnonymous: data.getUser.isAnonymous,
        });
    },
  });

  useEffect(() => {
    if (router.pathname.includes('/challenge')) {
      setCurrentTab('challenge');
      touchChallengeContentTypeFilter();
    } else if (
      router.pathname.includes('/more') &&
      !router.pathname.includes('/more/storygame')
    ) {
      setCurrentTab('more');
    } else if (router.pathname.includes('/store')) {
      setCurrentTab('store');
    } else if (
      router.pathname.includes('/interactive') ||
      router.pathname === '/'
    ) {
      setCurrentTab('interactive');
    } else if (router.pathname.includes('/webnovel')) {
      setCurrentTab('webnovel');
    } else if (router.pathname.includes('/home/storygame')) {
      setCurrentTab('storygame');
    }
  }, [router.pathname]);

  useEffect(() => {
    const storageInfo = localStorage.getItem(STORAGE_KEY.USER_PROPERTIES);
    const parsedInfo = storageInfo && JSON.parse(storageInfo);

    setUserInfo(parsedInfo);
  }, [router.locale, router.pathname]);

  return (
    <>
      <div css={background({ isDarkTheme })} />
      <nav css={tabList({ isDarkTheme })}>
        {!userInfo && (
          <>
            <TabItem
              {...BOTTOM_TABS.interactive}
              isActive={currentTab === 'interactive'}
              isDarkTheme={isDarkTheme}
            />
            <TabItem
              {...BOTTOM_TABS.storygame}
              isActive={currentTab === 'storygame'}
              isDarkTheme={isDarkTheme}
            />
            <TabItem
              {...BOTTOM_TABS.webnovel}
              isActive={currentTab === 'webnovel'}
              isDarkTheme={isDarkTheme}
            />
            <TabItem
              {...BOTTOM_TABS.challenge}
              isActive={currentTab === 'challenge'}
              isDarkTheme={isDarkTheme}
            />
          </>
        )}
        {userInfo &&
          userInfo?.countryCode === 'KR' &&
          userInfo.language === 'ko_KR' && (
            // 한국 > 한국어
            <>
              <TabItem
                {...BOTTOM_TABS.interactive}
                isActive={currentTab === 'interactive'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.storygame}
                isActive={currentTab === 'storygame'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.webnovel}
                isActive={currentTab === 'webnovel'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.challenge}
                isActive={currentTab === 'challenge'}
                isDarkTheme={isDarkTheme}
              />
            </>
          )}
        {userInfo &&
          userInfo?.countryCode === 'KR' &&
          userInfo.language !== 'ko_KR' && (
            // 한국 > 영어
            <>
              <TabItem
                {...BOTTOM_TABS.interactive}
                isActive={currentTab === 'interactive'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.storygame}
                isActive={currentTab === 'storygame'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.challenge}
                isActive={currentTab === 'challenge'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.store}
                isActive={currentTab === 'store'}
                isDarkTheme={isDarkTheme}
              />
            </>
          )}
        {userInfo &&
          userInfo?.countryCode !== 'KR' &&
          userInfo?.language === 'ko_KR' && (
            // 그외 > 한국어
            <>
              <TabItem
                {...BOTTOM_TABS.interactive}
                isActive={currentTab === 'interactive'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.storygame}
                isActive={currentTab === 'storygame'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.webnovel}
                isActive={currentTab === 'webnovel'}
                isDarkTheme={isDarkTheme}
              />
            </>
          )}
        {userInfo &&
          userInfo?.countryCode !== 'KR' &&
          userInfo?.language !== 'ko_KR' && (
            // 그외 > 한국어 아님
            <>
              <TabItem
                {...BOTTOM_TABS.interactive}
                isActive={currentTab === 'interactive'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.storygame}
                isActive={currentTab === 'storygame'}
                isDarkTheme={isDarkTheme}
              />
              <TabItem
                {...BOTTOM_TABS.store}
                isActive={currentTab === 'store'}
                isDarkTheme={isDarkTheme}
              />
            </>
          )}
        <TabItem
          {...BOTTOM_TABS.more}
          isActive={currentTab === 'more'}
          isDarkTheme={isDarkTheme}
        />
      </nav>
    </>
  );
};

const TabItem = ({
  tab,
  url,
  iconOutlinePathDark,
  iconFillPathDark,
  iconOutlinePath,
  iconFillPath,
  title,
  isActive,
  isDarkTheme,
}: TabInfo) => {
  const { t } = useTranslation();
  return (
    <Link href={url}>
      <a
        className="tab"
        css={tabItem({
          iconOutlinePathDark,
          iconFillPathDark,
          iconOutlinePath,
          iconFillPath,
          isActive,
          isDarkTheme,
        })}
        onClick={() => {
          if (tab === 'challenge') {
            touchChallengeTab();
          } else if (tab === 'more') {
            touchMoreTab();
          } else if (tab === 'interactive') {
            touchHomeTab({ tab });
          } else if (tab === 'novel') {
            touchNovelTab();
          }
          touchHomeTab({ tab });
        }}
      >
        <i className="tab-icon" />
        <p className="tab-title">{t(title)}</p>
      </a>
    </Link>
  );
};

const tabItem = ({
  iconOutlinePathDark,
  iconFillPathDark,
  iconOutlinePath,
  iconFillPath,
  isDarkTheme,
  isActive,
}: {
  iconOutlinePathDark: string;
  iconFillPathDark: string;
  iconOutlinePath: string;
  iconFillPath: string;
  isDarkTheme?: boolean;
  isActive?: boolean;
}) => css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .tab-icon {
    width: 1.5rem;
    height: 1.5rem;
    background: 50% 50% no-repeat;
    ${isActive
      ? isDarkTheme
        ? `background-image: url(${iconFillPathDark})`
        : `background-image: url(${iconFillPath})`
      : isDarkTheme
      ? `background-image: url(${iconOutlinePathDark})`
      : `background-image: url(${iconOutlinePath})`}
  }

  .tab-title {
    margin-top: 0.375rem;
    font-size: 0.625rem;
    font-style: normal;

    ${isActive
      ? `
        font-weight: 700;
        color: ${isDarkTheme ? theme.colors.white : theme.colors.gray900};
        `
      : `
        font-weight: 400;
        color: ${
          isDarkTheme ? 'rgba(255, 255, 255, 0.5)' : theme.colors.gray900
        };
      `}
  }
`;

const background = ({ isDarkTheme }: { isDarkTheme: boolean }) => css`
  position: fixed;
  width: 100%;
  bottom: 0;
  height: calc(54px + env(safe-area-inset-bottom));
  ${!isDarkTheme && `border-top: 1px solid ${theme.colors.gray200};`}
  background: ${isDarkTheme ? 'black' : theme.colors.white};
  z-index: 10;
  visibility: hidden;

  ${theme.setMobileStyle(css`
    visibility: visible;
  `)}
`;

const tabList = ({ isDarkTheme }: { isDarkTheme: boolean }) => css`
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  width: 100%;
  max-width: ${isDarkTheme ? theme.maxWidthV2 : theme.maxWidth};
  height: 54px;
  display: flex;
  justify-content: space-around;
  ${!isDarkTheme && `border-top: 1px solid ${theme.colors.gray200};`}
  background: ${isDarkTheme ? 'black' : theme.colors.white};
  z-index: 10;
  visibility: hidden;

  ${theme.setTabletStyle(css`
    visibility: visible;
  `)}
`;

export default Navbar;
