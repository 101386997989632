import React from 'react';
import { css } from '@emotion/react';
import theme, { ColorType } from '@styles/theme';
import { NavCloseBtn } from 'public/assets';

interface ICloseHeader {
  onClick: () => void;
  title?: string;
  position?: 'left' | 'right';
  color?: ColorType;
  hasBgColor?: boolean;
}

const CloseHeader = ({
  onClick,
  title,
  position = 'left',
  color = 'gray800',
  hasBgColor = false,
}: ICloseHeader) => {
  return (
    <header css={header({ iconPosition: position, hasBgColor })}>
      {position === 'left' ? (
        <NavCloseBtn
          width={24}
          height={24}
          className="icon"
          fill={theme.colors[color]}
          onClick={onClick}
        />
      ) : (
        <span className="icon"></span>
      )}
      {title && <h1 className="title">{title}</h1>}
      {position === 'right' ? (
        <NavCloseBtn
          width={24}
          height={24}
          className="icon"
          fill={theme.colors[color]}
          onClick={onClick}
        />
      ) : (
        <span className="icon"></span>
      )}
    </header>
  );
};

const header = ({
  iconPosition,
  hasBgColor,
}: {
  iconPosition: 'left' | 'right';
  hasBgColor: boolean;
}) => css`
  position: fixed;
  top: 0;
  display: flex;
  height: 4rem;
  width: 100%;
  max-width: 50rem;
  justify-content: space-between;
  align-items: center;
  padding: 1.375em 1.25em;
  z-index: 10;

  ${hasBgColor && `background: ${theme.colors.white};`}

  .title {
    font: ${theme.font.header.navigation};
  }

  .icon {
    position: relative;
    ${iconPosition === 'left' ? 'left: 0' : 'right: 0'};
    width: 24px;
    cursor: pointer;
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    width: 100%;
  }
`;

export default CloseHeader;
