import { useRef, useEffect, useState } from 'react';

export const useIntersection = () => {
  const [isView, setIsView] = useState(false);
  const elemRef = useRef<null | Element | undefined>(null);

  const setRef = (elem: null | Element | undefined) => {
    elemRef.current = elem;
  };

  useEffect(() => {
    if (!elemRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(
        (entry) => {
          if (entry.isIntersecting) {
            setIsView(true);
          }
        },
        {
          rootMargin: '100px',
          threshold: 1,
        },
      );
    });

    observer.observe(elemRef.current);
    return () => observer.disconnect();
  }, []);

  return [isView, setRef] as const;
};
