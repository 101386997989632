declare global {
  interface Window {
    calculate: any;
    setSeed: any;
  }
}

export const calculate = (operationData: string, playData: string): string => {
  return window.calculate(operationData, playData);
};

export const setSeed = (upcId: string): string => {
  return window.setSeed(upcId);
};
