import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { ChatPlayableType } from '@/customTypes/chatStory';
import SPError from '@components/commons/error/SPError';

interface IChatError {
  chatPlayableType: ChatPlayableType;
  storyId?: number;
}

// TODO-GLOBAL: t() 사용하도록 변경
// t('common_screen_description_unexpected_error_occured')
// t('storydetail_popup_description_already_played')
// t('common_screen_description_unexpected_error_occured')
// t('storydetail_popup_description_play_after_watch_ad')
// t('storydetail_popup_description_play_after_buy_story')
// t('common_screen_description_unexpected_error_occured')
const ERROR_MESSAGE = {
  info: 'common_screen_description_unexpected_error_occured', // 채팅정보 오류 // access_error
  end: 'storydetail_popup_description_already_played', // 플레이 완료 회차 // story:already_played'
  index: 'common_screen_description_unexpected_error_occured', // 회차 순서 오류 // access_error
  ad: 'storydetail_popup_description_play_after_watch_ad', // 광고 시청 필요 // 광고시청 후에 플레이할 수 있어요.
  payment: 'storydetail_popup_description_play_after_buy_story', // 구매 필요 // 작품구매 후에 플레이할 수 있어요.
  play: 'common_screen_description_unexpected_error_occured',
};

const ChatError = ({ chatPlayableType, storyId }: IChatError) => {
  const { t } = useTranslation();
  const router = useRouter();

  const onBack = () => {
    router.push(storyId ? `/story/${storyId}` : '/');
  };

  return (
    <SPError
      message={t(ERROR_MESSAGE[chatPlayableType])}
      backBtnName={
        storyId ? t('common_popup_button_return_to_details') : undefined
      }
      onBack={onBack}
    />
  );
};

export default ChatError;
