import { pxToRem } from '@lib/common-react/utils/pixelToRem';
import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import Trans from 'next-translate/Trans';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { ArrowRight24Icon, NextBtn, StoryPlayLogo2Icon } from 'public/assets';
import { DEEP_LINK } from '@common/values';
import { openDeepLink } from '@common/utils';

interface IViewInAppButton {
  deepLink?: string;
  showNewVersion?: boolean;
}

const ViewInAppButton = ({
  deepLink = DEEP_LINK.NEW_FEATURE_OPEN_APP,
  showNewVersion = false,
}: IViewInAppButton) => {
  const { t } = useTranslation();
  const router = useRouter();

  const handleOnClick = () => {
    openDeepLink(router, deepLink);
  };

  if (showNewVersion) {
    return (
      <button css={newVersionWrapper} onClick={handleOnClick}>
        <StoryPlayLogo2Icon />
        <a className="text">
          <Trans
            i18nKey="storygame_screen_label_to_app_web"
            components={{ strong: <strong /> }}
          ></Trans>
        </a>
        <ArrowRight24Icon
          className={'right-icon'}
          width={24}
          height={24}
          stroke={theme.colors.white}
        />
      </button>
    );
  }

  return (
    <button css={wrapper} onClick={handleOnClick}>
      <a className="button-text">
        <strong className="strong-text">
          {t('common_screen_button_open_app')}
        </strong>
      </a>
      <NextBtn width={16} height={16} stroke={theme.colors.white} />
    </button>
  );
};

const wrapper = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
  width: 100%;
  height: 3.5em;
  cursor: pointer;
  background: ${theme.colors.primary500};
  color: ${theme.colors.white};
  font-family: Apple SD Gothic Neo;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.30000001192092896px;
  text-align: left;

  .button-text {
    padding-top: 0.2em;

    .strong-text {
      font-weight: 700;
      font-family: Apple SD Gothic Neo;
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: -0.30000001192092896px;
      text-align: left;
    }
  }
`;

const newVersionWrapper = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.primary500};
  width: 100%;
  padding: ${pxToRem(12)} ${pxToRem(16)};
  gap: ${pxToRem(16)};
  border-radius: ${pxToRem(8)};
  cursor: pointer;
  color: ${theme.colors.whiteA(75)};
  ${theme.fontV3.body.body04Reg};
  text-align: left;

  .text {
    flex: 1;

    strong {
      color: ${theme.colors.white};
      font-weight: 500;
    }
  }

  .right-icon {
    margin-left: auto;
    color: ${theme.colors.whiteA(50)};
  }
`;

export default ViewInAppButton;
