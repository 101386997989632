import * as Types from '../../../baseType';

import { gql } from '@apollo/client';
export type StoryVerticalCoverDtoFragment = { __typename?: 'Story', type: Types.Story_Type, storyId: number, name: string, authorName: string, onHiatus: boolean, isFinished: boolean, isNew: boolean, isUp: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, representedAt?: any | null, sectionType: Types.Story_Section_Type, isAdult: boolean, mainImageFile?: { __typename?: 'File', link: string } | null, storyWeekdays?: Array<{ __typename?: 'StoryWeekday', weekday: Types.Story_Weekday }> | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string } } | null, language?: { __typename?: 'Language', display: string, code: string } | null };

export type StoryHorizontalCoverDtoFragment = { __typename?: 'Story', storyId: number, name: string, oneLineDesc: string, authorName: string, numViews: number, numLikes: number, numComment: number, onHiatus: boolean, isFinished: boolean, isUp: boolean, isAdult: boolean, isTimeLeapFree: boolean, playType: Types.Story_Play_Type, mainImageFile?: { __typename?: 'File', link: string } | null, genreKey?: { __typename?: 'StoryGenre', text: { __typename?: 'LocalizeKey', key: string, ko_KR: string } } | null };

export type GenreInfoDtoFragment = { __typename?: 'StoryGenre', type: string, text: { __typename?: 'LocalizeKey', key: string, ko_KR: string }, title?: { __typename?: 'LocalizeKey', key: string, ko_KR: string } | null };

export const StoryVerticalCoverDtoFragmentDoc = gql`
    fragment StoryVerticalCoverDto on Story {
  type
  storyId
  name
  authorName
  onHiatus
  isFinished
  isNew
  isUp
  isTimeLeapFree
  playType
  representedAt
  sectionType
  playType
  isAdult
  mainImageFile {
    link
  }
  storyWeekdays {
    weekday
  }
  genreKey {
    text {
      key
    }
  }
  language {
    display
    code
  }
}
    `;
export const StoryHorizontalCoverDtoFragmentDoc = gql`
    fragment StoryHorizontalCoverDto on Story {
  storyId
  name
  oneLineDesc
  authorName
  numViews
  numLikes
  numComment
  onHiatus
  isFinished
  isUp
  isAdult
  isTimeLeapFree
  playType
  mainImageFile {
    link
  }
  genreKey {
    text {
      key
      ko_KR
    }
  }
}
    `;
export const GenreInfoDtoFragmentDoc = gql`
    fragment GenreInfoDto on StoryGenre {
  type
  text {
    key
    ko_KR
  }
  title {
    key
    ko_KR
  }
}
    `;