import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNoticeTermsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetNoticeTermsQuery = { __typename?: 'Query', getNoticeTerms: { __typename?: 'NoticeTerms', TOS: { __typename?: 'Terms', termsId: number, link: string }, PP: { __typename?: 'Terms', termsId: number, link: string } } };


export const GetNoticeTermsDocument = gql`
    query getNoticeTerms {
  getNoticeTerms {
    TOS {
      termsId
      link
    }
    PP {
      termsId
      link
    }
  }
}
    `;

/**
 * __useGetNoticeTermsQuery__
 *
 * To run a query within a React component, call `useGetNoticeTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoticeTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoticeTermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNoticeTermsQuery(baseOptions?: Apollo.QueryHookOptions<GetNoticeTermsQuery, GetNoticeTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNoticeTermsQuery, GetNoticeTermsQueryVariables>(GetNoticeTermsDocument, options);
      }
export function useGetNoticeTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNoticeTermsQuery, GetNoticeTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNoticeTermsQuery, GetNoticeTermsQueryVariables>(GetNoticeTermsDocument, options);
        }
export type GetNoticeTermsQueryHookResult = ReturnType<typeof useGetNoticeTermsQuery>;
export type GetNoticeTermsLazyQueryHookResult = ReturnType<typeof useGetNoticeTermsLazyQuery>;
export type GetNoticeTermsQueryResult = Apollo.QueryResult<GetNoticeTermsQuery, GetNoticeTermsQueryVariables>;