import React from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { useRouter } from 'next/router';
import { useGetNoticeTermsQuery } from '@/operations/queries/user/__generated__/useGetNoticeTerms.generated';
import LanguageDropDown from './LanguageDropDown';

const StoryGameFooter = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { data } = useGetNoticeTermsQuery({
    skip: router.pathname.includes('webview'),
  });

  return (
    <footer css={container}>
      <div className="footer-wrapper">
        <div className="link-container">
          <Link href="/notices">
            <a>
              <span className="notice">
                {t('more_screen_title_notice')}
                <div className="divider"></div>
              </span>
            </a>
          </Link>
          <a
            href={data?.getNoticeTerms.PP.link}
            target="_blank"
            rel="noreferrer"
          >
            <span className="notice">
              {t('common_screen_title_privacy_policy')}
              <div className="divider"></div>
            </span>
          </a>
          <a
            href={data?.getNoticeTerms.TOS.link}
            target="_blank"
            rel="noreferrer"
          >
            <span className="notice">
              {t('common_screen_title_terms_of_service')}
            </span>
          </a>
        </div>
        <address>
          <div className="info">
            {router.locale === 'ko' ? (
              <>
                {`${t('common_screen_description_thingsflow_inc')} | ${t(
                  'common_screen_description_ceo_suji_lee',
                )} | ${t(
                  'common_screen_description_business_registeration_number',
                )}`}
              </>
            ) : (
              <>
                {`${t('common_screen_description_thingsflow_inc')} | ${t(
                  'common_screen_description_ceo_suji_lee',
                )}`}
              </>
            )}
          </div>
          <div className="info">
            {`${t('common_screen_description_company_address')} | ${t(
              'common_screen_title_contact',
            )} : ${t('common_screen_description_representative_number')}`}
          </div>
          <div className="support">
            <Link href="mailto:help@storyplay.com">
              <a className="info">
                {t('common_screen_title_support')} : help@storyplay.com
              </a>
            </Link>
            <span className="divider">|</span>
            <Link href="mailto:support@storyplay.com">
              <a className="info">
                {t('common_screen_title_partnership')} : support@storyplay.com
              </a>
            </Link>
          </div>
        </address>
        <br />
        <span className="info">{t('common_screen_description_copyright')}</span>
        <div className="language-dropdown">
          <LanguageDropDown isDarkTheme />
        </div>
      </div>
    </footer>
  );
};

const container = css`
  width: 100vw;
  background: var(--black, #000);
  display: flex;
  justify-content: center;

  .footer-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    max-width: 70.5rem;
    padding: 3rem 0;
    color: var(--whiteA25, rgba(255, 255, 255, 0.25));

    ${theme.setPCStyle(css`
      width: 100%;
      min-width: initial;
      padding: 3rem 1.5rem;
    `)};
  }

  .link-container {
    display: flex;

    .notice {
      font-weight: 700;
      display: flex;
      align-items: center;
      color: var(--whiteA50, rgba(255, 255, 255, 0.5));
      ${theme.fontV3.body.body05Reg};

      .divider {
        margin: 0 0.625rem;
        padding-top: -0.25rem;
        width: 0.125rem;
        height: 0.8125rem;
        background: var(--whiteA25, rgba(255, 255, 255, 0.25));
      }
    }
  }

  address {
    margin-top: 1.25rem;
    ${theme.fontV3.body.body06Reg};
  }

  .icon {
    display: flex;
    gap: 1.125rem;
    margin-top: 2.5625rem;
    margin-bottom: 1.125rem;
  }

  .info {
    ${theme.fontV3.body.body06Reg};
  }

  .language-dropdown {
    position: absolute;
    top: 2rem;
    right: 1.5rem;
  }

  .support {
    ${theme.fontV3.body.body06Reg};

    .divider {
      margin: 0 0.25rem;
    }
  }

  ${theme.setPCStyle(css`
    width: 100%;
  `)};

  ${theme.setMobileStyle(css`
    .language-dropdown {
      position: static;
      margin-top: 0.5rem;
    }
  `)};
`;

export default StoryGameFooter;
