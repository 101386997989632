import {
  Section,
  SingInMethod,
  StoryStatus,
  HomeTab,
  ContentType,
  AdLocationType,
  StartType,
  ChapterFromType,
  PlatformType,
} from '@customTypes/event';
import { ChallengeStorySorting, StoryGenre } from '@customTypes/story';
import { PushCategory } from '@customTypes/notification';
import { STORAGE_KEY } from '@common/values';
import { Challenge_Story_Genre } from '@/baseType';

export const GA_ID = process.env.NEXT_PUBLIC_GA_ID;

declare global {
  interface Window {
    gtag: any;
  }
}

const setUserProperties = () => {
  const properties = localStorage.getItem(STORAGE_KEY.USER_PROPERTIES);
  const userProperties = properties && JSON.parse(properties);

  window.gtag('set', 'user_properties', {
    openapp_feature_version: localStorage.getItem(
      STORAGE_KEY.OPEN_APP_FEATURE_VERSION,
    ),
    rewarded_ad_feature_version: '1',
    payment_feature_version: '1',
    user_id: userProperties?.user_id ?? null,
    sign_up_at: userProperties?.sign_up_at ?? null,
    is_tester: userProperties?.is_tester ?? null,
    language: userProperties?.language ?? null,
    is_adult: userProperties?.isAdult,
  });
};

export const pageView = () => {
  setUserProperties();
  window.gtag('event', 'page_view', {});
};

export const viewHomeTab = () => {
  setUserProperties();
  window.gtag('event', 'view_home_tab', {});
};

export const swipeHomeSection = (sectionName: string) => {
  setUserProperties();
  window.gtag('event', 'view_home_tab', { section: sectionName });
};

export const touchOpenAppButton = () => {
  setUserProperties();
  window.gtag('event', 'touch_openapp_button', {});
};

export const viewStoryDetail = ({
  previousSection,
  storyId,
  storyBundleId,
  storyName,
  storyLanguage,
  storyGenre,
  label,
  contentType,
  from,
  order,
  filter,
  status,
}: {
  previousSection: Section | null;
  storyId: string;
  storyBundleId: string;
  storyName: string;
  storyLanguage: string;
  storyGenre: string;
  label: string | null;
  contentType: ContentType;
  from: '채팅_플로팅' | '채팅_배너' | '스토어' | '정보없음' | string | null;
  order: '오리지널' | '신작' | '완결' | 'UP' | '휴재' | string | null;
  filter: string | null;
  status: StoryStatus | null;
}) => {
  setUserProperties();
  window.gtag('event', 'view_story_detail', {
    previous_section: previousSection,
    story_id: storyId,
    story_bundle_id: storyBundleId,
    story_name: storyName,
    story_language: storyLanguage,
    story_genre: storyGenre,
    play_type: contentType,
    from,
    label,
    order,
    filter,
    status,
  });
  window.gtag('event', `view_story_detail_${storyName}`, {
    previous_section: previousSection,
    story_id: storyId,
    from,
  });
};

export const selectContent = (itemAndId: string) => {
  setUserProperties();
  window.gtag('event', 'select_content', {
    content_type: 'interactive',
    item_id: itemAndId,
  });
};

export const viewDeleteAccount = () => {
  setUserProperties();
  window.gtag('event', 'view_delete_account', {});
};

export const touchLeaveButton = () => {
  setUserProperties();
  window.gtag('event', 'touch_leave_button', {});
};

export const completeDeleteAccount = () => {
  setUserProperties();
  window.gtag('event', 'complete_delete_account', {});
};

export const startChapter = ({
  story,
  storyId,
  storyBundleId,
  chapter,
  chapterId,
  start,
  waitFree,
  adImpression,
  price,
  pay,
  contentType,
  storyType,
  withCustomCharacter,
}: {
  story: string;
  storyId: string;
  storyBundleId: string;
  chapter: number;
  chapterId: string;
  start: 'start' | 'continue' | 'time_leap' | '정보없음';
  waitFree?: boolean | '정보없음';
  adImpression?: boolean | '정보없음';
  price?: number | null;
  pay: 'T' | 'F';
  contentType: ContentType;
  storyType: 'home' | 'challenge' | '정보없음';
  withCustomCharacter: boolean;
}) => {
  setUserProperties();
  window.gtag('event', 'start_chapter', {
    story,
    story_id: storyId,
    story_bundle_id: storyBundleId,
    chapter,
    chapter_id: chapterId,
    start,
    wait_free: waitFree,
    ad_impression: adImpression,
    price,
    pay,
    content_type: contentType,
    story_type: storyType,
    with_custom_character: withCustomCharacter,
  });
};

export const startChapterFromDetail = ({
  storyId,
  storyBundleId,
  chapterId,
  chapterIndex,
  storyName,
  storyLanguage,
  storyGenre,
  playType,
  startType,
  chapterIsAdult,
}: {
  storyId: string;
  storyBundleId: string | null;
  chapterId: string;
  chapterIndex: number;
  storyName: string;
  storyLanguage: string | null;
  storyGenre: string | null;
  playType: ContentType;
  startType: StartType;
  chapterIsAdult: boolean;
}) => {
  setUserProperties();
  window.gtag('event', 'start_chapter_from_detail', {
    story_id: storyId,
    story_bundle_id: storyBundleId,
    chapter_id: chapterId,
    chapter_index: chapterIndex,
    story_name: storyName,
    story_language: storyLanguage,
    story_genre: storyGenre,
    play_type: playType,
    start_type: startType,
    chapter_is_adult: chapterIsAdult,
  });
};

export const startChapterFirst = ({
  story,
  storyId,
  chapterId,
  chapter,
  contentType,
  storyType,
}: {
  story: string;
  storyId: string;
  chapter: number;
  chapterId: string;
  contentType: ContentType;
  storyType: 'home' | 'challenge' | '정보없음';
}) => {
  setUserProperties();
  window.gtag('event', 'start_chapter_first', {
    story,
    story_id: storyId,
    chapter_id: chapterId,
    chapter,
    contentType,
    storyType,
  });
};

export const endChapter = ({
  storyName,
  chapterIndex,
  endingId = '정보없음',
  endingTitle = '정보없음',
  storyId,
  storyBundleId,
  storyType,
  chapterId,
  contentType,
  withCustomCharacter,
}: {
  storyName: string;
  chapterIndex: number;
  endingId?: string | '정보없음';
  endingTitle?: string | '정보없음';
  storyId: number;
  storyBundleId?: number;
  storyType: 'home' | 'challenge' | '정보없음';
  chapterId: string;
  contentType: ContentType;
  withCustomCharacter: boolean;
}) => {
  setUserProperties();
  window.gtag('event', 'end_chapter', {
    story: storyName,
    story_id: storyId,
    story_bundle_id: storyBundleId,
    chapter_id: chapterId,
    chapter: chapterIndex,
    ending_id: endingId,
    ending_title: endingTitle,
    story_type: storyType,
    content_type: contentType,
    with_custom_character: withCustomCharacter,
  });
  window.gtag('event', `end_chapter_${chapterIndex}_${storyName}`, {
    chapter_ending_summary: `${storyName}_${endingTitle}`,
  });
};

export const endChapterFirst = ({
  story,
  storyId,
  chapterId,
  chapter,
  contentType,
  storyType,
}: {
  story: string;
  storyId: string;
  chapter: number;
  chapterId: string;
  contentType: ContentType;
  storyType: 'home' | 'challenge' | '정보없음';
}) => {
  setUserProperties();
  window.gtag('event', 'end_chapter_first', {
    story,
    story_id: storyId,
    chapter_id: chapterId,
    chapter,
    contentType,
    storyType,
  });
};

export const startStoryAll = (previous_screen: string) => {
  setUserProperties();
  window.gtag('event', 'start_story_all', { previous_screen: previous_screen });
};

export const startStoryName = (name: string) => {
  setUserProperties();
  window.gtag('event', `start_story_${name}`, {});
};

export const makeChoice = ({
  storyName,
  chapterIndex,
  choiceName,
}: {
  storyName: string;
  chapterIndex: number;
  choiceName: string;
}) => {
  setUserProperties();
  window.gtag('event', `make_a_choice`, {
    choice: `${storyName}_${chapterIndex}_${choiceName}`,
  });
};

export const makeChoiceStats = ({
  storyName,
  chapterIndex,
  questionName,
  choiceName,
  isReplay,
}: {
  storyName: string;
  chapterIndex: number;
  questionName: string;
  choiceName: string;
  isReplay: boolean;
}) => {
  setUserProperties();
  window.gtag(
    'event',
    isReplay ? 'replay_choice_stats' : 'make_a_choice_stats',
    {
      choice: `${storyName}_${chapterIndex}_${questionName}_${choiceName}`,
    },
  );
};

export const touchTabInChoiceStats = ({
  storyName,
  chapterIndex,
  questionName,
  choiceName,
  tab,
  isReplay,
}: {
  storyName: string;
  chapterIndex: number;
  questionName: string;
  choiceName: string;
  tab: 'all' | 'class';
  isReplay: boolean;
}) => {
  setUserProperties();
  window.gtag(
    'event',
    isReplay ? 'touch_tab_in_replay_choice_stats' : 'touch_tab_in_choice_stats',
    {
      choice: `${storyName}_${chapterIndex}_${questionName}_${choiceName}`,
      tab,
    },
  );
};

export const endStoryAll = () => {
  setUserProperties();
  window.gtag('event', 'end_story_all', {});
};

export const endStory = ({
  storyName,
  endingSummary,
  endingTitle,
  storyId,
  endingId,
  rareEnding,
  choiceCount,
  collectionRate,
  screenName,
}: {
  storyName: string;
  endingSummary: string;
  endingTitle: string;
  storyId: number;
  endingId: number;
  rareEnding: number;
  choiceCount: number;
  collectionRate: number;
  screenName: string;
}) => {
  setUserProperties();
  window.gtag('event', 'end_story', {
    story_ending_summary: `${storyName}_${endingSummary}`,
    story_id: storyId.toString(),
    story: storyName,
  });
  window.gtag('event', `end_story_${storyName}`, {
    story_ending_summary: endingSummary,
  });
  window.gtag('event', `view_final_ending_dialog`, {
    story: storyName,
    story_id: storyId.toString(),
    ending_title: endingTitle,
    ending_id: endingId.toString(),
    rare_ending: rareEnding.toString(),
    choice_count: choiceCount.toString(),
    collection_rate: collectionRate.toString(),
    screen_name: screenName,
  });
};

export const startAutoPlayReading = ({
  storyName,
  chapterIndex,
}: {
  storyName: string;
  chapterIndex: number;
}) => {
  setUserProperties();
  window.gtag('event', 'start_autoPlay_for_reading', {
    story: `${storyName}_${chapterIndex}`,
  });
};

export const touchOpenAppNextChapter = ({
  storyName,
  storyId,
  chapterId,
}: {
  storyName: string;
  storyId: string;
  chapterId: string;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_openapp_next_chapter', {
    story: storyName,
    storyId: storyId,
    chapter: chapterId,
  });
};

export const viewAppConversionModal = ({
  where,
  story,
  storyId,
}: {
  where: 'detail_like' | 'detail_comment' | 'detail_number' | 'chat_comment';
  story: string;
  storyId: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_app_conversion_modal', {
    where,
    story,
    storyId,
    chapter: null,
  });
};

export const openAppConversionModal = ({
  where,
  story,
  storyId,
}: {
  where: 'detail_like' | 'detail_comment' | 'detail_number' | 'chat_comment';
  story: string;
  storyId: string;
}) => {
  setUserProperties();
  window.gtag('event', 'openapp_app_conversion_modal', {
    where,
    story,
    storyId,
    chapter: null,
  });
};

export const shareStoryDetail = ({
  story,
  storyId,
}: {
  story: string;
  storyId: string;
}) => {
  setUserProperties();
  window.gtag('event', 'share_story_detail', { story, storyId });
};

export const viewAppConversionBottomsheet = () => {
  setUserProperties();
  window.gtag('event', 'view_app_conversion_bottomsheet', {});
};

export const touchOpenAppConversionBottomsheet = () => {
  setUserProperties();
  window.gtag('event', 'touch_openapp_app_conversion_bottomsheet', {});
};

export const touchCloseAppConversionBottomsheet = () => {
  setUserProperties();
  window.gtag('event', 'touch_close_app_conversion_bottomsheet', {});
};

export const viewAppConversionBanner = () => {
  setUserProperties();
  window.gtag('event', 'view_app_conversion_banner', {});
};

export const touchCloseAppConversionBanner = () => {
  setUserProperties();
  window.gtag('event', 'touch_close_app_conversion_banner', {});
};

export const viewLoginModal = ({
  story,
  storyId,
}: {
  story: string;
  storyId: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_login_modal', { story, storyId });
};

export const touchAcceptLoginModal = ({
  story,
  storyId,
}: {
  story: string;
  storyId: string;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_accept_login_modal', { story, storyId });
};

export const touchCloseLoginModal = ({
  story,
  storyId,
}: {
  story: string;
  storyId: string;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_close_login_modal', { story, storyId });
};

export const shareEndingButton = ({
  story,
  endingTitle,
  button,
  storyId,
}: {
  story: string;
  endingTitle: string;
  button: string;
  storyId: number | undefined;
}) => {
  setUserProperties();
  window.gtag('event', 'share_ending_button', {
    story,
    ending_title: endingTitle,
    button,
    storyId,
  });
};

export const viewTeaserVideo = ({
  storyId,
  storyName,
  isReplay,
}: {
  storyId: string;
  storyName: string;
  isReplay: boolean;
}) => {
  setUserProperties();
  window.gtag('event', 'view_teaser_video', {
    story_id: storyId,
    stroy: storyName,
    is_replay: isReplay.toString(),
  });
};

export const teaserPlayTime = ({
  storyId,
  storyName,
  isReplay,
  playTime,
}: {
  storyId: string;
  storyName: string;
  isReplay: boolean;
  playTime: string;
}) => {
  setUserProperties();
  window.gtag('event', 'teaser_play_time', {
    story_id: storyId,
    stroy: storyName,
    is_replay: isReplay.toString(),
    play_time: playTime,
  });
};

export const viewTeaserVideoEnd = ({
  storyId,
  storyName,
  isReplay,
}: {
  storyId: string;
  storyName: string;
  isReplay: boolean;
}) => {
  setUserProperties();
  window.gtag('event', 'view_teaser_video_end', {
    story_id: storyId,
    stroy: storyName,
    is_replay: isReplay.toString(),
  });
};

export const viewSignInOrSignUp = () => {
  setUserProperties();
  window.gtag('event', 'view_sign_in_or_sign_up', {});
};

export const startSignIn = ({ method }: { method: SingInMethod }) => {
  setUserProperties();
  window.gtag('event', 'start_sign_in', { method });
};

export const startSignUp = ({ method }: { method: SingInMethod }) => {
  setUserProperties();
  window.gtag('event', 'start_sign_up', { method });
};

export const startSignUpInputEmail = () => {
  setUserProperties();
  window.gtag('event', 'start_sign_up_input_email', {});
};

export const startSignUpInputPassword = () => {
  setUserProperties();
  window.gtag('event', 'start_sign_up_input_password', {});
};

export const startSignUpInputNickname = () => {
  setUserProperties();
  window.gtag('event', 'start_sign_up_input_nick', {});
};

export const firstLogin = ({ method }: { method: SingInMethod }) => {
  setUserProperties();
  window.gtag('event', 'first_login', { method });
};

export const viewMainTabMore = () => {
  setUserProperties();
  window.gtag('event', 'view_main_tab_more', {});
};

export const viewEditPassword = () => {
  setUserProperties();
  window.gtag('event', 'view_edit_password', {});
};

export const editPassword = () => {
  setUserProperties();
  window.gtag('event', 'edit_password', {});
};

export const touchADBannerInChat = ({
  storyId,
  storyName,
  chapterIndex,
  targetUrl,
}: {
  storyId: string;
  storyName: string;
  chapterIndex: string;
  targetUrl: string;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_ad_banner_in_chat', {
    story_id: storyId,
    story: storyName,
    chapter: chapterIndex,
    url: targetUrl,
  });
};

export const viewChallengeTab = () => {
  setUserProperties();
  window.gtag('event', 'view_challenge_tab');
};

export const touchChallengeGenre = ({ genre }: { genre: StoryGenre }) => {
  setUserProperties();
  window.gtag('event', 'touch_challenge_genre', { genre });
};

export const touchChallengeSort = ({
  sort,
}: {
  sort: ChallengeStorySorting;
}) => {
  setUserProperties();
  let sortText = '';

  switch (sort) {
    case 'RECOMMENDED':
      sortText = '인기순';
      break;

    case 'LATEST_COMMENT':
      sortText = '최근 댓글순';
      break;

    case 'LATEST_UPDATED':
      sortText = '업데이트순';
      break;

    case 'NUM_LIKES':
      sortText = '좋아요순';
      break;

    case 'NUM_VIEWS':
      sortText = '조회순';
      break;

    default:
      sortText = '인기순';
      break;
  }

  window.gtag('event', 'touch_challenge_sort', { sort: sortText });
};

export const touchChallengeWriteButton = () => {
  setUserProperties();
  window.gtag('event', 'touch_challenge_write_button', {});
};

export const touchContestBanner = ({ title }: { title: string }) => {
  setUserProperties();
  window.gtag('event', 'touch_contest_banner', { contest_title: title });
};

export const viewStoryThumbnail = ({
  section,
  story,
  index,
  storyId,
  order,
  contentType,
}: {
  section: string;
  story: string;
  index: string;
  storyId: string;
  order?: '인기순' | '최신순' | '정보없음' | string;
  contentType: ContentType | '정보없음';
}) => {
  setUserProperties();
  window.gtag('event', 'view_story_thumbnail', {
    section,
    story,
    story_id: storyId,
    index,
    order,
    content_type: contentType,
  });
};

export const touchChallengeSearch = () => {
  setUserProperties();
  window.gtag('event', 'touch_challenge_search', {});
};

export const touchWriteStory = ({ isAuthor }: { isAuthor: string }) => {
  setUserProperties();
  window.gtag('event', 'touch_write_story', { is_author: isAuthor });
};

export const touchStudioGuide = () => {
  setUserProperties();
  window.gtag('event', 'touch_studio_guide', {});
};

export const touchAuthorDashboard = () => {
  setUserProperties();
  window.gtag('event', 'touch_author_dashboard', {});
};

export const viewNotification = ({
  previousSection,
  notification = 'false',
}: {
  previousSection: string;
  notification: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_notification', {
    previous_section: previousSection,
    notification,
  });
};

export const touchNotificationItem = ({
  notificationType,
  notificationStoryId,
}: {
  notificationType: PushCategory;
  notificationStoryId: string;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_notification_item', {
    notification_type: notificationType,
    notification_story_id: notificationStoryId,
  });
};

export const touchNotificationSetting = () => {
  setUserProperties();
  window.gtag('event', 'edit_password', {});
};

export const shareReplayChoiceStats = ({
  storyName,
  chapterIndex,
  questionName,
  choiceName,
  tab,
  isReplay,
  storyId,
}: {
  storyName: string;
  chapterIndex: number;
  questionName: string;
  choiceName: string;
  tab: 'all' | 'class';
  isReplay: boolean;
  storyId: number | undefined;
}) => {
  setUserProperties();
  window.gtag(
    'event',
    isReplay ? 'share_replay_choice_stats' : 'share_choice_stats',
    {
      choice: `${storyName}_${chapterIndex}_${questionName}_${choiceName}`,
      tab,
      storyId,
    },
  );
};

export const viewAchievementPopup = ({
  storyName,
  chapterIndex,
  achievement,
}: {
  storyName?: string;
  chapterIndex?: string;
  achievement?: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_achievement_popup', {
    storyName,
    chapterIndex,
    achievement,
  });
};

export const viewItemPopup = ({
  storyName,
  chapterIndex,
  item,
}: {
  storyName?: string;
  chapterIndex?: string;
  item?: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_item_popup', {
    storyName,
    chapterIndex,
    item,
  });
};

export const adStart = ({
  storyId,
  adUnitId,
  adLoc,
  adUrl,
  storyBundleId,
  chapterId,
  chapterIndex,
  storyName,
  storyLanguage,
  storyGenre,
  playType,
  rewardType,
}: {
  storyId: string;
  adUnitId: string | null;
  adLoc: AdLocationType;
  adUrl: string | null;
  storyBundleId: string | null;
  chapterId: string;
  chapterIndex: number;
  storyName: string;
  storyLanguage: string | null;
  storyGenre: Challenge_Story_Genre | null;
  playType: ContentType;
  rewardType: 'FREE_CHAPTER' | 'FREE_CHARACTER';
}) => {
  setUserProperties();
  window.gtag('event', 'ad_start', {
    story: storyName,
    chapter: chapterId,
    index: chapterIndex,
    story_id: storyId,
    ad_unit_id: adUnitId,
    ad_loc: adLoc,
    ad_url: adUrl,
    story_bundle_id: storyBundleId,
    chapter_id: chapterId,
    chapter_index: chapterIndex,
    story_name: storyName,
    story_language: storyLanguage,
    story_genre: storyGenre,
    play_type: playType,
    reward_type: rewardType,
  });
};

export const adFinish = ({
  storyId,
  adUnitId,
  adLoc,
  adUrl,
  storyBundleId,
  chapterId,
  chapterIndex,
  storyName,
  storyLanguage,
  storyGenre,
  playType,
  rewardType,
}: {
  storyId: string;
  adUnitId: string | null;
  adLoc: AdLocationType;
  adUrl: string | null;
  storyBundleId: string | null;
  chapterId: string;
  chapterIndex: number;
  storyName: string;
  storyLanguage: string | null;
  storyGenre: Challenge_Story_Genre | null;
  playType: ContentType;
  rewardType: 'FREE_CHAPTER' | 'FREE_CHARACTER';
}) => {
  setUserProperties();

  window.gtag('event', 'ad_finish', {
    story: storyName,
    chapter: chapterId,
    index: chapterIndex,
    story_id: storyId,
    ad_unit_id: adUnitId,
    ad_loc: adLoc,
    ad_url: adUrl,
    story_bundle_id: storyBundleId,
    chapter_id: chapterId,
    chapter_index: chapterIndex,
    story_name: storyName,
    story_language: storyLanguage,
    story_genre: storyGenre,
    play_type: playType,
    reward_type: rewardType,
  });
};

export const payTicketForStory = ({
  amount,
  storyId,
  storyBundleId,
  storyName,
  storyLanguage,
  storyGenre,
  playType,
}: {
  amount: number;
  storyId: string;
  storyBundleId: string | null;
  storyName: string;
  storyLanguage: string | null;
  storyGenre: string | null;
  playType: ContentType | null;
}) => {
  window.gtag('event', 'pay_ticket_for_story', {
    amount: amount,
    story_id: storyId,
    story_bundle_id: storyBundleId,
    story_name: storyName,
    story_language: storyLanguage,
    story_genre: storyGenre,
    play_type: playType,
  });
};

export const viewPurchaseStory = ({
  storyName,
  storyId,
  fullPrice,
  price,
  lastPreviewChapterIndex,
  from,
}: {
  storyName: string;
  storyId: string;
  fullPrice: string; // 정가
  price: string; // 할인가
  lastPreviewChapterIndex: string;
  from: 'chat' | 'detail' | 'novel' | '정보없음';
}) => {
  setUserProperties();
  window.gtag('event', 'view_purchase_story', {
    story: storyName,
    story_id: storyId,
    full_price: fullPrice,
    price: price,
    lastPreviewChapterIndex,
    from: from,
    is_coin: true,
  });
};

export const touchPurchaseStory = ({
  storyName,
  storyId,
  fullPrice,
  price,
  lastPreviewChapterIndex,
  from,
}: {
  storyName: string;
  storyId: string;
  fullPrice: string; // 정가
  price: string; // 할인가
  lastPreviewChapterIndex: string;
  from: 'chat' | 'detail' | 'novel' | '정보없음';
}) => {
  setUserProperties();
  window.gtag('event', 'touch_purchase_story', {
    story: storyName,
    story_id: storyId,
    full_price: fullPrice,
    price: price,
    lastPreviewChapterIndex,
    from: from,
    is_coin: true,
  });
};

export const viewPurchaseStoryPopup = ({
  storyName,
  storyId,
  fullPrice,
  price,
  userCoin,
}: {
  storyName: string;
  storyId: string;
  fullPrice: string; // 정가
  price: string; // 할인가
  userCoin: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_purchase_story_popup', {
    story: storyName,
    story_id: storyId,
    full_price: fullPrice,
    price: price,
    user_coin: userCoin,
  });
};

export const touchPurchaseStoryPopup = ({
  storyName,
  storyId,
  fullPrice,
  price,
  userCoin,
}: {
  storyName: string;
  storyId: string;
  fullPrice: string; // 정가
  price: string; // 할인가
  userCoin: string;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_purchase_story_popup', {
    story: storyName,
    story_id: storyId,
    full_price: fullPrice,
    price: price,
    user_coin: userCoin,
  });
};

export const viewInAppPurchaseTicket = ({
  neededCoin,
  userCoin,
}: {
  neededCoin: string;
  userCoin: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_in_app_purchase_ticket', {
    user_coin: userCoin,
    needed_coin: neededCoin,
  });
};

export const touchInAppPurchaseTicket = ({
  neededCoin,
  userCoin,
  price,
}: {
  neededCoin: string;
  userCoin: string;
  price: string;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_in_app_purchase_ticket', {
    user_coin: userCoin,
    needed_coin: neededCoin,
    price,
  });
};

export const touchPurchaseProceed = ({
  userCoin,
  coin,
  price,
  method,
}: {
  userCoin: string;
  coin: string;
  price: string;
  method: '신용카드' | '카카오페이' | '토스' | '휴대폰결제' | '정보없음';
}) => {
  setUserProperties();
  window.gtag('event', 'touch_purchase_proceed', {
    user_coin: userCoin,
    coin,
    price,
    method,
  });
};

export const purchaseTicketFailure = ({
  userCoin,
  coin,
  price,
  method,
  message,
}: {
  userCoin: string;
  coin: string;
  price: string;
  method: '신용카드' | '카카오페이' | '토스' | '휴대폰결제' | string;
  message: string;
}) => {
  setUserProperties();
  window.gtag('event', 'purchase_ticket_failure', {
    user_coin: userCoin,
    coin,
    price,
    method,
    message,
  });
};

export const purchaseTicket = ({
  id,
  userCoin,
  coin,
  price,
  method,
  name,
  currency,
  orderId,
}: {
  id?: string; // productId
  userCoin: string;
  coin: number;
  price: string;
  method: '신용카드' | '카카오페이' | '토스' | '휴대폰결제' | string;
  name: string;
  currency: string;
  orderId: string;
}) => {
  setUserProperties();
  window.gtag('event', 'purchase_ticket', {
    user_coin: userCoin,
    coin,
    price,
    method,
  });
  window.gtag('event', 'purchase', {
    event_category: 'ecommerce',
    transaction_id: orderId,
    value: Number(price).toFixed(2),
    currency,
    product_name: name,
    product_id: id,
    method,
    product_category: 'coin',
    items: [
      {
        item_id: id,
        item_name: name,
        item_category: 'coin',
        category: '',
        price: Number(price).toFixed(2),
        quantity: 1,
      },
    ],
  });
};

export const touchCommentButton = ({
  storyName,
  chapterIndex,
  from,
  contentType,
}: {
  storyName: string;
  chapterIndex: number | 'all';
  from: string;
  contentType: ContentType;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_comment_button', {
    story: storyName,
    chapter: chapterIndex,
    where: from,
    contentType,
  });
};

export const touchCommentReplyButton = ({ parentId }: { parentId: number }) => {
  setUserProperties();
  window.gtag('event', 'touch_comment_reply_button', {
    id: parentId,
  });
};

export const writeComment = ({
  storyId,
  storyName,
  chapterIndex,
  isSpoiler,
  commentId,
  choiceId,
  category = 'text',
  contentType,
}: {
  storyId: number;
  storyName: string;
  chapterIndex: number;
  isSpoiler: boolean;
  commentId: number;
  choiceId?: number;
  category?: 'text' | 'image' | 'stats';
  contentType: ContentType;
}) => {
  setUserProperties();
  window.gtag('event', 'write_comment', {
    storyId,
    story: storyName,
    chapter: chapterIndex,
    isSpoiler,
    commentId,
    choiceId,
    category,
    contentType,
  });
};

export const writeCommentReply = ({
  storyId,
  storyName,
  chapterIndex,
  isSpoiler,
  commentId,
  category,
  contentType,
}: {
  storyId: number;
  storyName: string;
  chapterIndex: number;
  isSpoiler: boolean;
  commentId: number;
  category: 'text' | 'image' | 'stats';
  contentType: ContentType;
}) => {
  setUserProperties();
  window.gtag('event', 'write_comment_reply', {
    storyId,
    story: storyName,
    chapter: chapterIndex,
    isSpoiler,
    commentId,
    category,
    contentType,
  });
};

export const deleteComment = ({
  storyName,
  chapterIndex,
  isSpoiler,
  replyCount,
  likeCount,
  category,
}: {
  storyName: string;
  chapterIndex: number;
  isSpoiler: boolean;
  replyCount: number;
  likeCount: number;
  category: 'text' | 'image' | 'stats';
}) => {
  setUserProperties();
  window.gtag('event', 'delete_comment', {
    story: storyName,
    chapter: chapterIndex,
    isSpoiler,
    replyCount,
    likeCount,
    category,
  });
};

export const deleteCommentReply = ({
  storyName,
  chapterIndex,
  isSpoiler,
  likeCount,
  category,
}: {
  storyName: string;
  chapterIndex: number;
  isSpoiler: boolean;
  likeCount: number;
  category: 'text' | 'image' | 'stats';
}) => {
  setUserProperties();
  window.gtag('event', 'delete_comment_reply', {
    story: storyName,
    chapter: chapterIndex,
    isSpoiler,
    likeCount,
    category,
  });
};

export const touchEditCommentButton = () => {
  setUserProperties();
  window.gtag('event', 'touch_edit_comment_button');
};

export const completeEditComment = () => {
  setUserProperties();
  window.gtag('event', 'complete_edit_comment');
};

export const touchReportCommentButton = ({
  storyName,
  chapterIndex,
  type,
  category = 'text',
}: {
  storyName: string;
  chapterIndex: number;
  type: 'comment' | 'reply';
  category?: 'text' | 'image' | 'stats';
}) => {
  setUserProperties();
  window.gtag('event', 'touch_report_comment_button', {
    story: storyName,
    chapter: chapterIndex,
    type,
    category,
  });
};

export const completeReportComment = ({
  reportId,
  storyName,
  chapterIndex,
  commentId,
  type,
  category,
}: {
  reportId: number;
  storyName: string;
  chapterIndex: number;
  commentId: number;
  type: 'comment' | 'reply';
  category: 'text' | 'image' | 'stats';
}) => {
  setUserProperties();
  window.gtag('event', 'complete_report_comment', {
    reportId,
    story: storyName,
    chapter: chapterIndex,
    commentId,
    type,
    category,
  });
};

export const likeComment = ({
  storyName,
  chapterIndex,
  commentId,
  likeCount,
  category,
  contentType,
}: {
  storyName: string;
  chapterIndex: number;
  commentId: number;
  likeCount: number;
  category: 'text' | 'image' | 'stats';
  contentType: ContentType;
}) => {
  setUserProperties();
  window.gtag('event', 'like_comment', {
    story: storyName,
    chapter: chapterIndex,
    commentId,
    likeCount,
    category,
    contentType,
  });
};

export const likeCommentReply = ({
  storyName,
  chapterIndex,
  commentId,
  likeCount,
  category,
  contentType,
}: {
  storyName: string;
  chapterIndex: number;
  commentId: number;
  likeCount: number;
  category: 'text' | 'image' | 'stats';
  contentType: ContentType;
}) => {
  setUserProperties();
  window.gtag('event', 'like_comment_reply', {
    story: storyName,
    chapterIndex,
    commentId,
    likeCount,
    category,
    contentType,
  });
};

export const cancelLikeComment = ({
  storyName,
  chapterIndex,
  commentId,
  likeCount,
  category,
}: {
  storyName: string;
  chapterIndex: number;
  commentId: number;
  likeCount: number;
  category: 'text' | 'image' | 'stats';
}) => {
  setUserProperties();
  window.gtag('event', 'cancel_like_comment', {
    story: storyName,
    chapter: chapterIndex,
    commentId,
    likeCount,
    category,
  });
};

export const cancelLikeCommentReply = ({
  storyName,
  chapterIndex,
  commentId,
  likeCount,
  category,
}: {
  storyName: string;
  chapterIndex: number;
  commentId: number;
  likeCount: number;
  category: 'text' | 'image' | 'stats';
}) => {
  setUserProperties();
  window.gtag('event', 'cancel_like_comment_reply', {
    story: storyName,
    chapterIndex,
    commentId,
    likeCount,
    category,
  });
};

export const touchHomeGenreTab = ({ section }: { section: string }) => {
  setUserProperties();
  window.gtag('event', 'touch_home_genre_tab', {
    section,
  });
};

export const touchWeekdayTab = ({ section }: { section: Section }) => {
  setUserProperties();
  window.gtag('event', 'touch_weekday_tab', {
    section,
  });
};

export const ageVerificationStart = ({
  storyId,
  platform,
}: {
  storyId: string | null;
  platform: PlatformType;
}) => {
  setUserProperties();

  window.gtag('event', 'age_verification_start', {
    story_id: storyId,
    platform,
  });
};

export const ageVerificationComplete = ({
  storyId,
  platform,
}: {
  storyId: string | null;
  platform: PlatformType;
}) => {
  setUserProperties();

  window.gtag('event', 'age_verification_complete', {
    story_id: storyId,
    platform,
  });
};

export const viewAgeVerification = ({
  platform,
}: {
  platform: PlatformType;
}) => {
  setUserProperties();

  window.gtag('event', 'view_age_verification', {
    platform,
  });
};

export const adSalesImpressionDirect = ({
  directAdsId,
  adTitle,
  adLoc,
  adUrl,
  adImageUrl,
}: {
  directAdsId: number;
  adTitle: string | null;
  adLoc: AdLocationType;
  adUrl: string | null;
  adImageUrl: string | null;
}) => {
  setUserProperties();
  window.gtag('event', 'ad_sales_impression_direct', {
    id: directAdsId,
    ad_title: adTitle,
    ad_loc: adLoc,
    ad_url: adUrl,
    ad_img_url: adImageUrl,
  });
};

export const adSalesClickDirect = ({
  directAdsId,
  adTitle,
  adLoc,
  adUrl,
  adImageUrl,
}: {
  directAdsId: number;
  adTitle: string | null;
  adLoc: AdLocationType;
  adUrl: string | null;
  adImageUrl: string | null;
}) => {
  setUserProperties();
  window.gtag('event', 'ad_sales_click_direct', {
    id: directAdsId,
    ad_title: adTitle,
    ad_loc: adLoc,
    ad_url: adUrl,
    ad_img_url: adImageUrl,
  });
};

export const touchHomeTab = ({ tab }: { tab: HomeTab }) => {
  window.gtag('event', 'touch_home_tab', {
    tab,
  });
};

export const touchInteractiveTab = () => {
  window.gtag('event', 'touch_interactive_tab');
};

export const touchNovelTab = () => {
  window.gtag('event', 'touch_novel_tab');
};

export const touchChallengeTab = () => {
  window.gtag('event', 'touch_challenge_tab');
};

export const touchHistoryTab = () => {
  window.gtag('event', 'touch_history_tab');
};

export const touchStoreTab = () => {
  window.gtag('event', 'touch_store_tab');
};

export const touchSearchTab = () => {
  window.gtag('event', 'touch_search_tab');
};

export const touchNotificationTab = () => {
  window.gtag('event', 'touch_notification_tab');
};

export const touchMoreTab = () => {
  window.gtag('event', 'touch_more_tab');
};

export const touchLikeStory = ({
  story,
  story_id,
  like,
  content_type,
}: {
  story: string;
  story_id: string;
  like: boolean;
  content_type: ContentType;
}) => {
  window.gtag('event', 'touch_like_story', {
    story,
    story_id,
    like,
    content_type,
  });
};

export const touchChangeScrollType = ({
  type,
}: {
  type: 'scroll' | 'page';
}) => {
  setUserProperties();
  window.gtag('event', 'touch_change_scroll_type', {
    type,
  });
};

export const touchSprintWatch = () => {
  setUserProperties();
  window.gtag('event', 'touch_sprint_watch');
};

export const viewSprintWatchPopup = () => {
  setUserProperties();
  window.gtag('event', 'view_sprint_watch_popup');
};

export const touchSprintWatchConfirm = () => {
  setUserProperties();
  window.gtag('event', 'touch_sprint_watch_confirm');
};

export const touchMoveChapter = () => {
  setUserProperties();
  window.gtag('event', 'touch_move_chapter');
};

export const touchAllChapterPurchase = ({
  storyName,
  storyId,
  fullCoin,
  coin,
  lastPreviewChapterIndex,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  fullCoin?: number;
  coin?: number;
  lastPreviewChapterIndex?: number | null;
  contentType: 'interactive' | 'novel';
}) => {
  setUserProperties();
  window.gtag('event', 'touch_all_chapter_purchase', {
    story: storyName,
    story_id: storyId,
    full_coin: fullCoin,
    coin,
    lastPreviewChapterIndex,
    content_type: contentType,
  });
};

export const viewAllChapterPurchasePopup = ({
  storyName,
  storyId,
  fullCoin,
  coin,
  lastPreviewChapterIndex,
  purchasedChapter,
  chapterCoin,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  fullCoin?: number;
  coin?: number;
  lastPreviewChapterIndex?: number | null;
  purchasedChapter?: number;
  chapterCoin?: number;
  contentType: 'interactive' | 'novel';
}) => {
  setUserProperties();
  window.gtag('event', 'view_all_chapter_purchase_popup', {
    story: storyName,
    story_id: storyId,
    full_coin: fullCoin,
    coin,
    lastPreviewChapterIndex,
    purchased_chapter: purchasedChapter,
    chapter_coin: chapterCoin,
    content_type: contentType,
  });
};

export const viewEachChapterPurchasePopup = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  fullCoin,
  coin,
  from,
  chapterCoin,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: string;
  chapterIndex?: number;
  fullCoin?: number;
  coin?: number;
  from?: ChapterFromType;
  chapterCoin?: number;
  contentType: 'interactive' | 'novel';
}) => {
  setUserProperties();
  window.gtag('event', 'view_each_chapter_purchase_popup', {
    story: storyName,
    story_id: storyId,
    chapter_id: chapterId,
    chapter: chapterIndex,
    full_coin: fullCoin,
    coin,
    from,
    chapter_coin: chapterCoin,
    content_type: contentType,
  });
};

export const payTicketForEachChapter = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  fullCoin,
  coin,
  from,
  chapterCoin,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: string;
  chapterIndex?: number;
  fullCoin?: number;
  coin?: number;
  from?: ChapterFromType;
  chapterCoin?: number;
  contentType: 'interactive' | 'novel' | 'storygame';
}) => {
  setUserProperties();
  window.gtag('event', 'pay_ticket_for_each_chapter', {
    story: storyName,
    story_id: storyId,
    chapter_id: chapterId,
    chapter: chapterIndex,
    full_coin: fullCoin,
    coin,
    from,
    chapter_coin: chapterCoin,
    content_type: contentType,
  });
};

export const touchTicketForNextChapter = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  coin,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: string;
  chapterIndex?: number;
  coin?: number;
  contentType: 'interactive' | 'novel';
}) => {
  setUserProperties();
  window.gtag('event', 'touch_ticket_for_next_chapter', {
    story: storyName,
    story_id: storyId,
    chapter_id: chapterId,
    chapter: chapterIndex,
    coin,
    content_type: contentType,
  });
};

export const touchChallengeContentTypeFilter = () => {
  setUserProperties();
  window.gtag('event', 'touch_challenge_content_type_filter');
};

export const touchChallengeContentType = ({
  contentType,
}: {
  contentType: 'all' | 'interactive' | 'novel';
}) => {
  setUserProperties();
  window.gtag('event', 'touch_challenge_content_type', {
    content_type: contentType,
  });
};

export const viewMoreTab = () => {
  setUserProperties();
  window.gtag('event', 'view_more_tab');
};

export const viewNotice = () => {
  setUserProperties();
  window.gtag('event', 'view_notice');
};

export const viewNoticeDetail = ({ noticeId }: { noticeId: string }) => {
  setUserProperties();
  window.gtag('event', 'view_notice_detail', {
    notice_number: noticeId,
  });
};

export const touchInteractiveSameStory = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: number;
  chapterIndex?: number;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_interactive_same_story', {
    story: storyName,
    story_id: storyId,
    chapter_id: chapterId,
    chapter: chapterIndex,
  });
};

export const touchNovelSameStory = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: number;
  chapterIndex?: number;
}) => {
  setUserProperties();
  window.gtag('event', 'touch_novel_same_story', {
    story: storyName,
    story_id: storyId,
    chapter_id: chapterId,
    chapter: chapterIndex,
  });
};

export const touchViewerEditor = () => {
  setUserProperties();
  window.gtag('event', 'touch_viewer_editor');
};

export const touchViewerEditorSetting = (title: string) => {
  setUserProperties();
  window.gtag('event', 'touch_viewer_editor_setting', title);
};

export const touchStartChapter = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  fullCoin,
  coin,
  lastPreviewChapterIndex,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: number;
  chapterIndex?: number;
  fullCoin?: number;
  coin?: number;
  lastPreviewChapterIndex?: number | null;
  contentType: 'interactive' | 'novel';
}) => {
  setUserProperties();
  window.gtag('event', 'touch_start_chapter', {
    story: storyName,
    story_id: storyId,
    chapter_id: chapterId,
    chapter: chapterIndex,
    full_coin: fullCoin,
    coin,
    lastPreviewChapterIndex,
    content_type: contentType,
  });
};

export const viewChapterEnding = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  endingId,
  endingTitle,
  storyType,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: string;
  chapterIndex?: number;
  endingId?: number | null;
  endingTitle?: string | null;
  storyType?: 'home' | 'challenge';
  contentType: 'interactive' | 'novel';
}) => {
  setUserProperties();
  window.gtag('event', 'view_chapter_ending', {
    story: storyName,
    story_id: storyId,
    chapter_id: chapterId,
    chapter: chapterIndex,
    ...(endingId && { ending_id: endingId }),
    ...(endingTitle && { ending_title: endingTitle }),
    story_type: storyType,
    content_type: contentType,
  });
};

export const viewChapterEndingFirst = ({
  storyName,
  storyId,
  chapterId,
  chapterIndex,
  storyType,
  contentType,
}: {
  storyName?: string;
  storyId?: string;
  chapterId?: number;
  chapterIndex?: number;
  storyType?: 'home' | 'challenge';
  contentType: 'interactive' | 'novel';
}) => {
  setUserProperties();
  window.gtag('event', 'view_chapter_ending_first', {
    story: storyName,
    story_id: storyId,
    chapter_id: chapterId,
    chapter: chapterIndex,
    story_type: storyType,
    content_type: contentType,
  });
};

export const viewSignUpSuccess = () => {
  setUserProperties();
  window.gtag('event', 'view_sign_up_success');
};

export const viewEmailVerificationRequest = () => {
  setUserProperties();
  window.gtag('event', 'view_email_verification_request');
};

export const successEmailVerification = () => {
  setUserProperties();
  window.gtag('event', 'success_email_verification');
};

export const viewHomeContinueBanner = () => {
  setUserProperties();
  window.gtag('event', 'view_home_continue_banner');
};

export const touchHomeContinueBanner = () => {
  setUserProperties();
  window.gtag('event', 'touch_home_continue_banner');
};

export const touchHomeContinueBannerOff = () => {
  setUserProperties();
  window.gtag('event', 'touch_home_continue_banner_off');
};

export const viewHomeTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_home_top_banner', {
    banner_id: bannerId,
    banner_type: bannerType,
  });
};

export const clickHomeTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  setUserProperties();
  window.gtag('event', 'click_home_top_banner', {
    banner_id: bannerId,
    banner_type: bannerType,
  });
};

export const viewInteractiveTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_interactive_top_banner', {
    banner_id: bannerId,
    banner_type: bannerType,
  });
};

export const clickInteractiveTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  setUserProperties();
  window.gtag('event', 'click_interactive_top_banner', {
    banner_id: bannerId,
    banner_type: bannerType,
  });
};

export const viewNovelTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  setUserProperties();
  window.gtag('event', 'view_novel_top_banner', {
    banner_id: bannerId,
    banner_type: bannerType,
  });
};

export const clickNovelTopBanner = ({
  bannerId,
  bannerType,
}: {
  bannerId: number;
  bannerType: string;
}) => {
  setUserProperties();
  window.gtag('event', 'click_novel_top_banner', {
    banner_id: bannerId,
    banner_type: bannerType,
  });
};

export const touchInteractiveMoreChallenge = () => {
  window.gtag('event', 'touch_interactive_more_challenge');
};

export const touchNovelMoreChallenge = () => {
  window.gtag('event', 'touch_novel_more_challenge');
};

export const touchCtaEndingReport = ({
  storyId,
  storyName,
}: {
  storyId: string;
  storyName: string;
}) => {
  window.gtag('event', 'touch_cta_ending_report', {
    story_id: storyId,
    story: storyName,
  });
};

export const viewTermsAgreement = () => {
  window.gtag('event', 'view_terms_agreement');
};

export const viewWebToAppPopup = () => {
  window.gtag('event', 'view_web_to_app_popup');
};

export const clickWebToAppPopup = () => {
  window.gtag('event', 'click_web_to_app_popup');
};

export const viewStorygameThumbnail = ({
  sectionName,
  sectionIndex,
  storyName,
  storyId,
  storyBundleId,
}: {
  sectionName: string;
  sectionIndex: number;
  storyName: string;
  storyId: string;
  storyBundleId: string;
}) => {
  window.gtag('event', 'view_storygame_thumbnail', {
    section_name: sectionName,
    section_index: sectionIndex,
    story_name: storyName,
    story_id: storyId,
    story_bundle_id: storyBundleId,
    play_type: 'storygame',
  });
};

export const touchStorygameThumbnail = ({
  sectionName,
  sectionIndex,
  storyName,
  storyId,
  storyBundleId,
}: {
  sectionName: string;
  sectionIndex: number;
  storyName: string;
  storyId: string;
  storyBundleId: string;
}) => {
  window.gtag('event', 'touch_storygame_thumbnail', {
    section_name: sectionName,
    section_index: sectionIndex,
    story_name: storyName,
    story_id: storyId,
    story_bundle_id: storyBundleId,
    play_type: 'storygame',
  });
};

export const viewSubTab = ({
  prevTab,
  tab,
  index,
}: {
  prevTab: string;
  tab: string;
  index: number;
}) => {
  window.gtag('event', 'view_sub_tab', {
    prev_tab: prevTab,
    tab,
    index,
    main_tab: 'storygame',
  });
};

export const viewStorygameTab = () => {
  window.gtag('event', 'view_storygame_tab');
};

export const viewProfileTab = () => {
  window.gtag('event', 'view_profile_tab');
};

export const createCustomCharacterComplete = ({
  storyId,
  storyBundleId,
  storyName,
  storyLanguage,
  storyGenre,
  playType,
  payType,
}: {
  storyId: string;
  storyBundleId: string;
  storyName: string;
  storyLanguage: string;
  storyGenre: string;
  playType: ContentType;
  payType: 'free' | 'ad' | 'coin';
}) => {
  window.gtag('event', 'create_custom_character_complete', {
    story_id: storyId,
    story_bundle_id: storyBundleId,
    story_name: storyName,
    story_language: storyLanguage,
    story_genre: storyGenre,
    play_type: playType,
    pay_type: payType,
  });
};
