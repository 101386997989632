import { gql } from '@apollo/client';

export const USER_DTO = gql`
  fragment UserDto on User {
    createdAt
    updatedAt
    email
    unread
    userId
    name
    verifiedAt
    isPushInfoRequired
    pushEnabledAt
    nightPushEnabledAt
    marketingPushEnabledAt
    currentCoin
    isAnonymous
    certificatedAt
    isExpiredCertification
    ssoLogins {
      ssoProvider
    }
    countryCode
    isPremium
    isTester
    birthDateToken
    totalCoinUsed
    registeredAt
    systemLanguageCode
    newTermsToAccept {
      TOS {
        termsId
        link
      }
      CollectionAndUseOfPrivacy {
        termsId
        link
      }
    }
  }
`;
