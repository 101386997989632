import React from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { onClose } from '@slices/alertSlice';
import { RootState } from '@store/store';

const Alert = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { message, type } = useAppSelector((state: RootState) => state.alert);

  return (
    <>
      <BackDrop />
      <Container>
        <div className={`modal-wrapper`}>
          <h1 className="title">{t('common_popup_title_alert')}</h1>
          <div className="message">{message}</div>
          <div
            className={`button ${type}`}
            onClick={() => {
              dispatch(onClose());
            }}
          >
            {t('common_screen_title_confirm')}
          </div>
        </div>
      </Container>
    </>
  );
};

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100%;
  background: #000000;
  opacity: 0.6;
  z-index: 20;
`;

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1.5em;
  background: ${({ theme }) => theme.colors.white};
  z-index: 29;
  border-radius: 0.75em;
  max-width: 20.9375em;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.devices.mobile}) {
    width: 100%;
  }

  .modal-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    .title {
      font: ${({ theme }) => theme.font.title1};
      color: ${({ theme }) => theme.colors.gray900};
    }

    .message {
      /* Body/Body 2 [14px] */

      font-family: 'Apple SD Gothic Neo';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.3px;

      /* GRAY/700 */

      color: #57595e;
    }

    .title.center {
      text-align: center;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 240px;
      height: 52px;
      background: ${({ theme }) => theme.colors.danger600};
      border-radius: 8px;
      font-size: 16px;
      color: ${({ theme }) => theme.colors.white};
      cursor: pointer;
    }

    .button.info {
      background: ${({ theme }) => theme.colors.primary500};
    }
  }

  .modal-wrapper.center {
    text-align: center;
  }
`;

export default Alert;
