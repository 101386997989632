// 스토리게임 웹뷰 통신 API 문서: https://www.notion.so/thingsflow/API-fc91ec66006b44aa8f9bed8066da5aef?pvs=4
import {
  WebviewActionTypes,
  goLoginParams,
  goPageParams,
  goStoreParams,
  goChapterParams,
  goAdultCertParams,
  goCommentsParams,
  goStoryGameDetailsParams,
  goInteractiveDetailsParams,
  showToastParams,
  setSoundEffectParams,
  onShareParams,
  onPurchaseParams,
  onErrorParams,
  logEventParams,
  logEventTypeWithJsonString,
  goNoticeParams,
  showRewardAdParams,
} from '@/customTypes/webview';

const sendEvent = ({
  action,
  parameter,
}: {
  action: WebviewActionTypes;
  parameter?:
    | goLoginParams
    | goPageParams
    | goStoreParams
    | goChapterParams
    | goAdultCertParams
    | goCommentsParams
    | goInteractiveDetailsParams
    | goStoryGameDetailsParams
    | showToastParams
    | setSoundEffectParams
    | onShareParams
    | onPurchaseParams
    | onErrorParams
    | logEventTypeWithJsonString
    | goNoticeParams
    | showRewardAdParams;
}) => {
  const platform = localStorage.getItem('platform');

  if (platform === 'ios') {
    if (window?.webkit?.messageHandlers?.spWeb?.postMessage) {
      window.webkit.messageHandlers.spWeb.postMessage({
        action,
        ...(parameter && { parameter }),
      });
      return;
    }
  }

  if (platform === 'android') {
    if (window?.androidStoryplayWebViewApi?.spWeb) {
      window.androidStoryplayWebViewApi.spWeb(
        JSON.stringify({
          action,
          ...(parameter && { parameter }),
        }),
      );
      return;
    }
  }
};

export function goBackForApp() {
  sendEvent({ action: 'goBack' });
}

export function goHomeForApp() {
  sendEvent({ action: 'goHome' });
}

export const goLoginForApp = (parameter?: goLoginParams) => {
  sendEvent({ action: 'goLogin', parameter });
};

export const goPageForApp = (parameter: goPageParams) => {
  sendEvent({ action: 'goPage', parameter });
};

export const goExternalPageForApp = (parameter: goPageParams) => {
  sendEvent({ action: 'goExternalPage', parameter });
};

export const goSearchForApp = () => {
  sendEvent({ action: 'goSearch' });
};

export const goStoreForApp = (parameter?: goStoreParams) => {
  sendEvent({ action: 'goStore', parameter });
};

export const goNotificationsForApp = () => {
  sendEvent({ action: 'goNotifications' });
};

export const goChapterForApp = (parameter: goChapterParams) => {
  sendEvent({
    action: 'goChapter',
    parameter,
  });
};

export const goAdultCertForApp = (parameter: goAdultCertParams) => {
  sendEvent({ action: 'goAdultCert', parameter });
};

export const goCommentsForApp = (parameter: goCommentsParams) => {
  sendEvent({
    action: 'goComments',
    parameter: parameter,
  });
};

export const goStoryGameDetailsForApp = (
  parameter: goStoryGameDetailsParams,
) => {
  sendEvent({ action: 'goStoryGameDetails', parameter });
};

export const goInteractiveDetailsForApp = (
  parameter: goInteractiveDetailsParams,
) => {
  sendEvent({ action: 'goInteractiveDetails', parameter });
};

export const goFavoriteForApp = () => {
  sendEvent({ action: 'goFavorite' });
};

export const showToastForApp = (message: string) => {
  sendEvent({ action: 'showToast', parameter: { message } });
};

export const setSoundEffectForApp = (parameter: setSoundEffectParams) => {
  sendEvent({ action: 'setSoundEffect', parameter });
};

export const onShareForApp = (parameter: onShareParams) => {
  sendEvent({ action: 'onShare', parameter });
};

export const onVibrateForApp = () => {
  sendEvent({ action: 'onVibrate' });
};

export const onPurchaseForApp = (parameter: onPurchaseParams) => {
  sendEvent({ action: 'onPurchase', parameter });
};

export const onErrorForApp = (parameter: onErrorParams) => {
  sendEvent({ action: 'onError', parameter });
};

export const onLogEventForApp = (name: string, params?: logEventParams) => {
  sendEvent({
    action: 'onLogEvent',
    parameter: {
      name,
      params: params && JSON.stringify(params).replace(/\\/g, ''),
    },
  });
};

export const updateUserForApp = () => {
  sendEvent({ action: 'updateUser' });
};

export const goNoticeForApp = (parameter?: goNoticeParams) => {
  sendEvent({ action: 'goNotice', parameter });
};

export const showRewardAdForApp = (parameter: showRewardAdParams) => {
  sendEvent({ action: 'showRewardAd', parameter });
};
