import { isBefore } from 'date-fns';

import {
  PlayChapterInfo,
  PlayChapter,
  PlayStory,
  LastPlay,
  OtherCharacterInfo,
  PlayingCharacters,
  ListAchievements,
  LoadBlockStatementResult,
} from '@customTypes/chapter';
import { StoryMeta, PlayedEnding, BadgesAcquired } from '@customTypes/story';
import { STORAGE_KEY } from '@common/values';
import {
  isEmptyString,
  convertCharName,
  hasBGMStatement,
  hasTTSStatement,
  onTrimAll,
  safeJSONParse,
} from '@common/utils';
import {
  ChatBlock,
  ChatPlayableType,
  ChatScript,
  ChoiceIfSaveProp,
  ChoiceIfUpdateItem,
  ChoiceOption,
  ConditionType,
  LoadChapterData,
  MakeChoiceData,
  OperationType,
  SaveProp,
  SelectedChoice,
  Statement,
  StatementItem,
  TextEffect,
  TextEffectStyle,
  TextEffectStyleType,
} from '@/customTypes/chatStory';
import {
  ChapterPaymentStatus,
  UserPurchasedChapter,
} from '@customTypes/webNovelChapter';
import { calculate } from '@lib/playerData';

const onReadCurrentPlay = (storyId: number) => {
  const currentPlay = localStorage.getItem(
    `${STORAGE_KEY.CURRENT_PLAY}${storyId}`,
  );

  const result: CurrentPlayInfo | undefined = currentPlay
    ? JSON.parse(currentPlay)
    : undefined;
  return result;
};

const getCurrentPlayData = (
  storyId: number,
  lastPlay: LastPlay | undefined,
  isRestarting: boolean,
): CurrentPlayInfo | undefined => {
  if (lastPlay) {
    const { currentPlayingChapter } = lastPlay;
    const currentLocal = isRestarting ? undefined : onReadCurrentPlay(storyId);
    const storyData: CurrentPlayInfo = {
      storyId: storyId,
      chapterId: currentPlayingChapter.chapterId,
      chapterIndex: currentPlayingChapter.chapter.chapterIndex,
      lastSourceIndex: currentLocal?.lastSourceIndex || -1,
      lastSourceLine: currentLocal?.lastSourceLine ?? -1,
      lastBlockName: currentLocal?.lastBlockName ?? '',
    };

    return currentPlayingChapter ? storyData : undefined;
  } else {
    return undefined;
  }
};

const getChatBlocks = (
  scripts: string,
): {
  blocks: ChatBlock[];
  startingBlock: string;
} => {
  const scriptList: ChatScript = JSON.parse(scripts);
  const blocks = Object.values(scriptList?.blocks);
  const startingIndex = blocks.findIndex(
    (item) => item.name === scriptList.startingBlock,
  );
  const replaceItems = blocks.splice(0, startingIndex);
  blocks.splice(1, 0, ...replaceItems);

  return { blocks, startingBlock: scriptList.startingBlock };
};

const conditionEndingParams = ({
  endings,
  conditionType,
  conditionParam,
}: {
  endings: PlayedEnding[];
  conditionType: ConditionType;
  conditionParam: string;
}) => {
  if (endings.length < 1) return true;
  // true를 리턴하면 해당 블럭으로 이동한다.

  const lastEnding = endings.find((ending) => ending.isPrevChapterEnding);
  if (!lastEnding) {
    return true;
  }

  switch (conditionType) {
    case 'PreviousEndingEqualsTo': // 마지막 엔징정보와 동일한 결과
      return lastEnding.name === conditionParam;
    case 'PreviousEndingNotEqualsTo': // 마지막 엔징정보와 다른 결과
      return lastEnding.name !== conditionParam;
    default:
      return false;
  }
};

const conditionPropertyParams = ({
  currentProps,
  conditionType,
  conditionNumberParam,
  conditionStringParam,
  conditionParam,
}: {
  currentProps: Record<string, number | string>;
  conditionType: ConditionType;
  conditionNumberParam: number;
  conditionStringParam: string;
  conditionParam: string;
}) => {
  // true를 리턴하면 해당 블럭으로 이동한다.
  const currentPropsParams = currentProps[conditionParam];
  const param =
    conditionNumberParam === -1 ? conditionStringParam : conditionNumberParam;

  switch (conditionType) {
    case 'PropertyEqualsTo': // Property와 같은 결과
      return currentPropsParams === param;
    case 'PropertyNotEqualsTo': // Property와 다른 결과
      return currentPropsParams !== param;
    case 'PropertyGTE': // Property 기준 큰 값
      return currentPropsParams >= param;
    case 'PropertyLTE': // Property 기준 작은 값
      return currentPropsParams <= param;
    default:
      return false;
  }
};

const conditionItemParams = ({
  currentItems,
  conditionType,
  conditionNumberParam,
  conditionParam,
}: {
  currentItems: Record<string, number | string>;
  conditionType: ConditionType;
  conditionNumberParam: number;
  conditionParam: string;
}) => {
  // true를 리턴하면 해당 블럭으로 이동한다.
  const itemsParams = Number(currentItems[conditionParam]);
  const param = conditionNumberParam;

  switch (conditionType) {
    case 'NumOfStoryItemEqualsTo': // 아이템 과 동일한 값
      return itemsParams === param;
    case 'NumOfStoryItemNotEqualsTo': // 아이템 과 다른 값
      return itemsParams !== param;
    case 'NumOfStoryItemGTE': // 아이템 기준 큰 값
      return itemsParams >= param;
    case 'NumOfStoryItemLTE': // 아이템 기준 작은 값
      return itemsParams <= param;
    default:
      return false;
  }
};

const onConditionPlayData = ({
  playData,
  conditionParam,
}: {
  playData: string;
  conditionParam: string;
}) => {
  const result = calculate(conditionParam, playData);
  return Boolean(Number(result));
};

/**
 * @name getUpdatedItems
 * @description 업데이트 된 아이템 계산
 * @param {any} currentItems 지금 나의 아이템 (Objet)
 * @param {any} newItems 새로 계산될 아이템
 * @param {any} playData 플레이 데이터 정보
 * @return newMergeItems merge된 item값
 * @return updateItemInfo 업데이트된 아이템이름 & 업데이트 완료된 전체 아이템정보, 서버전달용
 */
export const getUpdatedItems = ({
  currentItems,
  newItems,
  playData,
}: {
  currentItems: Record<string, number | string>;
  newItems: {
    itemName: string;
    itemOp: OperationType;
    itemCount: string;
  };
  playData: string;
}): {
  newMergeItems: Record<string, number | string>;
  updateItemCount?: number;
  updateItemName?: string;
} => {
  const items = { ...currentItems };
  const currentValue = items[newItems.itemName];

  if (currentValue !== undefined) {
    const itemValue =
      newItems.itemOp === 'INCREASE_NUMBER'
        ? (Number(items[newItems.itemName]) || 0) + Number(newItems.itemCount)
        : newItems.itemOp === 'CALCULATE'
        ? Number(calculate(newItems.itemCount, playData))
        : // : newItems.itemOp === 'SET_TEXT'
          // ? items // TODO:
          Number(newItems.itemCount);

    const copyItems = { ...items };
    copyItems[newItems.itemName] = itemValue;

    return {
      newMergeItems: copyItems,
      updateItemCount: itemValue - Number(currentValue),
      updateItemName: newItems.itemName,
    };
  } else {
    const updateItemCount =
      newItems.itemOp === 'CALCULATE'
        ? Number(calculate(newItems.itemCount, playData))
        : Number(newItems.itemCount);
    const newItem = JSON.parse(`{"${newItems.itemName}": ${updateItemCount}}`);

    return {
      newMergeItems: {
        ...currentItems,
        ...newItem,
      },
      updateItemCount: updateItemCount,
      updateItemName: newItems.itemName,
    };
  }
};

/**
 * @name onMergeProps
 * @description props object 값 계산
 * @param {Record<string, number | string>} currentProps 지금 나의 속성
 * @param {SaveProp} props 새로 계산될 속성
 * @param {string} playData 새로 계산될 속성
 */
export const onMergeProps = (
  currentProps: Record<string, number | string>,
  newProps: SaveProp,
  playData: string,
) => {
  const props = { ...currentProps };
  const currentValue = currentProps[newProps.propName];
  if (currentValue !== undefined) {
    const prevProp =
      newProps.propOp === 'INCREASE_NUMBER'
        ? (Number(props[newProps.propName]) || 0) + Number(newProps.value)
        : newProps.propOp === 'CALCULATE'
        ? Number(calculate(newProps.value, playData))
        : newProps.propOp === 'SET_TEXT'
        ? newProps.value
        : Number(newProps.value);

    const copyProps = { ...props };
    copyProps[newProps.propName] = prevProp;

    return copyProps;
  } else {
    return props;
  }
};

/**
 * @name handleCondition
 * @description handleCondition 타입 조건 처리
 * @param statement
 * @param endings
 * @param currentProps
 * @param currentItems
 * @returns
 */
export const handleCondition = ({
  condition,
  endings,
  currentProps,
  currentItems,
  playData,
}: {
  condition: Statement | StatementItem;
  endings: PlayedEnding[];
  currentProps?: Record<string, number | string>; // parse 변환하여 전달
  currentItems?: Record<string, number | string>; // parse 변환하여 전달
  playData: string;
}) => {
  let isChange = false;
  if (condition.conditionAction === 'MoveToBlock') {
    if (condition?.conditionType?.startsWith('PreviousEnding')) {
      isChange = conditionEndingParams({
        endings: endings,
        conditionType: condition?.conditionType,
        conditionParam: condition?.conditionParam || '',
      });
    } else if (condition?.conditionType?.startsWith('Property')) {
      isChange = conditionPropertyParams({
        currentProps: currentProps || {},
        conditionType: condition?.conditionType,
        conditionNumberParam: condition?.conditionNumberParam || 0,
        conditionStringParam: condition?.conditionStringParam ?? '',
        conditionParam: condition?.conditionParam || '',
      });
    } else if (condition?.conditionType?.startsWith('NumOfStoryItem')) {
      isChange = conditionItemParams({
        currentItems: currentItems || {},
        conditionType: condition?.conditionType,
        conditionNumberParam: condition?.conditionNumberParam || 0,
        conditionParam: condition?.conditionParam || '',
      });
    } else if (condition?.conditionType === 'PlayDataExpr') {
      isChange = onConditionPlayData({
        playData,
        conditionParam: condition?.conditionParam || '',
      });
    }
  }

  return {
    isChange: isChange,
    newBlockName: isChange ? condition.conditionActionParam : undefined,
  };
};

/**
 * @name isShowStatement
 * @description 스크립트 정보중에 화면에 표시되는 type 구분
 * @param statement statement
 * @returns 화면에 표시 여부
 */
export const isShowStatement = (statement: Statement) => {
  switch (statement?.statementType) {
    case 'MainCharacterTalk':
    case 'MainCharacterThink':
    case 'CharacterThink':
    case 'CharacterTalk':
    case 'Script':
    case 'FullWidthImage':
    case 'MainCharacterMessageImage':
    case 'MessageImage':
    case 'Place':
    case 'Time':
    case 'FullWidthText':
    case 'ChoiceInput':
    case 'CallRemoteScript':
      return true;
    default:
      return false;
  }
};

export const isMessageStatement = (statement: Statement) => {
  switch (statement?.statementType) {
    case 'MainCharacterTalk':
    case 'MainCharacterThink':
    case 'CharacterThink':
    case 'CharacterTalk':
    case 'Script':
    case 'FullWidthText':
      return true;
    default:
      return false;
  }
};

export const isLogStatement = (statement: Statement) => {
  if (isMessageStatement(statement)) {
    return true;
  }
  switch (statement?.statementType) {
    case 'FullWidthImage':
    case 'MainCharacterMessageImage':
    case 'MessageImage':
      return true;
    default:
      return false;
  }
};

export const isStoryGameNameStatement = (statement?: Statement) => {
  switch (statement?.statementType) {
    case 'MainCharacterTalk':
    case 'MainCharacterThink':
    case 'MainCharacterMessageImage':
    case 'CharacterThink':
    case 'CharacterTalk':
    case 'MessageImage':
      return true;
    default:
      return false;
  }
};

export const isStoryGameMessageStatement = (statement?: Statement) => {
  switch (statement?.statementType) {
    case 'MainCharacterTalk':
    case 'MainCharacterThink':
    case 'CharacterThink':
    case 'CharacterTalk':
      return true;
    default:
      return false;
  }
};

const isPostPosition = (lastWord: string) => {
  // 받침이 있으면 0번, 없으면 1번 으로 선택
  const unicode = lastWord.charCodeAt(0);
  if (unicode < 44032 || unicode > 55203) return 1;
  return (unicode - 44032) % 28 != 0 ? 0 : 1;
};

/**
 * @name onReplacePostPosition
 * @description 조사처리에 사용 예) [[을/를]], [[이/-]]
 * @param message
 * @returns
 */
export const onReplacePostPosition = (message: string): string => {
  // eslint-disable-next-line no-useless-escape
  const postRegexp = /\[\[(\W{1,3}|\-)\/(\W{1,3}|\-)\]\]/gm;
  const prevWordRegexp = /(.)(?:\[\[)/gm;

  const isCheck = postRegexp.test(message);
  if (!isCheck) return message;

  // 조사 구분 앞 단어
  const postIndexList = message
    .match(prevWordRegexp)
    ?.map((item) => item.replace('[[', ''))
    .map((item) => {
      return isPostPosition(item);
    });

  const postString = message.replaceAll(postRegexp, (substring: string) => {
    const word = substring
      .replace('[[', '')
      .replace(']]', '')
      .split('/')
      .map((item) => (item === '-' ? '' : item));
    return word[postIndexList?.shift() || 0];
  });
  return postString;
};

/**
 * @name onSaveStoragePlayInfo
 * @description 플레이중인 스토리 저장
 * @param storyId
 * @param data
 */
export const onSaveStoragePlayInfo = (
  storyId: number,
  data: CurrentPlayInfo | undefined,
) => {
  if (data) {
    localStorage.setItem(
      `${STORAGE_KEY.CURRENT_PLAY}${storyId}`,
      JSON.stringify(data),
    );
  } else {
    localStorage.removeItem(`${STORAGE_KEY.CURRENT_PLAY}${storyId}`);
  }
};

/**
 * @name getChapterInfo
 * @description 플레이에 사용될 정보 초기화
 * @param {PlayChapter} chapterData
 * @param {boolean} isRestarting
 * @returns 챕터 플레이 정보
 */
export const getChapterInfo = (
  chapterData: PlayChapter,
  isRestarting: boolean,
  achievements?: ListAchievements,
) => {
  const FLOATING_STORY_ID = [54, 137, 64, 2943];

  const chapter = chapterData;
  const playStory: PlayStory = chapter.story;
  const lastPlay = playStory.lastPlay;
  const lastName = lastPlay?.lastName || playStory?.defaultLastName;
  const firstName = lastPlay?.firstName || playStory?.defaultFirstName;
  const currentPlayInfo = getCurrentPlayData(
    chapter.storyId,
    lastPlay,
    isRestarting,
  );

  const mainCharacterName = {
    firstName,
    lastName,
  };

  const { blocks, startingBlock } = getChatBlocks(chapter.chapterScript);
  const playedEndings = lastPlay?.playedChapters
    ?.map((item) => {
      return (
        item.ending && {
          ...item.ending,
          chapterIndex: item.chapter.chapterIndex,
          isPrevChapterEnding:
            item.chapter.chapterIndex === chapter.chapterIndex - 1,
        }
      );
    })
    .filter((item) => !!item);

  const characters =
    chapter?.story?.characters?.map((item) => {
      const info: OtherCharacterInfo = {
        originName: item.name,
        customFirstName: item.defaultFirstName || item.name,
        customLastName: item.defaultLastName,
        profileImageFileId: item.imageFileId,
        displayName: item.displayName,
      };
      return info;
    }) || [];
  const playingCharacters =
    chapter.story.lastPlay?.currentPlayingChapter.playingCharacters &&
    chapter.story.lastPlay.currentPlayingChapter.playingCharacters.length > 0
      ? chapter?.story?.lastPlay?.currentPlayingChapter?.playingCharacters?.map(
          (item) => {
            const info: OtherCharacterInfo = {
              originName: item.character?.name,
              customFirstName: item.firstName,
              customLastName: item.lastName,
              profileImageFileId: item.profileImageFileId,
              profileImageFile: item.profileImageFile,
              displayName: undefined,
            };
            return info;
          },
        )
      : [];
  const otherCharacters: OtherCharacterInfo[] = characters.map((item) => {
    const findItem = playingCharacters.find(
      (character) => character.originName === item.originName,
    );
    return findItem || item;
  });

  const hasBGM = hasBGMStatement(chapter.chapterScript);
  const hasTTS = hasTTSStatement(chapter.chapterScript);
  const acquiredAchievements = getBadgesToAchievement(achievements?.list);

  const properties = chapter.story.properties?.map((prop) => {
    const discoveredUserProps = Object.keys(
      JSON.parse(chapter.story.discoveredUserProps),
    );

    return {
      ...prop,
      isDiscovered:
        discoveredUserProps.findIndex((key) => key === prop.propNameRaw) !== -1,
    };
  });

  const chatInfo: PlayChapterInfo = {
    playChapter: chapter,
    currentPlayInfo: currentPlayInfo,
    chatBlocks: blocks || [],
    playedEndings: playedEndings || [],
    onboardingPopups: chapter.onboardingPopups,
    userProps: lastPlay?.currentPlayingChapter?.currentProps
      ? JSON.parse(lastPlay?.currentPlayingChapter?.currentProps)
      : {},
    userItems: lastPlay?.currentPlayingChapter?.currentItems
      ? JSON.parse(lastPlay?.currentPlayingChapter?.currentItems)
      : {},
    mainCharacterName,
    selectedChoices:
      chapter.story.lastPlay?.currentPlayingChapter.selectedChoices || [],
    startingBlock,
    playingOtherCharacters: otherCharacters,
    hasBGM,
    hasTTS,
    chapterOption: chapter.chapterOption,
    achievements: acquiredAchievements,
    achievementData: achievements,
    upcId: lastPlay?.currentPlayingChapter.upcId || -1,
    purchasable: chapter.story.purchasable,
    purchased: chapter.story.purchased,
    isFloatingADStory: FLOATING_STORY_ID.includes(chapter.storyId),
    properties: properties || [],
    items: chapter.story.storyItems,
    endingInfo: chapter.story.endingInfo,
  };

  return chatInfo;
};

/**
 * @name isPlayableChapter
 * @description 플레이 가능한 챕터인지 검토
 * @param playChapterInfo 챕터 플레이 정보
 * @returns info: 정보오류, end: 플레이완료, index:순서오류, play:플레이가능
 */
export const isPlayableChapter = (
  playChapterInfo: PlayChapterInfo,
): ChatPlayableType => {
  const chapterInfo = playChapterInfo?.playChapter;
  if (!chapterInfo) return 'info';

  const playStory: PlayStory = chapterInfo.story;
  const currentPlay = playChapterInfo.currentPlayInfo;
  const thisChapter = playStory?.chapters.find(
    (item) => item.chapterId === chapterInfo.chapterId,
  );
  if (!thisChapter) return 'info'; // 진행하려는 챕터ID가스토리 내에 없는경우

  const endingChapterIds = playChapterInfo?.playedEndings?.map(
    (item) => item.chapterId,
  );

  if (
    endingChapterIds.find((chapterId) => chapterId === thisChapter.chapterId)
  ) {
    // 진행하려는 챕처가 엔딩리스트에 있는경우
    return 'end';
  }

  if (currentPlay && currentPlay?.chapterId !== thisChapter?.chapterId) {
    if (endingChapterIds) {
      const lastEndingChapterId = endingChapterIds[endingChapterIds.length - 1];
      const lastEndingChapter = playStory?.chapters.find(
        (item) => item.chapterId === lastEndingChapterId,
      );
      // 마지막 엔딩 획득한 챕터의 다음 챕터가 지금 실행하려는 챕터인지
      return lastEndingChapter
        ? lastEndingChapter?.chapterIndex + 1 === thisChapter.chapterIndex
          ? 'play'
          : 'index'
        : 'index';
    }
  }

  if (
    playChapterInfo.purchasable &&
    !playChapterInfo.purchased &&
    (!playStory.lastPreviewChapterIndex ||
      playStory.lastPreviewChapterIndex < chapterInfo.chapterIndex)
  ) {
    return 'payment';
  }

  // 최근 플레이 정보가 없고 1화가 아니면 예외처리
  if (!currentPlay && chapterInfo.chapterIndex !== 1) {
    return 'index';
  }

  return 'play';
};

/**
 * @name onSavePlayData
 * @description 채팅 플레이중인 데이터를 저장
 * @param playChapterInfo
 */
export const onSavePlayData = (
  playChapterInfo: PlayChapterInfo,
  playInfo: {
    blockName: string;
    progressIndex: number;
    choiceIndex: number;
    sourceLine: number;
  },
) => {
  const chapterInfo = playChapterInfo.playChapter;
  const currentPlayInfo = playChapterInfo.currentPlayInfo;
  const playBlock = playChapterInfo.chatBlocks.find(
    (item) => item.name === playInfo.blockName,
  );
  let lastIndex = 0;
  const lastItem = playBlock?.statements.find((item, index) => {
    lastIndex = index;
    return item.sourceLine === playInfo.sourceLine;
  });
  const isShowItem = lastItem ? isShowStatement(lastItem) : false;
  const data: CurrentPlayInfo = {
    storyId: chapterInfo.storyId,
    chapterId: chapterInfo.chapterId,
    lastSourceIndex: isShowItem
      ? playInfo.progressIndex
      : playInfo.progressIndex - 1 ?? 0,
    lastSourceLine: isShowItem
      ? playInfo.sourceLine ?? -1
      : playBlock?.statements?.[lastIndex - 1 || 0]?.sourceLine ?? -1,
    lastBlockName: playInfo.blockName,
    chapterIndex: currentPlayInfo?.chapterIndex ?? 0,
  };

  onSaveStoragePlayInfo(chapterInfo.storyId, data);
};

/**
 * @name updateLastPlay
 * @description 플레이에 사용될 정보 업데이트 (startStory 호출 시)
 * @param {PlayChapterInfo} chatInfo
 * @param {boolean} isRestarting
 * @returns 챕터 플레이 정보
 */
export const updateLastPlay = (
  chatInfo: PlayChapterInfo,
  lastPlay: LastPlay,
) => {
  const playChapter = {
    ...chatInfo.playChapter,
    story: {
      ...chatInfo.playChapter.story,
      lastPlay: lastPlay || chatInfo.playChapter.story.lastPlay,
    },
  };
  const lastName = lastPlay?.lastName || playChapter.story.defaultLastName;
  const firstName = lastPlay?.firstName || playChapter.story.defaultFirstName;
  const currentPlayInfo = getCurrentPlayData(
    playChapter.storyId,
    lastPlay,
    false,
  );

  const mainCharacterName = {
    firstName,
    lastName,
  };
  const blocks = chatInfo.chatBlocks;
  const playedEndings = lastPlay?.playedChapters
    ?.map(
      (item) =>
        item.ending && {
          ...item.ending,
          chapterIndex: item.chapter.chapterIndex,
          isPrevChapterEnding:
            item.chapter.chapterIndex === playChapter.chapterIndex - 1,
        },
    )
    .filter((item) => !!item);

  const playingCharacters =
    lastPlay.currentPlayingChapter.playingCharacters &&
    lastPlay.currentPlayingChapter.playingCharacters.length > 0
      ? lastPlay?.currentPlayingChapter?.playingCharacters.map((item) => {
          const info: OtherCharacterInfo = {
            originName: item.character?.name,
            customFirstName: item.firstName,
            customLastName: item.lastName,
            profileImageFileId: item.profileImageFileId,
            profileImageFile: item.profileImageFile,
            displayName: undefined,
          };
          return info;
        })
      : [];
  const otherCharacters: OtherCharacterInfo[] =
    chatInfo?.playingOtherCharacters?.map((item) => {
      const findItem = playingCharacters.find(
        (character) => character.originName === item.originName,
      );
      return findItem || item;
    });

  const playChat: PlayChapterInfo = {
    ...chatInfo,
    playChapter: playChapter,
    currentPlayInfo: currentPlayInfo,
    chatBlocks: blocks || [],
    playedEndings: playedEndings || [],
    userProps: lastPlay?.currentPlayingChapter?.currentProps
      ? JSON.parse(lastPlay?.currentPlayingChapter?.currentProps)
      : {},
    userItems: lastPlay?.currentPlayingChapter?.currentItems
      ? JSON.parse(lastPlay?.currentPlayingChapter?.currentItems)
      : {},
    mainCharacterName,
    selectedChoices: lastPlay?.currentPlayingChapter?.selectedChoices,
    playingOtherCharacters: otherCharacters,
    upcId: lastPlay.currentPlayingChapter.upcId,
  };

  return playChat;
};

/**
 * @name loadBlockStatement
 * @description Block 단위 이어보기 처리
 * @params 이어보기 관련 데이터
 * @returns 이어보기에 사용될 데이터
 */
const loadBlockStatement = ({
  currentBlock,
  mainCharacterName,
  otherCharacters,
  initProps,
  initItems,
  playData,
  userChoice,
  chatProgressInfo,
  storyId,
  chapterId,
  endings,
  lastStatement,
  lastPlay,
}: {
  currentBlock: ChatBlock;
  mainCharacterName?: {
    firstName: string;
    lastName?: string;
  };
  otherCharacters: OtherCharacterInfo[];
  initProps: any;
  initItems: any;
  playData: string;
  userChoice?: SelectedChoice;
  chatProgressInfo: {
    lastSourceIndex: number;
    lastSourceLine: number;
  };
  storyId: number;
  chapterId: number;
  endings: PlayedEnding[];
  lastStatement?: Statement;
  lastPlay?: LastPlay;
}): LoadBlockStatementResult => {
  let progressInfo = { ...chatProgressInfo };
  let loadList: StatementItem[] = [];
  let removeChoiceName: string | undefined = undefined;
  let props = { ...initProps };
  let items = { ...initItems };
  let nextBlockName: string | undefined = undefined;
  let fullScreenEffectInfo: StatementItem | undefined;

  let blockStatements = currentBlock.statements;
  if (lastStatement) {
    const addList = blockStatements.filter((item, index) => {
      if (item.sourceLine === lastStatement.sourceLine) {
        chatProgressInfo = {
          lastSourceIndex: index,
          lastSourceLine: item.sourceLine,
        };
      }
      return item.sourceLine <= lastStatement.sourceLine;
    });

    blockStatements = addList;
  }

  const choiceName = userChoice?.choice.name;
  const userChoiceIndex = userChoice ? userChoice?.selectedChoice - 1 : -1;

  blockStatements.every((originStatement: Statement, index: number) => {
    const isShow = isShowStatement(originStatement);
    const statementItem = setStatementCustomText({
      statement: originStatement,
      mainCharacterName,
      otherCharacters,
      props,
      items,
    });
    const statement = {
      ...statementItem,
      isShow,
    };
    const myPlayData = JSON.stringify({
      ...JSON.parse(playData),
      props: props,
      items: items,
    });

    if (
      !onExecuteCondition({
        statement,
        playData: myPlayData,
      })
    ) {
      return true;
    }

    progressInfo = {
      lastSourceIndex: index,
      lastSourceLine: statement.sourceLine,
    };
    loadList.push(statement);

    if (statement.statementType === 'Choice') {
      if (userChoice && choiceName === statement.choiceName) {
        if (statement.options?.layoutType === 'UserInputChoice') {
          const choiceItem: StatementItem | undefined = getUserInputChoiceItem({
            sourceLine: statement.sourceLine,
            background: statement.background,
            choiceOptions: statement.options,
            storyId: storyId,
            chapterId: chapterId,
            mainCharacterName: mainCharacterName,
            rightUserInputText: userChoice.rightUserInputText,
          });
          choiceItem && loadList.push(choiceItem);
          props = mergeChoiceToProps({
            props,
            choiceName: statement.choiceName,
            rightUserInputText: userChoice.rightUserInputText,
            choiceNumber: userChoice.selectedChoice,
          });
        } else {
          props = mergeChoiceToProps({
            props,
            choiceName: statement.choiceName,
            rightUserInputText: undefined,
            choiceNumber: userChoice.selectedChoice,
          });
        }

        if (
          statement.options?.layoutType === 'Statistics' ||
          statement.options?.showStatistics
        ) {
          const choiceItem = getChoiceRateItem({
            choiceId: userChoice.choiceId,
            storyId,
            chapterId,
          });
          choiceItem && loadList.push(choiceItem);
        }

        removeChoiceName = choiceName;
      } else {
        // 선택지가 없는데 choice 구문을 만난 경우. 이 구문은 실행하지 않는 것으로 판단하여 위에서 추가해준 현재 statement에서 제거한다. 일반적이지 않은 오류케이스.
        loadList.pop();
        progressInfo = {
          lastSourceIndex: loadList.length - 1,
          lastSourceLine: loadList[loadList.length - 1]?.sourceLine ?? -1,
        };
        return false;
      }
    } else if (statement.statementType === 'ChoiceIfSaveProp') {
      const choiceItem: ChoiceIfSaveProp = statement;
      if (statement.choice === userChoiceIndex) {
        props = onMergeProps(props, choiceItem.propUpdate, myPlayData);
      }
    } else if (statement.statementType === 'ChoiceIfUpdateItem') {
      const choiceItem: ChoiceIfUpdateItem = statement;
      if (choiceItem.choice === userChoiceIndex) {
        items = getUpdatedItems({
          currentItems: items,
          newItems: choiceItem.itemUpdate,
          playData: myPlayData,
        }).newMergeItems;
      }
    } else if (statement.statementType === 'ChoiceSaveProp') {
      if (statement.propChoices[userChoiceIndex]) {
        props = onMergeProps(
          props,
          statement.propChoices[userChoiceIndex],
          myPlayData,
        );
      }
    } else if (statement.statementType === 'Condition') {
      const { isChange, newBlockName } = handleCondition({
        condition: statement,
        endings,
        currentProps: props,
        currentItems: items,
        playData: myPlayData,
      });
      if (isChange && newBlockName) {
        nextBlockName = newBlockName;
        chatProgressInfo = {
          lastSourceIndex: -1,
          lastSourceLine: -1,
        };
        return false;
      }
    } else if (statement.statementType === 'SaveProp') {
      props = onMergeProps(props, statement.propUpdate, myPlayData);
    } else if (statement.statementType === 'UpdateItem') {
      items = getUpdatedItems({
        currentItems: items,
        newItems: statement.itemUpdate,
        playData: myPlayData,
      }).newMergeItems;
    } else if (statement.statementType === 'ChoiceToBlock') {
      nextBlockName = statement.choices[userChoiceIndex];
      chatProgressInfo = {
        lastSourceIndex: -1,
        lastSourceLine: -1,
      };
      return false;
    } else if (statement.statementType === 'ToBlock') {
      nextBlockName = statement.block;
      chatProgressInfo = {
        lastSourceIndex: -1,
        lastSourceLine: -1,
      };
      return false;
    } else if (statement.statementType === 'CallRemoteScript') {
      if (
        // 마지막 종료 시점이 remoteScriptType === 'ChatGPT' 이면
        lastStatement?.remoteScriptType === 'ChatGPT'
      ) {
        loadList = loadList.filter(
          (statement) =>
            statement.sourceLine !== chatProgressInfo.lastSourceLine,
        );
        progressInfo = {
          ...progressInfo,
          lastSourceIndex: chatProgressInfo.lastSourceIndex - 1,
        };
      }

      const remoteStatement = getRemoteStatements(
        statement,
        lastPlay?.currentPlayingChapter?.playingRemoteScripts,
      );

      if (remoteStatement) {
        const remoteBlock: ChatBlock = {
          isEndingBlock: false,
          name: statement.scriptId,
          statements: remoteStatement,
        };
        const result = loadBlockStatement({
          currentBlock: remoteBlock,
          mainCharacterName,
          otherCharacters,
          initProps: props,
          initItems: items,
          playData,
          userChoice,
          chatProgressInfo,
          storyId,
          chapterId,
          endings,
          lastPlay,
        });
        loadList.push(...result.loadList);
        props = result.props;
        items = result.items;
      }
    } else if (statement.statementType === 'FinishRemoteScript') {
      nextBlockName = statement.toBlockAfter;
      chatProgressInfo = {
        lastSourceIndex: -1,
        lastSourceLine: -1,
      };
      return false;
    } else if (statement.statementType === 'FullScreenEffectOn') {
      fullScreenEffectInfo = statement;
    } else if (statement.statementType === 'FullScreenEffectOff') {
      fullScreenEffectInfo = undefined;
    }

    return true;
  });

  return {
    progressInfo,
    loadList,
    removeChoiceName,
    props,
    items,
    nextBlockName,
    fullScreenEffectInfo,
  };
};

/**
 * @name onLoadStory
 * @description 채팅 이어보기 데이터 로드
 */
export const onLoadStory = ({
  endings,
  selectedChoices,
  currentPlayInfo,
  chatBlocks,
  mainCharacterName,
  otherCharacters = [],
  initProps,
  initItems,
  playData,
  lastPlay,
}: {
  endings: PlayedEnding[];
  selectedChoices: SelectedChoice[];
  currentPlayInfo: CurrentPlayInfo;
  chatBlocks: ChatBlock[];
  mainCharacterName?: {
    firstName: string;
    lastName?: string;
  };
  otherCharacters?: OtherCharacterInfo[];
  initProps: any;
  initItems: any;
  playData: string;
  lastPlay?: LastPlay;
}): LoadChapterData => {
  const loadList: StatementItem[] = [];
  const loadChatBlock: ChatBlock[] = [];
  let currentBlock: ChatBlock = chatBlocks[0];

  let props = { ...initProps };
  let items = { ...initItems };
  let selectedChoiceList: SelectedChoice[] = selectedChoices;
  let isFinish = false;
  let chatProgressInfo: {
    lastSourceIndex: number;
    lastSourceLine: number;
  } = {
    lastSourceIndex: currentPlayInfo.lastSourceIndex ?? -1,
    lastSourceLine: currentPlayInfo.lastSourceLine ?? -1,
  };
  let lastPlayedBlockFromStorage = chatBlocks.find(
    (block) => currentPlayInfo.lastBlockName === block.name,
  )?.name;
  let fullScreenEffectInfo: StatementItem | undefined;
  let tempResultList: LoadBlockStatementResult[] = [];

  do {
    // 선택 히스토리(서버 데이터) 우선 처리하도록 한다. 로컬에 기억해놓은 위치로의 이동은 그 이후.
    if (selectedChoiceList.length < 1) {
      if (
        currentBlock.name === lastPlayedBlockFromStorage ||
        tempResultList.length > 0
      ) {
        // 0. 로컬에 기억한 블럭이 존재하고 현재 블럭이 일치하는 경우
        // 0-0. 선택지가 아닌 방식으로 기억한 블럭 직전까지의 블럭까지 이동한 임시 저장한 결과가 있다면, 이 결과를 정식 이어보기 결과로 변환
        if (tempResultList.length > 0) {
          tempResultList.forEach((result, index) => {
            loadList.push(...result.loadList);
            if (index === tempResultList.length - 1) {
              props = result.props;
              items = result.items;
              currentBlock =
                chatBlocks.find((item) => item.name === result.nextBlockName) ??
                currentBlock;
              chatProgressInfo = result.progressInfo;
            }
          });
        }

        // 0-1. 저장된 값과 일치하는 Statement가 있는지 판단. 있으면 그 위치까지 이동하기 위함
        const findSourceLine = currentBlock.statements.find((item) => {
          return item.sourceLine === currentPlayInfo?.lastSourceLine;
        });
        if (findSourceLine) {
          // 0-2. findSourceLine 까지 이동하고 저장한뒤 종료
          const result = loadBlockStatement({
            currentBlock,
            mainCharacterName,
            otherCharacters,
            initProps: props,
            initItems: items,
            playData,
            userChoice: undefined,
            chatProgressInfo,
            storyId: currentPlayInfo.storyId,
            chapterId: currentPlayInfo.chapterId,
            endings,
            lastStatement: findSourceLine,
            lastPlay,
          });
          fullScreenEffectInfo = result.fullScreenEffectInfo;
          loadList.push(...result.loadList);
          chatProgressInfo = result.progressInfo;
          props = result.props;
          items = result.items;
        } else {
          // 0-3. findSourceLine 이 없는 경우, 마지막 이동한 블럭의 최초 위치로 지정
          chatProgressInfo = {
            lastSourceIndex: -1,
            lastSourceLine: -1,
          };
        }
        isFinish = true;
      } else if (
        lastPlayedBlockFromStorage &&
        !isEmptyString(lastPlayedBlockFromStorage)
      ) {
        // 1. 선택한 블럭과 현재 블럭이 일치하지 않는 경우 루프 종료. 선택지가 아닌 일반 블럭 이동은 현재는 고려하지 않는다.
        let isFindingNoChoiceBlock = true;
        let tempNextBlock: ChatBlock | undefined = undefined;

        // 1-1. 선택지가 없는 블럭 중 저장된 블럭과 일치하는 블럭이 있을때까지 이동 루프. 임시 저장해놓고 0-0에서 처리한다. 없으면 종료
        do {
          const lastResult =
            tempResultList.length >= 1 &&
            tempResultList[tempResultList.length - 1];
          const result = loadBlockStatement({
            currentBlock: tempNextBlock ?? currentBlock,
            mainCharacterName,
            otherCharacters,
            initProps: lastResult ? lastResult.props : props,
            initItems: lastResult ? lastResult.items : items,
            playData,
            chatProgressInfo: lastResult
              ? lastResult.progressInfo
              : chatProgressInfo,
            storyId: currentPlayInfo.storyId,
            chapterId: currentPlayInfo.chapterId,
            endings,
            lastPlay,
          });

          const nextBlock = chatBlocks.find(
            (item) => item.name === result.nextBlockName,
          );

          if (
            nextBlock &&
            tempResultList.findIndex(
              (item) => item.nextBlockName === nextBlock.name,
            ) === -1
          ) {
            tempResultList.push(result);
            tempNextBlock = nextBlock;
            if (nextBlock.name === lastPlayedBlockFromStorage) {
              isFindingNoChoiceBlock = false;
            }
          } else {
            tempResultList = [];
            isFindingNoChoiceBlock = false;
          }
        } while (isFindingNoChoiceBlock);

        // 1-2. 기억해놓은 블럭까지 이동할 수 없었던 경우. 종료.
        if (tempResultList.length === 0) {
          chatProgressInfo = {
            lastSourceIndex: -1,
            lastSourceLine: -1,
          };
          isFinish = true;
        }
      } else {
        // 3. 기억한 블럭이 없는 경우. 종료
        chatProgressInfo = {
          lastSourceIndex: -1,
          lastSourceLine: -1,
        };
        isFinish = true;
      }
    } else {
      // 서버로부터 전달받은 사용자 선택지 히스토리가 있는 경우
      // 로컬에 기억한 블럭이 현재 블럭과 일치하면 기억한 블럭을 초기화한다.
      if (currentBlock.name === lastPlayedBlockFromStorage) {
        lastPlayedBlockFromStorage = undefined;
      }
      loadChatBlock.push(currentBlock);

      const result = loadBlockStatement({
        currentBlock,
        mainCharacterName,
        otherCharacters,
        initProps: props,
        initItems: items,
        playData,
        userChoice: selectedChoiceList[0],
        chatProgressInfo,
        storyId: currentPlayInfo.storyId,
        chapterId: currentPlayInfo.chapterId,
        endings,
        lastPlay,
      });

      if (result.removeChoiceName) {
        // selectedChoiceList 에서 선택지 이어보기된 아이템 제거
        selectedChoiceList = selectedChoiceList.filter(
          (choice) => choice.choice.name !== result.removeChoiceName,
        );
      }

      loadList.push(...result.loadList);
      props = result.props;
      items = result.items;
      fullScreenEffectInfo = result.fullScreenEffectInfo;
      chatProgressInfo = result.progressInfo;
      const nextBlock = chatBlocks.find(
        (item) => item.name === result.nextBlockName,
      );

      if (nextBlock) {
        currentBlock = nextBlock;
      } else {
        isFinish = true;
      }
    }
  } while (!isFinish);

  return {
    loadList,
    blockName: currentBlock.name,
    props,
    items,
    blockHistory: loadChatBlock,
    chatProgressInfo,
    fullScreenEffectInfo,
  };
};

/**
 * @name onWriteCurrentPlay
 * @description 최근 플레이 정보 저장
 * @param {number} storyId 스토리 ID
 * @param {CurrentPlayInfo} data 최근 플레이 데이터
 */
export const onWriteCurrentPlay = (
  storyId: number,
  data: CurrentPlayInfo | undefined,
) => {
  if (data) {
    localStorage.setItem(
      `${STORAGE_KEY.CURRENT_PLAY}${storyId}`,
      JSON.stringify(data),
    );
  } else {
    localStorage.removeItem(`${STORAGE_KEY.CURRENT_PLAY}${storyId}`);
  }
};

/**
 * @name getCurrentStoryData
 * @description 최근 플레이정보 불러오기
 * @param storyMeta
 * @param isRestarting
 * @returns
 */ // 안쓰네?
export const getCurrentStoryData = (
  storyMeta: StoryMeta,
  isRestarting: boolean,
): CurrentPlayInfo | undefined => {
  const lastPlay = storyMeta?.lastPlay;
  if (lastPlay) {
    const { currentPlayingChapter } = lastPlay;
    const currentLocal = isRestarting
      ? undefined
      : onReadCurrentPlay(storyMeta.storyId);
    const storyData: CurrentPlayInfo = {
      storyId: currentLocal?.storyId ?? storyMeta.storyId,
      chapterId: currentPlayingChapter.chapterId,
      chapterIndex:
        currentLocal?.chapterIndex ??
        currentPlayingChapter.chapter.chapterIndex,
      lastSourceIndex: currentLocal?.lastSourceIndex || 0,
      lastSourceLine: currentLocal?.lastSourceLine ?? -1,
      lastBlockName: currentLocal?.lastBlockName ?? '',
    };

    return currentPlayingChapter ? storyData : undefined;
  } else {
    // lastPlay 정보가 없으면 최초 실행
    return undefined;
  }
};

/**
 * @name getChoiceSaveProps
 * @description ChoiceSaveProps에 대한 속성 업데이트 함수
 * @param currentProps 최근 속성
 * @param statements 스크립트 정보
 * @param index statement index 정보
 * @param selectedIndex choiceIndex 정보
 * @returns
 */
export const getChoiceSaveProps = (
  currentProps: any,
  statements: Statement[],
  index: number,
  selectedIndex: number,
) => {
  let props = currentProps; // 최근 속성 정보
  const length = statements.length;
  let newIndex = index;
  while (length > newIndex) {
    if (statements[newIndex].statementType === 'ChoiceSaveProp') {
      const choiceProps = statements[newIndex].propChoices?.[selectedIndex];
      if (choiceProps?.propName) {
        const prevProp =
          choiceProps?.propOp === 'INCREASE_NUMBER'
            ? (Number(currentProps[choiceProps.propName]) || 0) +
              Number(choiceProps.value)
            : Number(choiceProps.value);
        const copyProps = { ...props };
        copyProps[choiceProps.propName] = prevProp;
        props = copyProps;
      }

      newIndex++;
    } else {
      if (statements[newIndex].statementType === 'Choice') {
        newIndex++;
      } else {
        break;
      }
    }
  }

  return { props, newIndex };
};

/**
 * @name handleFinalEndings
 * @description finalEnding 정보 처리
 * @param statements 스크립트 정보
 * @param progressIndex 진행중인 스크립트 index
 * @returns 엔딩정보
 */
export const handleFinalEndings = (
  statements: Statement[] | StatementItem[],
  progressIndex: number,
) => {
  const length = statements.length;
  let newIndex = progressIndex;

  const endingInfo: {
    endingId?: string;
    endingName?: string;
    displayName?: string;
    endingImage?: string;
    endingDesc?: string;
  } = {
    endingId: undefined,
    endingName: undefined,
    displayName: undefined,
    endingImage: undefined,
    endingDesc: undefined,
  };

  while (length > newIndex) {
    const statement = statements[newIndex];
    if (statement.statementType === 'FinalEnding') {
      endingInfo.endingId = statements[newIndex].endingId;
      endingInfo.endingName = statements[newIndex].endingName;
      endingInfo.displayName = statements[newIndex].displayName;
    } else if (statement.statementType === 'CollectionImage') {
      endingInfo.endingImage = statement.image;
    } else if (statement.statementType === 'CollectionDesc') {
      endingInfo.endingDesc = statement.message;
      break;
    }
    newIndex++;
  }

  return endingInfo;
};

/**
 * @name convertPropsCountFormat
 * @description 속성 개수 변경 포멧 예) {{속성:속성이름}}
 * @param text
 * @param props
 * @returns
 */
export const convertPropsCountFormat = ({
  text,
  props,
}: {
  text: string;
  props: Record<string, number | string>;
}) => {
  const propsRegexp = /\{\{(속성)\:([\w가-힣 _$\s]{1,})\}\}/gm;
  const isCheck = propsRegexp.test(text);

  if (isCheck && props) {
    const propsString = text.replaceAll(propsRegexp, (substring: string) => {
      const keys = Object.keys(props);
      const matchProps = propsRegexp.exec(substring);
      substring.match(propsRegexp);
      if (matchProps) {
        const propsName = matchProps[2];
        const propsKey = keys.find(
          (propKey) =>
            propKey === propsName ||
            onTrimAll(propKey) === onTrimAll(propsName),
        );
        return propsKey ? `${props[propsKey]}` : '0';
      } else {
        return substring;
      }
    });
    return propsString;
  } else {
    return text;
  }
};

/**
 * @name convertItemCountFormat
 * @description 아이템 개수 포멧 변경 함수, 예) {{아이템:안경}}개 획득 -> 2개 획득
 * @param text : 포멧 변경 할 text
 * @param items : user item 정보
 * @returns 포멧 변경 적용된 text
 */
export const convertItemCountFormat = ({
  text,
  items,
}: {
  text: string;
  items: Record<string, number | string>;
}): string => {
  // eslint-disable-next-line no-useless-escape
  const itemRegexp = /\{\{(아이템)\:([\w가-힣\s]{1,})\}\}/gm;
  const isCheck = itemRegexp.test(text);
  if (isCheck) {
    const itemString = text.replaceAll(itemRegexp, (substring: string) => {
      const keys = Object.keys(items);
      const matchItems = itemRegexp.exec(substring);
      if (matchItems) {
        const itemName = matchItems[2];
        const itemKey = keys.find(
          (propKey) => propKey === itemName || onTrimAll(propKey) === itemName,
        );
        return itemKey ? `${items[itemKey]}` : '0';
      } else {
        return substring;
      }
    });
    return itemString;
  } else {
    return text;
  }
};

/**
 * @name onReplaceOtherCharacterName
 * @description 다른캐릭터 이름 처리 예) {{민준:이름}}
 * @param text
 * @returns
 */
export const onReplaceOtherCharacterName = ({
  text,
  otherCharacters,
}: {
  text: string;
  otherCharacters: OtherCharacterInfo[];
}): string => {
  // eslint-disable-next-line no-useless-escape
  const nameRegexp = /\{\{([\w가-힣\s]{1,})\:(이름|성)\}\}/gm;
  const isCheck = nameRegexp.test(text);
  if (!isCheck) return text;

  const nameString = text.replaceAll(nameRegexp, (substring: string) => {
    const findName = otherCharacters.find((item) =>
      substring.includes(item.originName || ''),
    );

    if (findName?.displayName) {
      if (substring.includes('성')) {
        return '';
      } else {
        const word = substring.includes('이름')
          ? findName.displayName
          : substring;
        return word;
      }
    } else {
      const word = findName
        ? substring.includes('이름')
          ? findName?.customFirstName || ''
          : findName?.customLastName || ''
        : substring;
      return word;
    }
  });

  return nameString;
};

export const setStatementCustomName = ({
  statement,
  mainCharacterName,
  otherCharacters = [],
}: {
  statement: Statement | StatementItem;
  mainCharacterName?: {
    firstName: string;
    lastName?: string;
  };
  otherCharacters: OtherCharacterInfo[];
}): Statement | StatementItem => {
  if (!statement?.message && !statement?.chrName) {
    return statement;
  }

  let message = statement?.message || '';
  let chrName = statement?.chrName || '';
  let messageWithEffect =
    statement?.messageWithEffect &&
    JSON.stringify(statement?.messageWithEffect);

  let displayName = undefined;

  if (mainCharacterName) {
    message = convertCharName(
      message,
      mainCharacterName.firstName,
      mainCharacterName.lastName || '',
    );

    chrName = convertCharName(
      chrName,
      mainCharacterName.firstName,
      mainCharacterName.lastName || '',
    );

    messageWithEffect =
      messageWithEffect &&
      convertCharName(
        messageWithEffect,
        mainCharacterName.firstName,
        mainCharacterName.lastName || '',
      );
  }

  if (otherCharacters.length > 0) {
    message = onReplaceOtherCharacterName({ text: message, otherCharacters });
    const findCharacter = otherCharacters.find(
      (item) => item.originName === statement.chrName,
    );

    displayName = findCharacter?.displayName
      ? findCharacter?.displayName
      : `${findCharacter?.customLastName || ''}${
          findCharacter?.customFirstName || chrName
        }`;

    messageWithEffect =
      messageWithEffect &&
      onReplaceOtherCharacterName({
        text: messageWithEffect,
        otherCharacters,
      });
  }
  message = onReplacePostPosition(message);
  messageWithEffect =
    messageWithEffect && onReplacePostPosition(messageWithEffect);

  const messageWithEffectList: TextEffectStyle[] | undefined = messageWithEffect
    ? safeJSONParse(messageWithEffect) ?? undefined
    : undefined;

  return {
    ...statement,
    message,
    chrName,
    displayName,
    messageWithEffect: messageWithEffectList,
  };
};

export const setCustomNameToText = ({
  text,
  playChapterInfo,
}: {
  text: string;
  playChapterInfo?: PlayChapterInfo;
}): string => {
  if (!playChapterInfo) {
    return text;
  }

  const mainCharacterName = playChapterInfo.mainCharacterName;
  const otherCharacters: OtherCharacterInfo[] =
    playChapterInfo.playingOtherCharacters;
  let resultText = text;
  if (mainCharacterName) {
    resultText = convertCharName(
      resultText,
      mainCharacterName.firstName,
      mainCharacterName.lastName || '',
    );
  }

  if (otherCharacters.length > 0) {
    resultText = onReplaceOtherCharacterName({
      text: resultText,
      otherCharacters,
    });
  }
  resultText = onReplacePostPosition(resultText);

  return resultText;
};

export const getIsShowProfile = (
  statements: StatementItem[],
  statement: StatementItem,
) => {
  const findIndex = statements.indexOf(statement);
  const sliceList = statements.slice(0, findIndex);
  const reverse = sliceList.reverse();
  const firstShowItem = reverse.find((item) => item.isShow);

  return statement?.chrName !== firstShowItem?.chrName;
};

export const setCustomNameTitle = ({
  text,
  mainCharacterName,
  playingCharacters,
}: {
  text: string;
  mainCharacterName?: {
    firstName: string;
    lastName?: string;
  };
  playingCharacters: PlayingCharacters[];
}): string => {
  const otherCharacters: OtherCharacterInfo[] =
    playingCharacters?.map((item) => {
      return {
        originName: item.character?.name,
        customFirstName: item.firstName,
        customLastName: item.lastName,
        profileImageFileId: item.profileImageFileId,
      };
    }) || [];
  let resultText = text;
  if (mainCharacterName) {
    resultText = convertCharName(
      resultText,
      mainCharacterName.firstName,
      mainCharacterName.lastName || '',
    );
  }

  if (otherCharacters.length > 0) {
    resultText = onReplaceOtherCharacterName({
      text: resultText,
      otherCharacters,
    });
  }
  resultText = onReplacePostPosition(resultText);

  return resultText;
};

export const getUserInputChoiceItem = ({
  sourceLine,
  background,
  choiceOptions,
  storyId,
  chapterId,
  mainCharacterName,
  rightUserInputText,
}: {
  sourceLine?: number;
  background?: string;
  choiceOptions?: ChoiceOption;
  storyId: number;
  chapterId: number;
  mainCharacterName?: {
    firstName: string;
    lastName?: string;
  };
  rightUserInputText?: string;
}): StatementItem | undefined => {
  const choiceItem: StatementItem = {
    statementType: choiceOptions?.choiceBackgroundImage
      ? 'ChoiceInput'
      : 'MainCharacterTalk',
    storyId: storyId,
    chapterId: chapterId,
    sourceLine: sourceLine,
    background: background,
    isShow: true,
    leftUserInputText: choiceOptions?.leftUserInputText,
    rightUserInputText,
    options: choiceOptions,
    message: rightUserInputText || '',
    chrName: (mainCharacterName?.lastName || '') + mainCharacterName?.firstName,
  } as StatementItem;

  return choiceItem;
};

export const getChoiceRateItem = ({
  choiceId,
  storyId,
  chapterId,
  background,
}: {
  choiceId: number;
  storyId: number;
  chapterId: number;
  background?: string;
}) => {
  const choiceItem = {
    statementType: 'ChoiceRate',
    choiceId: choiceId,
    storyId: storyId,
    chapterId: chapterId,
    sourceLine: choiceId,
    background: background || '',
    isShow: true,
  } as StatementItem;

  return choiceItem;
};

export const setCustomPropsNItems = ({
  statement,
  props,
  items,
}: {
  statement: Statement | StatementItem;
  props?: Record<string, number | string>;
  items?: Record<string, number | string>;
}): Statement | StatementItem => {
  let newStatement = statement;
  if (props) {
    const message = convertPropsCountFormat({
      text: newStatement?.message || '',
      props,
    });
    let messageWithEffect =
      newStatement?.messageWithEffect &&
      JSON.stringify(newStatement?.messageWithEffect);
    if (messageWithEffect) {
      messageWithEffect = convertPropsCountFormat({
        text: messageWithEffect,
        props,
      });
    }
    const messageWithEffectList: TextEffectStyle[] | undefined =
      messageWithEffect ? JSON.parse(messageWithEffect) : undefined;

    const effects = newStatement?.option?.effects?.map((effect: TextEffect) => {
      const content = convertPropsCountFormat({ text: effect.content, props });
      const contentWithEffect =
        effect.contentWithEffect.map((item) => {
          return {
            ...item,
            text: convertPropsCountFormat({ text: item.text, props }),
          };
        }) || newStatement.option?.effects;

      return {
        ...effect,
        content,
        contentWithEffect,
      };
    });

    newStatement = {
      ...newStatement,
      message,
      messageWithEffect: messageWithEffectList,
      option: effects
        ? {
            effects: effects,
          }
        : undefined,
    };
  }
  if (items) {
    const message = convertItemCountFormat({
      text: newStatement.message,
      items,
    });
    let messageWithEffect =
      newStatement.messageWithEffect &&
      JSON.stringify(newStatement?.messageWithEffect);
    if (messageWithEffect) {
      messageWithEffect = convertItemCountFormat({
        text: messageWithEffect,
        items,
      });
    }
    const messageWithEffectList: TextEffectStyle[] | undefined =
      messageWithEffect ? JSON.parse(messageWithEffect) : undefined;

    const effects = newStatement.option?.effects?.map((effect: TextEffect) => {
      const content = convertItemCountFormat({ text: effect.content, items });
      const contentWithEffect =
        effect.contentWithEffect.map((item) => {
          return {
            ...item,
            text: convertItemCountFormat({ text: item.text, items }),
          };
        }) || newStatement.option?.effects;

      return {
        ...effect,
        content,
        contentWithEffect,
      };
    });

    newStatement = {
      ...newStatement,
      message,
      messageWithEffect: messageWithEffectList,
      option: effects
        ? {
            effects: effects,
          }
        : undefined,
    };
  }

  return newStatement;
};

/**
 * @name mergeChoiceProps
 * @description ChoiceSaveProp type 속성값 처리를 위한 함수
 * @param {Record<string, number | string>} props 지금 나의 속성
 * @param {Statement[]} choiceSaveProps ChoiceSaveProp statement list
 * @param {choiceIndex} 유저가 선택한 choice index
 * @return merge된 props 값
 */
export const mergeChoiceProps = ({
  props,
  choiceSaveProps,
  choiceIndex,
  playData,
}: {
  props: Record<string, number | string>;
  choiceSaveProps: Statement[];
  choiceIndex: number;
  playData: string;
}): Record<string, number | string> => {
  let newProps = { ...props };
  choiceSaveProps.forEach((prop) => {
    const choiceProps = prop.propChoices[choiceIndex];
    if (choiceProps?.propName) {
      const propValue = choiceProps.value;
      const calcProp =
        choiceProps?.propOp === 'INCREASE_NUMBER'
          ? (Number(newProps[choiceProps.propName]) || 0) + Number(propValue)
          : choiceProps?.propOp === 'CALCULATE'
          ? Number(calculate(propValue, playData))
          : Number(propValue);

      const copyProps = { ...newProps };
      copyProps[choiceProps.propName] = calcProp;
      newProps = copyProps;
    }
  });
  return newProps;
};

/**
 * @name mergeChoiceItems
 * @description ChoiceIfUpdateItem type 속성값 처리를 위한 함수
 * @param {Record<string, number | string>} items 지금 나의 아이템
 * @param {Statement[]} choiceIfItems ChoiceIfUpdateItem statement list
 * @return update된 아이템 정보
 */
export const mergeChoiceItems = ({
  items,
  choiceIfItems,
  playData,
}: {
  items: Record<string, number | string>;
  choiceIfItems: Statement[];
  playData: string;
}): Record<string, number | string> => {
  let newItems: Record<string, number | string> = { ...items };
  choiceIfItems.forEach((info) => {
    newItems = getUpdatedItems({
      currentItems: newItems,
      newItems: info.itemUpdate,
      playData,
    }).newMergeItems;
  });

  return newItems;
};

/**
 * @name mergeChoiceIfProps
 * @description ChoiceIfSaveProp type 속성값 처리를 위한 함수
 * @param {Record<string, number | string>} props 지금 나의 속성
 * @param {Statement[]} choiceIfProps ChoiceIfSaveProp statement list
 * @return update된 속성 정보
 */
export const mergeChoiceIfProps = ({
  props,
  choiceIfProps,
  playData,
}: {
  props: Record<string, number | string>;
  choiceIfProps: Statement[];
  playData: string;
}): Record<string, number | string> => {
  let newProps = { ...props };
  choiceIfProps.forEach((info) => {
    if (info?.propUpdate.propName) {
      const propValue = info.propUpdate.value;
      const calcProp =
        info.propUpdate.propOp === 'INCREASE_NUMBER'
          ? (Number(newProps[info.propUpdate.propName]) || 0) +
            Number(propValue)
          : info.propUpdate.propOp === 'CALCULATE'
          ? Number(calculate(propValue, playData))
          : Number(propValue);
      const copyProps = { ...newProps };
      copyProps[info.propUpdate.propName] = calcProp;
      newProps = copyProps;
    }
  });

  return newProps;
};

/**
 * @name getMakeChoiceInfo
 * @description makeChoice 요청을 위한 속성, 아이템, 업적 정보 처리함수
 * @return makeChoice 요청에 사용될 데이터
 */
export const getMakeChoiceInfo = ({
  playChapterInfo,
  props,
  items,
  choiceIfInfos,
  choice,
  playData,
}: {
  playChapterInfo: PlayChapterInfo;
  props: Record<string, number | string>;
  items: Record<string, number | string>;
  choiceIfInfos: Statement[];
  choice: {
    choiceName: string;
    choiceIndex: number;
    showStatistics: boolean;
  };
  playData: string;
}): MakeChoiceData => {
  if (choiceIfInfos.length < 1) {
    const choiceInfo: MakeChoiceData = {
      storyId: playChapterInfo?.playChapter.storyId ?? 0,
      chapterId: playChapterInfo?.playChapter.chapterId ?? 0,
      choiceName: choice.choiceName,
      choice: choice.choiceIndex ? choice.choiceIndex + 1 : 1,
      showStatistics: choice.showStatistics,
    };

    return choiceInfo;
  }

  let choiceProps = undefined;
  let choiceItems = undefined;
  const choiceSaveProps = choiceIfInfos.filter(
    (info) => info.statementType === 'ChoiceSaveProp',
  );
  const pickChoiceIfItems = choiceIfInfos.filter(
    (info) =>
      info.choice === choice.choiceIndex &&
      info.statementType === 'ChoiceIfUpdateItem',
  );
  const pickChoiceIfProps = choiceIfInfos.filter(
    (info) =>
      info.choice === choice.choiceIndex &&
      info.statementType === 'ChoiceIfSaveProp',
  );
  const pickChoiceIfAchievement = choiceIfInfos.find(
    (info) =>
      info.choice === choice.choiceIndex &&
      info.statementType === 'ChoiceIfAchievementEvent',
  );

  if (choiceSaveProps.length > 0) {
    choiceProps = mergeChoiceProps({
      props: choiceProps || props,
      choiceSaveProps,
      choiceIndex: choice.choiceIndex,
      playData,
    });
  }

  if (pickChoiceIfProps.length > 0) {
    choiceProps = mergeChoiceIfProps({
      props: choiceProps || props,
      choiceIfProps: pickChoiceIfProps,
      playData,
    });
  }

  if (pickChoiceIfItems.length > 0) {
    choiceItems = mergeChoiceItems({
      items,
      choiceIfItems: pickChoiceIfItems,
      playData,
    });
  }

  const choiceInfo: MakeChoiceData = {
    storyId: playChapterInfo?.playChapter.storyId ?? 0,
    chapterId: playChapterInfo?.playChapter.chapterId ?? 0,
    choiceName: choice.choiceName,
    choice: choice.choiceIndex ? choice.choiceIndex + 1 : 1,
    showStatistics: choice.showStatistics,
    event: pickChoiceIfAchievement?.event || null,
    numParam1: pickChoiceIfAchievement?.numParam1 || null,
    numParam2: pickChoiceIfAchievement?.numParam2 || null,
    updatedItems: pickChoiceIfItems.length
      ? pickChoiceIfItems.map((item) => item.itemUpdate.itemName)
      : null,
    updatedUserItems: choiceItems ? choiceItems : null,
    updatedUserProps: choiceProps ? JSON.stringify(choiceProps) : null,
  };

  return choiceInfo;
};

/**
 * @name getTextEffectStyle
 * @description 텍스트의 굵기, 크기, 기울임 효과 등의 스타일 CSS 문자열을 만드는 함수
 * @param style 텍스트 효과 타입
 * @param currentSize 기존 폰트 사이즈(px단위)
 * @param sizeOffset 폰트 사이즈 상쇄값(px단위)(ex) -2, 3, 0,,)
 * @return emotion css내부에 삽입할 string 리턴
 */
export const getTextEffectStyle = ({
  style,
  currentSize,
  sizeOffset,
}: {
  style?: TextEffectStyleType;
  currentSize?: number;
  sizeOffset?: number;
}): string => {
  const fontSize =
    currentSize && sizeOffset ? currentSize + sizeOffset : currentSize;

  return `font-weight: ${
    style === 'bold' || style === 'italic_bold' ? 'bold' : 'normal'
  };
  font-style: ${
    style === 'italic' || style === 'italic_bold' ? 'italic' : 'normal'
  };
  ${fontSize && `font-size: ${fontSize}px;`}
  `;
};

export const chatCalculate = (operationData: string, playData: string) => {
  return calculate(operationData, playData);
};

/**
 * @name onExecuteCondition
 * @description calculate 조건에 해당하는 경우만 채팅화면에 표시
 * @param statement 채팅 statement
 * @param playData 플레이 데이터
 * @returns statement | undefined
 */
export const onExecuteCondition = ({
  statement,
  playData,
}: {
  statement: Statement;
  playData: string;
}): Statement | undefined => {
  if (statement?.executeCondition) {
    const isExecute = chatCalculate(statement.executeCondition, playData);
    return Number(isExecute) ? statement : undefined;
  } else {
    return statement;
  }
};

/**
 * @name setStatementCustomText
 * @description statement 커스텀 정보 업데이트 (캐릭터 이름, 속성, 아이템 갯수)
 * @params statement, mainCharacterName, otherCharacters, props, items
 * @return 커스텀 Statement
 */
export const setStatementCustomText = ({
  statement,
  mainCharacterName,
  otherCharacters,
  props,
  items,
}: {
  statement: Statement | StatementItem;
  mainCharacterName?: {
    firstName: string;
    lastName?: string;
  };
  otherCharacters: OtherCharacterInfo[];
  props: Record<string, number | string>;
  items: Record<string, number | string>;
}) => {
  let customStatement = setStatementCustomName({
    statement,
    mainCharacterName,
    otherCharacters,
  });

  customStatement = setCustomPropsNItems({
    statement: customStatement,
    props,
    items,
  });

  return customStatement;
};

export const getBadgesToAchievement = (
  badgesAcquired?: BadgesAcquired[],
): Record<string, number> | undefined => {
  if (!badgesAcquired) return undefined;

  let acquiredAchievements: string | undefined = undefined;

  const achievementList = badgesAcquired
    .filter((badge: BadgesAcquired) => badge.badge.acquired)
    .map((item) => {
      return `"${item.badge.aBadgeId}": "${item.badge.name}"`;
    });

  if (achievementList.length > 0) {
    acquiredAchievements = '{ ';
    achievementList.forEach(
      (item, index, array) =>
        (acquiredAchievements =
          acquiredAchievements +
          `${item}${index < array.length - 1 ? ', ' : ' }'}`),
    );
  }
  return acquiredAchievements && JSON.parse(acquiredAchievements);
};

/**
 * @name getRemoteStatements
 * @description RemoteScript 이어보기 처리, remoteScript에서 statements 정보 return
 * @params statement, remoteScript
 * @return Statement[] | undefined
 */
export const getRemoteStatements = (
  statement: Statement,
  playingRemoteScripts?: {
    remoteScriptId: string;
    statements: string;
  }[],
): Statement[] | undefined => {
  const remoteScript = playingRemoteScripts?.find(
    (item) => item.remoteScriptId === statement.scriptId,
  );

  if (remoteScript) {
    return JSON.parse(remoteScript.statements) as Statement[];
  } else {
    return undefined;
  }
};

export const onChoiceToProps = ({
  choiceName,
  rightUserInputText,
  choiceNumber,
}: {
  choiceName: string;
  rightUserInputText?: string;
  choiceNumber?: number;
}) => {
  const propsName = `${
    rightUserInputText ? '_$_userChoiceInputText_' : '_$_userChoice_'
  }${choiceName}`;
  const propsValue = rightUserInputText ? rightUserInputText : choiceNumber;
  return `{"${propsName}": "${propsValue}"}`;
};

export const mergeChoiceToProps = ({
  props,
  choiceName,
  rightUserInputText,
  choiceNumber,
}: {
  props: Record<string, number | string>;
  choiceName: string;
  rightUserInputText?: string;
  choiceNumber?: number;
}) => {
  let newProps = { ...props };
  if (rightUserInputText) {
    const propsName = `_$_userChoiceInputText_${choiceName}`;
    const choiceProp = JSON.parse(`{"${propsName}": "${rightUserInputText}"}`);
    newProps = {
      ...newProps,
      ...choiceProp,
    };
  }
  if (choiceNumber) {
    const propsName = `_$_userChoice_${choiceName}`;
    const choiceProp = JSON.parse(`{"${propsName}": ${choiceNumber}}`);
    newProps = {
      ...newProps,
      ...choiceProp,
    };
  }
  return newProps;
};

/**
 * @name getChapterPaymentStatus
 * @description 챕터의 무료, 광고, 유료 여부를 판단
 * @params freedAt(무료가 된 경우, 무료가 된 시각), adsOn(광고를 봐야 하는지 여부), userPurchasedChapter(챕터 구매 여부)
 * @return ChapterPaymentStatus
 */
export const getChapterPaymentStatus = ({
  freedAt,
  adsOn,
  userPurchasedChapter,
  price,
}: {
  freedAt: Date | null;
  adsOn: boolean;
  userPurchasedChapter: UserPurchasedChapter | null;
  price?: number;
}): ChapterPaymentStatus => {
  const isFree = freedAt
    ? isBefore(new Date(freedAt), new Date())
    : price === 0;
  if (isFree && adsOn) {
    return 'adsOn';
  }

  // 무료이거나, 유료여도 구매를 하였다면 무료처리
  if (isFree || !!userPurchasedChapter || price === 0) {
    return 'free';
  }

  return 'paid';
};
