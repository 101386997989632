import React from 'react';
import Head from 'next/head';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import { SearchNotFoundImg } from 'public/assets/index';

interface ISPErrorInner {
  title?: string;
  message?: string;
  buttonName?: string;
  onBack?: () => void;
}

const SPErrorInner = ({
  title,
  message,
  buttonName,
  onBack,
}: ISPErrorInner) => {
  return (
    <div css={container}>
      <Head>
        <meta
          property="description"
          content="스플: 인생은 한 번 뿐이지만 이곳에선 되돌릴 수 있다"
        />
      </Head>
      <div className="error-wrapper">
        <SearchNotFoundImg className="error-image" />
        {title && <p className="error-title">{title}</p>}
        <p className="error-text">{message ?? ''}</p>
        <button className="error-button" onClick={onBack}>
          {buttonName}
        </button>
      </div>
    </div>
  );
};

const container = css`
  width: 100%;
  height: 100vh;
  background-color: ${theme.colors.gray200};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 11;

  .error-wrapper {
    width: 100%;
    height: 100%;
    background: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .error-image {
    display: block;
    width: 200px;
    object-fit: contain;
  }

  .error-title {
    font-size: 24px;
    font-weight: 500;
  }

  .error-text {
    font-family: AppleSDGothicNeo, apple-system, BlinkMacSystemFont, Helvetica,
      Arial, Noto Sans, Dotum, sans-serif !important;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
    margin: 20px;
    white-space: pre-line;
    text-align: center;
  }

  .error-button {
    width: 160px;
    height: 50px;
    border-radius: 26px;
    background-color: ${theme.colors.primary500};
    font-size: 16px;
    color: ${theme.colors.white};
    border: none;
    cursor: pointer;
    z-index: 11;
  }

  .error-button:focus {
    outline: none;
  }

  @media (max-width: ${theme.devices.mobile}) {
    width: 100%;
  }
`;

export default SPErrorInner;
