import React, { useEffect, useState } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import theme from '@styles/theme';
import { NavCloseBtn } from 'public/assets';
import Portal from '@components/commons/portal/Portal';

interface IFullScreenPopupContainer {
  children: JSX.Element[] | JSX.Element;
  onClose: () => void;
  hasBackDrop?: boolean;
  hasHeader?: boolean;
  customCSS?: SerializedStyles;
}

const FullScreenPopupContainer = ({
  children,
  onClose,
  hasBackDrop = true,
  hasHeader = true,
  customCSS,
}: IFullScreenPopupContainer) => {
  const [isCloseButtonClick, setIsCloseButtonClick] = useState<boolean>(false);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const handleCloseButtonClick = () => {
    setIsCloseButtonClick(true);

    setTimeout(() => {
      onClose();
    }, 200);
  };

  return (
    <Portal>
      <>{hasBackDrop && <div css={backDrop} />}</>
      <div
        css={[container, customCSS]}
        className={`up ${isCloseButtonClick && 'down'}`}
      >
        {hasHeader && (
          <header className="header">
            <NavCloseBtn
              width={22}
              height={22}
              fill={theme.colors.white}
              className="icon"
              onClick={handleCloseButtonClick}
            />
          </header>
        )}
        {children}
      </div>
    </Portal>
  );
};

const backDrop = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100%;
  background: #000000;
  opacity: 0.6;
  overflow: hidden;
  z-index: 20;
`;

const container = css`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 800px;
  height: 100vh;
  overflow: scroll;
  background: #1a1a1a;
  z-index: 20;

  &::-webkit-scrollbar {
    display: none;
  }
  .header {
    position: fixed;
    top: 0;
    padding: 1.375em 1.25em;
    width: 800px;
    z-index: 29;

    .icon {
      cursor: pointer;
    }

    @media screen and (max-width: ${theme.devices.mobile}) {
      width: 100%;
    }
  }

  @media screen and (max-width: ${theme.devices.mobile}) {
    width: 100%;
    left: 0;
    transform: unset;

    &.up {
      animation: up 0.3s ease-out;
    }

    &.down {
      animation: down 0.25s ease-out;
    }
  }

  @keyframes up {
    0% {
      transform: translateY(100vh);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes down {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(100vh);
    }
  }
`;

export default FullScreenPopupContainer;
