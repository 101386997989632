import Ko from '../translation/ko.json'
import En from '../translation/en.json'

// json파일 내에 있는 키값으로 타입 생성.
export type keyType = keyof typeof Ko & keyof typeof En

export type LANGUAGE_TYPE = 'ko' | 'en'

const STORAGE_KEY_LANG = 'common-react-lang'

export const setCommonReactLang = (lang: LANGUAGE_TYPE) => {
  localStorage.setItem(STORAGE_KEY_LANG, lang || 'ko')
}

export const getCurrentLanguage = () => {
  let currentLanguage: LANGUAGE_TYPE = 'ko'

  if (typeof window !== 'undefined') {
    const languageByStorage = localStorage.getItem(STORAGE_KEY_LANG) as
      | LANGUAGE_TYPE
      | undefined
    currentLanguage = languageByStorage || 'ko'
  }

  return currentLanguage
}

const replaceTemplate = (
  template: string,
  values: { [key: string]: string }
): string => {
  return template.replace(/{{\s*([^{}\s]+)\s*}}/g, (match, key) => {
    // 객체에서 키에 해당하는 값을 찾아 반환합니다.
    // 해당 키가 없는 경우, 원래의 문자열을 반환합니다.
    return key in values ? values[key] : match
  })
}

const translate = (key: keyType, arg?: { value?: any; value1?: any }) => {
  const currentLanguage = getCurrentLanguage()
  const languageTextFile = currentLanguage === 'en' ? En : Ko
  return arg
    ? replaceTemplate((languageTextFile[key] as string).trim(), arg)
    : languageTextFile[key] || key
}

export { translate as t }
