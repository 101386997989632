export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID;

declare global {
  interface Window {
    fbq: any;
  }
}

// 표준 이벤트
export const subscribe = () => {
  window.fbq('track', 'Subscribe');
};

export const completeRegistration = () => {
  window.fbq('track', 'CompleteRegistration');
};

export const startTrial = () => {
  window.fbq('track', 'StartTrial');
};

export const viewContent = () => {
  window.fbq('track', 'ViewContent');
};

export const purchase = ({ price }: { price: number }) => {
  window.fbq('track', 'Purchase', { value: price, currency: 'KRW' });
};

// 맞춤 이벤트
export const startChapter = (name: string, index: number) => {
  window.fbq('trackCustom', 'start_chapter', {
    story: name,
    chapter: index,
  });
};

export const startChapterInteractive = (name: string, index: number) => {
  window.fbq('trackCustom', 'start_chapter_interactive', {
    story: name,
    chapter: index,
  });
};

export const startChapterNovel = (name: string, index: number) => {
  window.fbq('trackCustom', 'start_chapter_novel', {
    story: name,
    chapter: index,
  });
};

export const startChapterFirst = (name: string) => {
  window.fbq('trackCustom', 'start_chapter_first', { story: name });
};

export const startChapterInteractiveFirst = (name: string) => {
  window.fbq('trackCustom', 'start_chapter_interactive_first', { story: name });
};

export const startChapterNovelFirst = (name: string) => {
  window.fbq('trackCustom', 'start_chapter_novel_first', { story: name });
};

export const endChapter = (name: string, index: number) => {
  window.fbq('trackCustom', 'end_chapter', {
    story: name,
    chapter: index,
  });
};

export const endChapterInteractive = (name: string, index: number) => {
  window.fbq('trackCustom', 'end_chapter_interactive', {
    story: name,
    chapter: index,
  });
};

export const endChapterNovel = (name: string, index: number) => {
  window.fbq('trackCustom', 'end_chapter_novel', {
    story: name,
    chapter: index,
  });
};

export const endChapterFirst = (name: string) => {
  window.fbq('trackCustom', 'end_chapter_first', { story: name });
};

export const endChapterInteractiveFirst = (name: string) => {
  window.fbq('trackCustom', 'end_chapter_interactive_first', { story: name });
};

export const endChapterNovelFirst = (name: string) => {
  window.fbq('trackCustom', 'end_chapter_novel_first', { story: name });
};
