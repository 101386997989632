import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChapterCommentOrderBy } from '@/baseType';
import { SORT_BY } from '@common/values';

interface readyToEditPayload {
  id: number;
  content: string;
  isSpoiler: boolean;
}

interface reportInfoPayload {
  storyName: string;
  chapterIndex: number;
  type: 'comment' | 'reply';
}
interface ICommentState {
  editingCommentInfo: {
    id: number | null;
    content: string;
    isSpoiler: boolean;
  };
  orderBy: ChapterCommentOrderBy | ChapterCommentOrderBy[];
  onlySystemLanguageMatched: boolean;
  isCommentable: boolean;
  toastMessage: string | null;
  reportInfo: reportInfoPayload | null;
  isUserAdmin: boolean;
}

const initialState: ICommentState = {
  editingCommentInfo: {
    id: null,
    content: '',
    isSpoiler: false,
  },
  orderBy: SORT_BY.best,
  onlySystemLanguageMatched: false,
  isCommentable: false,
  toastMessage: null,
  reportInfo: null,
  isUserAdmin: false,
};

const commentSlices = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    readyToEdit(state, action: PayloadAction<readyToEditPayload>) {
      const { id, content, isSpoiler } = action.payload;
      state.editingCommentInfo.id = id;
      state.editingCommentInfo.content = content;
      state.editingCommentInfo.isSpoiler = isSpoiler;
    },
    clearEditCommentInfo(state) {
      state.editingCommentInfo.id = null;
      state.editingCommentInfo.content = '';
      state.editingCommentInfo.isSpoiler = false;
    },
    setOrderBy(
      state,
      action: PayloadAction<ChapterCommentOrderBy | ChapterCommentOrderBy[]>,
    ) {
      state.orderBy = action.payload;
    },
    setIsCommentable(state, action: PayloadAction<boolean>) {
      state.isCommentable = action.payload;
    },
    setToastMessage(state, action: PayloadAction<string | null>) {
      state.toastMessage = action.payload;
    },
    setReportInfo(state, action: PayloadAction<reportInfoPayload | null>) {
      state.reportInfo = action.payload;
    },
    setIsUserAdmin(state, action: PayloadAction<boolean>) {
      state.isUserAdmin = action.payload;
    },
    setOnlySystemLanguageMatched(state, action: PayloadAction<boolean>) {
      state.onlySystemLanguageMatched = action.payload;
    },
  },
});

export const {
  readyToEdit,
  clearEditCommentInfo,
  setOrderBy,
  setIsCommentable,
  setToastMessage,
  setReportInfo,
  setIsUserAdmin,
  setOnlySystemLanguageMatched,
} = commentSlices.actions;

export default commentSlices;
