import * as Types from '../../../../baseType';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRefreshCountryTokenMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRefreshCountryTokenMutation = { __typename?: 'Mutation', refreshToken: { __typename?: 'LoginResponse', token: string } };


export const GetRefreshCountryTokenDocument = gql`
    mutation GetRefreshCountryToken {
  refreshToken {
    token
  }
}
    `;
export type GetRefreshCountryTokenMutationFn = Apollo.MutationFunction<GetRefreshCountryTokenMutation, GetRefreshCountryTokenMutationVariables>;

/**
 * __useGetRefreshCountryTokenMutation__
 *
 * To run a mutation, you first call `useGetRefreshCountryTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetRefreshCountryTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getRefreshCountryTokenMutation, { data, loading, error }] = useGetRefreshCountryTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetRefreshCountryTokenMutation(baseOptions?: Apollo.MutationHookOptions<GetRefreshCountryTokenMutation, GetRefreshCountryTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetRefreshCountryTokenMutation, GetRefreshCountryTokenMutationVariables>(GetRefreshCountryTokenDocument, options);
      }
export type GetRefreshCountryTokenMutationHookResult = ReturnType<typeof useGetRefreshCountryTokenMutation>;
export type GetRefreshCountryTokenMutationResult = Apollo.MutationResult<GetRefreshCountryTokenMutation>;
export type GetRefreshCountryTokenMutationOptions = Apollo.BaseMutationOptions<GetRefreshCountryTokenMutation, GetRefreshCountryTokenMutationVariables>;