import React from 'react';
import Head from 'next/head';
import { DefaultSeo, DefaultSeoProps } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import Script from 'next/script';
import { DEV_JS_PLAYDATA_URL, PROD_JS_PLAYDATA_URL } from '@common/values';

import * as fbq from '@lib/facebookPixel';
import * as branch from '@lib/branchMetrics';
import * as gtag from '@lib/googleAnalytics';
import * as twq from '@lib/twitterPixel';

import {
  BASE_URL,
  MBTI19_STORY_ID,
  SHARE_HOUSE_RED_STORY_ID,
} from '@common/values';
import { useRouter } from 'next/router';
import { isDevelopment } from '@/common/utils';

interface IHead {
  userId?: number;
  userEmail?: string;
  userAgent?: string | boolean;
  userCountry?: string;
  isAdult?: boolean;
}

const SPHead = ({ userId, userAgent, isAdult }: IHead) => {
  const router = useRouter();
  const { t } = useTranslation();

  const title = t('common_screen_description_sp_choicable_story');
  const description = t('common_screen_description_sp_life_is_once');

  const defaultSeo: DefaultSeoProps = {
    title,
    description,
    openGraph: {
      title,
      description,
      type: 'website',
      locale: 'ko_KR',
      url: BASE_URL,
      images: [
        {
          url: `${BASE_URL}/assets/${
            isAdult && router.asPath?.includes(String(MBTI19_STORY_ID))
              ? 'mbti19-og-image.png'
              : isAdult &&
                router.asPath?.includes(String(SHARE_HOUSE_RED_STORY_ID))
              ? 'share-house19-og-image.jpg'
              : router.locale === 'ko'
              ? 'og-image-with-ai-kr.png'
              : 'og-image-with-ai-en.png'
          }`,
          width: 800,
          height: 400,
        },
      ],
    },
    // hreflang 설정
    languageAlternates: [
      {
        hrefLang: 'en',
        href: `${BASE_URL}/en`,
      },
      {
        hrefLang: 'ko',
        href: `${BASE_URL}/ko`,
      },
      {
        hrefLang: 'x-default',
        href: BASE_URL,
      },
    ],
    additionalLinkTags: [
      {
        // 웹사이트를 홈화면에 추가했을 경우 보여지는 아이콘. 아래 코드로 안드로이드와 아오스 모두 적용됨.
        rel: 'apple-touch-icon',
        href: `${BASE_URL}/assets/icon-app.png`,
        sizes: '76x76',
      },
    ],
  };

  const getPlayDataCalculator = () => {
    const date = new Date().toISOString();
    const tsQuery = `?ts=${date}`; // 캐싱방지용
    const url =
      process.env.NODE_ENV === 'development'
        ? DEV_JS_PLAYDATA_URL
        : PROD_JS_PLAYDATA_URL;

    return url + tsQuery;
  };

  return (
    <>
      {isDevelopment() && (
        <meta
          name="google-site-verification"
          content="1-lshoejIzTMWLMmIS1psYI9jrGcXIeuJsCQaGhOHr0"
        />
      )}
      {isDevelopment() && <meta name="robots" content="noindex"></meta>}
      <DefaultSeo {...defaultSeo} />
      <Script
        id="tictok-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
              )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
            const userData = JSON.parse(localStorage.getItem('user_properties'));
            if (userData) {
              ttq.identify({
                sha256_email: userData?.hashedEmail,
              });
            } 
            ttq.load('CCFUBR3C77U3OVB1G1FG');
            ttq.page();
          }(window, document, 'ttq');
          `,
        }}
      />
      <Script
        id="twitter-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
          },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
          a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
          // Insert Twitter Pixel ID and Standard Event data below
          twq('init', '${twq.TWITTER_PIXEL_ID}');
          twq('track','PageView');
          `,
        }}
      />
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){
            w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'}); var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),
            dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T6NZSZH');
          `,
        }}
      />
      <Script
        id="hotjar"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:3067383,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        }}
      />
      <Script
        id="facebook-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          const userData = JSON.parse(localStorage.getItem('user_properties'));
          fbq('init', '${fbq.FB_PIXEL_ID}', {
            external_id: userData?.userId || '',
            client_user_agent: '${userAgent || ''}',
            em: userData?.email || '',
            country: userData?.language || 'ko'
          });
          `,
        }}
      />
      <Script
        id="branch-sdk"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);
          branch.init('${branch.BRANCH_KEY}', function(err, data) {
            if (err) {
              console.log("Branch failed to resolve link:",  err);
              return;
            }
          });
          `,
        }}
      />
      <Script
        id="google-analytics"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          function random(percent) {return Math.random() < percent ? '1' : '0';}
          localStorage.setItem('openapp_feature_version', '1');
          localStorage.removeItem('ad_feature_version');
          gtag('js', new Date());
          gtag('config', '${gtag.GA_ID}');
          `,
        }}
      />
      <Script src={getPlayDataCalculator()} />
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width viewport-fit=auto maximum-scale=1"
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8717528159142473"
          crossOrigin="anonymous"
        />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_ID}`}
        />
        <script src="https://code.jquery.com/jquery-1.12.4.min.js" />
        <script src="https://cdn.iamport.kr/js/iamport.payment-1.1.8.js" />
        <script
          async
          src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
          window.googletag = window.googletag || {cmd: []};
          `,
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function (a_, i_, r_, _b, _r, _i, _d, _g, _e) {
              if (!a_[_b]) {
                var d = { queue: [] };
                _r.concat(_i).forEach(function (a) {
                  var i_ = a.split('.'),
                    a_ = i_.pop();
                  i_.reduce(function (a, i_) {
                    return (a[i_] = a[i_] || {});
                  }, d)[a_] = function () {
                    d.queue.push([a, arguments]);
                  };
                });
                a_[_b] = d;
                a_ = i_.getElementsByTagName(r_)[0];
                i_ = i_.createElement(r_);
                i_.onerror = function () {
                  d.queue
                    .filter(function (a) {
                      return 0 <= _i.indexOf(a[0]);
                    })
                    .forEach(function (a) {
                      a = a[1];
                      a = a[a.length - 1];
                      'function' === typeof a && a('error occur when load airbridge');
                    });
                };
                i_.async = 1;
                i_.src = '//static.airbridge.io/sdk/latest/airbridge.min.js';
                a_.parentNode.insertBefore(i_, a_);
              }
            })(
              window,
              document,
              'script',
              'airbridge',
              'init fetchResource setBanner setDownload setDownloads openDeeplink setDeeplinks sendWeb setUserAgent setUserAlias addUserAlias setMobileAppData setUserId setUserEmail setUserPhone setUserAttributes clearUser setDeviceIFV setDeviceIFA setDeviceGAID events.send events.signIn events.signUp events.signOut events.purchased events.addedToCart events.productDetailsViewEvent events.homeViewEvent events.productListViewEvent events.searchResultViewEvent'.split(
                ' ',
              ),
              ['events.wait'],
            );
          
            airbridge.init({
              app: 'storyplay',
              webToken: 'c3f91855b4e84ab6ad93951e371ef0ea',
              utmParsing: true,
              shareCookieSubdomain: false,
              useProtectedAttributionWindow: true,
              protectedAttributionWindowInMinutes: 60,
            });
          `,
          }}
        />
        {/* 허블 스크립트 */}
        {!isDevelopment() && (
          <script
            async
            src="https://sdk.hubble.team/sdk/749f498f-0e3a-47b0-a6f4-f54fad310b97.js"
          />
        )}
        {/* meta 도메인 인증 코드 */}
        <meta
          name="facebook-domain-verification"
          content="n22y3pxu35o2gmsvd9rk85iphlz15l"
        />
        {/* paypal 보안 정책 허용 */}
        {/* <meta
          http-equiv="Content-Security-Policy"
          content="Unsafe-inline, Unsafe-eval"
        /> */}
        {isDevelopment() && <meta name="robots" content="noindex"></meta>}
      </Head>
    </>
  );
};
export default SPHead;
