import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Challenge_Story_Genre, Story_Sorting } from '@/baseType';
import { ChapterFromType, Section } from '@customTypes/event';

interface IEventState {
  label?: string;
  weekdayFilter?: string;
  weekdaySorting?: string;
  previousSection?: Section;
  previousScreen?: ChapterFromType;
  previousPath?: string;
  currentPath?: string;
  previousStoryGameTabTitle?: string;
}

const initialState: IEventState = {};

const eventSlices = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setCurrentStoryLabelText(state, action: PayloadAction<string | undefined>) {
      state.label = action.payload;
    },
    setWeekdayFilter(
      state,
      action: PayloadAction<Challenge_Story_Genre | 'TOTAL'>,
    ) {
      state.weekdayFilter = action.payload;
    },
    setWeekdaySorting(state, action: PayloadAction<Story_Sorting>) {
      state.weekdaySorting = action.payload;
    },
    setPreviousSection(state, action: PayloadAction<Section>) {
      state.previousSection = action.payload;
    },
    setPreviousScreen(state, action: PayloadAction<ChapterFromType>) {
      state.previousScreen = action.payload;
    },
    setCurrentPath(state, action: PayloadAction<string>) {
      state.previousPath = state.currentPath;
      state.currentPath = action.payload;
    },
    setPreviousStoryGameTabTitle(state, action: PayloadAction<string>) {
      state.previousStoryGameTabTitle = action.payload;
    },
    clearData(state) {
      state = {};
    },
  },
});

export const {
  setCurrentStoryLabelText,
  setWeekdayFilter,
  setWeekdaySorting,
  setPreviousSection,
  setPreviousScreen,
  setCurrentPath,
  setPreviousStoryGameTabTitle,
  clearData,
} = eventSlices.actions;

export default eventSlices;
